/* Remove colors and add transition property */

.top-flash {
}

.alert-flash {

    box-shadow: 0 0 30px 10px rgba(50, 50, 50, 0.49);
    position: fixed;
    width: 30%;
    left: 0;
    right: 0;
    top: 76px;
    margin-left: auto;
    margin-right: auto;
    padding: 8px 35px 8px 14px;
    margin-bottom: 20px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    border: 1px solid transparent;
    border-radius: 4px;
    transition-property: all;
    z-index: 10000;
}

.alert-flash h4 {
    margin: 0;
}

.alert-flash .close {
    position: relative;
    top: -2px;
    right: -21px;
    line-height: 20px;
}

/* add warning colors to warn class */
.alert-warn {
    background-color: #fcf8e3;
    border: 1px solid #f7e1b5;
}

.alert-warn, .alert-warn h4 {
    color: #c0a16b;
}

.alert-flash.fade {
    opacity: 0;
    top: -2000px;
    -webkit-transition: opacity .3s ease 0s, top 0s linear .9s;
    transition: opacity .3s ease 0s, top 0s linear .9s;
}

.alert-flash.fade.in {
    top: 55px;
    opacity: 1;
    -webkit-transition: top 0s linear 0s, opacity .3s ease .01s;
    transition: top 0s linear 0s, opacity .3s ease .01s;
}

@media only screen and (max-width: 767px) {
    .alert-flash {
        width: 80%;
    }
}

.table-full-height {
  height: 530px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  right: 0;
  left: 0;
  padding-top: 5px;
  padding-left: 15px;
  &.feeds {
    padding-bottom: 25px;
  }
  @include respond-to(xs) {
    height: 485px;
  }
}
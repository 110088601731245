.profile-info-slider {
  @extend .recom-slider;

  margin-right: -15px;
  margin-left: -15px;
  .swiper-container {
    .swiper-slide {
      padding-right: 40px;
      padding-left: 40px;
    }
    .swiper-button-next {
      right: 5px;
      font-size: 40px;
      color: $blue;
      background-image: none;
      overflow: hidden;
      height: 50px;
      width: 30px;
      text-decoration: none;
      &:hover {
        color: $link-color-hover;
      }
      &.swiper-button-disabled {
        color: $gray;
        opacity: .5;
        cursor: auto;
        pointer-events: none;
      }
      &:before {
        content: '\f054';
        font-family: FontAwesome, serif;
        font-style: normal;
        text-indent: 0;
        display: block;
      }

    }
    .swiper-button-prev {
      @extend .swiper-button-next;
      left: 5px;
      right: auto;
      &:before {
        content: '\f053';
      }
    }
  }

  &.light-needs-slider {
    .bx-wrapper .bx-prev, .bx-wrapper .bx-next {
      top: 60px;
    }
  }
  &.needs-slider {
    li {
      min-height: 260px;
    }
    .bx-wrapper .bx-prev, .bx-wrapper .bx-next {
      top: 120px;
    }
  }
}

.view-profile-card, user-card {
  text-align: center;
  background: white;
  min-height: 340px;
  position: relative;
  width: 100%;
  float: left;
  margin-bottom: 12px;
  padding-bottom: 60px;
  padding-left: 10px;
  padding-right: 10px;
  .profile-name {
    font-weight: bold;
    font-size: 16px;
    margin-top: 20px;
  }
  .profile-additional-block {
    position: relative;
    .rounded-photo {
      border-radius: 50%;
      max-width: 160px;
      max-height: 160px;
      min-width: 140px;
    }
    .verified-profile {
      position: absolute;
      top: 0;
      right: 0;
      .label {
        font-size: 14px;
        position: absolute;
        top: 0;
        margin-left: 15px;
        color: darkgray;
        font-weight: bold;
        @include respond-to(xs) {
          display: none;
        }
      }
      .fa-check-circle {
        font-size: 48px;
      }
    }
    .profile-additional {
      margin-top: 10px;
    }
  }
  .profile-premium-block {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: #789200;
    color: white;
    vertical-align: middle;
    font-size: 18px;
    padding: 13px 55px 13px 15px;
    text-align: center;
    font-weight: bold;
    .premium-img-single {
      width: 42px;
      height: 42px;
      position: absolute;
      right: 10px;
      top: 5px;
    }
  }
  .profile-fans {
    margin-top: 8px;
    margin-bottom: 6px;
    font-size: 22px;
    color: $gray;
    text-align: right;
    .fans-icon {
      color: $dark-green;
      font-size: 42px;
      margin-right: -18px;
    }
    .fans-counter {
      display: inline-block;
      vertical-align: super;
    }
  }
}

.blue-subtitle {
  text-transform: uppercase;
  color: $blue;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
  .title-annotation {
    color: $gray;
    padding-left: 10px;
    font-size: 12px;
    text-transform: none;
  }
}

.view-profile-switcher {
  border-bottom: 3px solid $blue;
  margin-bottom: 5px;
  .single .left-col & {
    margin-bottom: 0;
  }
  background: white;

  .profile-tab-toggle {
    color: $blue;
    background: white;
    text-align: center;
    .tab-title {
      font-size: 20px;
      font-weight: bold;
    }
    i {
      font-size: 56px;
    }
    &.active {
      background: $blue;
      color: white;
    }
  }
}

.left-content {
  padding-right: 25px;
  @include respond-to(sm) {
    padding-right: 15px;
  }
}

.right-content {
  .accomplishment {
    word-wrap: break-word;
  }
  .content {
    min-height: 70px;
    margin-bottom: 12px;
  }
}

.blue-top-line {
  border-top: 2px solid $blue;
}

.blue-bottom-line {
  border-bottom: 2px solid $blue;
}

.profile-learn-more {
  padding-right: 15px;
  padding-left: 15px;
  background: white;
  .blue-subtitle {
    font-size: 24px;
    margin-top: 56px;
  }
}

.big-blue-btn {
  text-decoration: none;
  padding: 25px;
  background-color: #00a9c6;
  transition: 0.3s background-color, padding;
  &:hover {
    text-decoration: none;
  }
  .text {
    opacity: .80;
    width: calc(100% - 84px);
    display: inline-block;
    text-decoration: none;
    color: white;
    transition: 0.3s opacity;
    font-size: 26px;
    vertical-align: super;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }
  .circle-arrow {
    opacity: .67;
    transition: 0.5s opacity;
    @include circle-arrow(80px, 4px);
  }
  &:hover {
    .text, .circle-arrow {
      opacity: 1;
    }
  }
  &:active {
    background-color: #008fa9;
    padding: 26px 24px 24px 26px;
    .text, .circle-arrow {
      opacity: .9;
    }
  }
}

.small-padding {
  padding-left: 15px;
  padding-right: 15px;
}

.list-learn-more {
  display: inline-block;
  text-align: left;
  .list-learn-more-title {
    color: darkgray;
    font-weight: bold;
    text-align: left;
  }
  ul {
    padding-left: 0;
  }
  li {
    list-style-type: none;
  }
  li:before {
    content: "\f00c";
    color: #00a9c6;
    font-family: FontAwesome;
    font-size: 18px;
    margin-right: 5px;
  }
  @include respond-to(sm) {
    min-width: 180px;
  }
}


@media (min-width: 1200px) {
    .top-search {
    }

    .social-share {
        margin-top: 15px;
        margin-bottom: 2px;
    }

    .social-share strong {
        display: inline-block;
        margin-top: 6px;
        vertical-align: top;
    }

    /*#bs-navbar-collapse-1 > .auth-user{ width: 100%; position: absolute; padding-left: 180px; }*/
    #bs-navbar-collapse-1 > .auth-user > li:last-child {
        float: right;
    }

    /*.embed-responsive-item { height: 326px; }*/
    .home-block .main-subtitle {
        font-size: 27px
    }
}

@media (max-width: 1200px) {
    .sign-up-under-video .video-text {
        padding: 10px;
        padding-left: 45px;
        padding-right: 45px;
    }

}

@media (max-width: 1199px) {

    /*    .slider3 .carousel-control.left{
            margin-left: -10px;
        }
        .slider3 .carousel-control.right{
            right: -5px;
        }*/
    .front-page-startup-corner {
        padding-left: 5%;
    }

    .front-page-startup-corner .left-text {
        font-size: 20px;
    }

    .front-page-startup-corner .center-block {
        width: 60%;
        margin-top: 35px;
    }

    .front-page-startup-corner .center-block h3,
    .front-page-startup-corner .center-block span {
        font-size: 35px;
    }

    .front-page-startup-corner .bottom-text {
        font-size: 18px;
    }

    .stepdone-1 p, .stepdone-2 p, .stepdone-3 p {
        font-size: 29px;
    }

    .stepdone-1 {
        width: 36%;
        margin-left: -66px;
    }

    .stepdone-3 {
        width: 38%;
        margin-left: -5px;
    }

    .active-steps {
        width: 240px;
        height: 240px;
    }

    .active-steps.active-step-1 {
        margin-left: 75px;
    }

    .active-steps img {
        margin: 5px;
        width: 50%;
        height: 50%;
    }

    .active-steps span {
        position: absolute;
        margin-left: -60px;
        margin-top: 25px;
        font-family: 'Open Sans';
        font-weight: bold;
        font-size: 48px;
    }

    .step-announce p:first-child {
        width: 970px;
    }

    .guest-menu .navbar-default .navbar-collapse {
        width: 60%;
    }

    .logged-top-menu .navbar-default .navbar-collapse {
        width: 68%;
    }

    /*.premium-header #bs-navbar-collapse-1 > .auth-user {
        width: 70%;
    }*/
}

@media (max-width: 991px) {
    footer .footer-menu > li {
        display: block;
        vertical-align: top;
        padding-right: 0;
        width: 100%;
        text-align: center;
        padding-top: 10px;
    }

    footer .footer-menu > li:before {
        content: "";
    }

    footer .footer-menu .sub-menu {
        margin-top: 0px;
    }

    footer .footer-menu > li a {
        padding: 0;
    }

    .nav .seporator {
        width: 25px;
    }

    .nav li a.point {
        margin-right: 0;
    }

    .form-login-wrap > .form-group {
        margin-left: 10px;
    }

    .form-login-wrap {
        margin-top: 0;
    }

    .sign-up-under-video .video-text {
        padding: 10px;
        padding-left: 45px;
        padding-right: 45px;
    }

    /*.facebook-reg .text, .linkedin-reg .text{*/
    /*font-size: 14px;*/
    /*}*/
    .front-slider .bx-wrapper .caption .text-logo {
        padding-bottom: 25px;
    }

    .main-counter {
        margin-top: 0;
    }

    .front-page-startup-corner {
        padding-left: 5%;
    }

    .front-page-startup-corner .left-text {
        font-size: 16px;
    }

    .front-page-startup-corner .center-block {
        width: 60%;
        margin-top: 26px;
    }

    .front-page-startup-corner .center-block h3,
    .front-page-startup-corner .center-block span {
        font-size: 26px;
    }

    .front-page-startup-corner .bottom-text {
        font-size: 14px;
    }

    .step-announce-title {
        font-family: 'Open Sans';
        font-weight: bold;
        text-align: center;
        margin-left: -15px;
        margin-right: -15px;
        background-color: #fff;
        font-size: 54px;
        color: #00b3cb;
    }

    .step-announce {
        height: 276px;
        background-image: url(/../../images/step1-back-mobile.jpg);
        background-size: cover;
    }

    .step-announce.step-announce-three {
        height: 420px;
    }

    .step-announce div {
        float: none;
        padding: 2px;
        margin: 0;
    }

    .stepdone-1 {
        width: 100%;
        transform: skewX(0deg);
        margin-left: 0px;
        height: 50%;
    }

    .step-announce.step-announce-three .stepdone-1 {
        height: 33.33333333%;
    }

    .step1-content, .step2-content, .step3-content {
        position: static;
        text-align: left;
        height: 100%;
    }

    .active-steps {
        width: 128px;
        height: 128px;
        transform: skewX(0deg);
        border: 5px #fff solid;
        text-align: center;
    }

    .active-steps.active-step-1 {
        margin-left: 0px;
    }

    .stepdone-1 p, .stepdone-2 p, .stepdone-3 p {
        width: 250%;
        top: 40px;
        left: 140px;
        position: absolute;
        text-align: left;
    }

    .active-steps img {
        margin-top: 18px;
        width: 65%;
        height: 65%;
    }

    .active-steps span {
        margin-left: -45px;
        margin-top: 33px;
        font-size: 32px;
    }

    .stepdone-3 {
        width: 100%;
        transform: skewX(0deg);
        margin-left: 0px;
        margin-right: 0px;
        height: 50%;
    }

    .step-announce.step-announce-three .stepdone-3 {
        height: 33.33333333%;
    }

    .passive-step {
        transform: skewX(0deg);
    }

    .passive-step-back .passive-step {
        margin-top: 0px;
    }

    .row.content.announces.step-announce a {
        text-align: center;
    }

    .step-announce .passive-step > p {
        margin-left: 0px;
    }

    .passive-step {
        width: 128px;
        height: 128px;
    }

    .passive-step span {
        font-size: 60px;
        margin-top: 18px;
    }

    .stepdone-1.passed-step-back {
        width: 100%;
        margin-left: 0px;
    }

    .passive-step {
        margin-top: 0px;
    }

    .passed-steps img {
        transform: rotate(10deg);
        right: 0;
        width: 100px;
        height: 103px;
        margin: 10px 36px;
        z-index: -1;
    }

    .stepdone-1.active-step-back + .stepdone-2 {
        width: 100%;
        margin-left: 0px;
    }

    .stepdone-2 {
        width: 100%;
        height: 33.33333333%;
        transform: skewX(0deg);
    }

    .share-btn {
        margin-left: 0px;
    }

    .feeds-block .row-table, .feeds-block .row-table > * {
        display: block;
    }

    .announces {
        padding-left: 0px;
        padding-top: 100px;
        display: block;
    }

    .SliderPartnership {
        padding-left: 0;
        padding-right: 0px;
    }

}

@media only screen and (min-width: 992px) and (max-width: 1200px), only screen and (min-device-width: 992px) and (max-device-width: 1200px) {
    /* MD */
    /*#bs-navbar-collapse-1 > .auth-user{ width: 100%; position: absolute; padding-left: 260px; }*/
    #bs-navbar-collapse-1 > .auth-user > li:last-child {
        float: right;
    }

    .stepdone-1.active-step-back + .stepdone-2 {
        margin-left: -9px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-device-width: 768px) and (max-device-width: 991px) {
    /* SM  */
    .text-center-sm {
        text-align: center;
        margin: auto;
    }

    .dl-horizontal.widget-counters dt {
        float: left;
    }

    /*#bs-navbar-collapse-1 > .auth-user{ width: 100%; position: absolute; padding-left: 260px; }*/
    #bs-navbar-collapse-1 > .auth-user > li:last-child {
        float: right;
    }

    .top-search {
        width: 250px;
    }

}

@media only screen and (max-width: 767px), only screen and (max-device-width: 767px) {
    /* XS  */
    .text-center-xs {
        text-align: center;
        margin: auto;
    }

    .top-search {
        width: 100%;
    }

    .hidden-smob {
        display: none !important;
    }

    .display-smob {
        display: block;
    }

    .display-smob .dropdown-menu.form-login {
        left: 20px;
        right: 0px;
        text-align: left;
    }

    .navbar {
        margin-bottom: 0;
    }

    .nav.navbar-nav > li > a {
        text-align: center;
    }

    .navbar-nav .top-search .open .dropdown-menu {
        position: absolute;
        margin: 2px 0 0;
        background-color: #fff;
        box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    }

    .dl-horizontal.widget-counters dt {
        float: left;
    }

    .logo {
        width: 170px;
        height: auto;
        margin: 3px;
        float: left;
    }

    .signup > div:first-child {
        margin-left: -23px;
        margin-right: -23px;
        padding-left: 23px;
        padding-right: 23px;
        padding-top: 1px;
        padding-bottom: 1px;
    }

    /*.facebook-reg { width: 100%; margin-bottom: 2px;}*/
    /*.facebook-reg > div {text-align: center;}*/
    /*.facebook-reg span {display: block; padding-top: 10px; padding-left: 3px; padding-right: 3px;}*/
    .soc-auth-reg-wrap {
        padding: 0px;
    }

    /*    .linkedin-reg { display: block; width: 100%;}
        .linkedin-reg > div {text-align: center;}
        .linkedin-reg span {display: block; padding-top: 10px;padding-left: 3px;padding-right: 3px;}*/
    #carousel-example-generic {
        margin-bottom: 25px;
    }

    .top-search-xs > div {
        padding-right: 0;
        padding-left: 0;
    }

    .dropdown-menu.form-login {
        left: -22px;
    }

    .annotation-title {
        margin-top: 20px;
        text-align: center;
    }

    .reg-buttons-wrap {
        position: relative;
        margin-left: -23px;
    }

    .reg-buttons-wrap .facebook-reg {
        background-color: #3a5795;
    }

    .reg-buttons-wrap .show-sign-up {
        background-color: #00a9c6;
        color: #fff;
        font-weight: bold;
        font-size: 18px;
    }

    form[name='profile'] .text-right.bolder {
        text-align: left;
    }

    form[name='profile'] .form-edit-btn {
        margin-top: -50px;
        float: right;
    }

    form[name='profile'] .form-edit-btn.active {
        margin-top: -30px;
    }

    .contentblock .text-right.bolder {
        text-align: left;
    }

    .grid-info-panel .sort-buttons > button:first-child {
        margin-left: 0px;
    }

    .grid-info-panel .sort-buttons,
    .grid-info-panel .sm-conter {
        text-align: center;
    }

    .two-content > .seporator {
        display: none;
    }

    .two-content > * {
        width: 100%;
        float: left;
    }

    .two-content:after {
        content: ' ';
        display: block;
        clear: both;
    }

    .frontend-sign-up .two-content > * {
        width: 14%;
        float: none;
    }

    .frontend-sign-up .two-content:after {
        content: '';
        display: none;
        clear: none;
    }

    .frontend-sign-up .seporator {
        display: table-cell;
        width: 2%;
    }

    .feeds-block .row-table {
        display: block;
    }

    .feeds-block .row-table > * {
        display: block;
    }

    .front-registration-cols > div {
        float: none;
        width: 50%;
    }

    .logged-top-menu .navbar-default .navbar-collapse,
    .guest-menu .navbar-default .navbar-collapse {
        width: 100%;
    }

    .premium-header #bs-navbar-collapse-1 {
        width: 100%;
        border-top: none;
    }

    .premium-header .navbar-default .navbar-nav > li > a.head-login {
        color: #789200;
        font-weight: bold;
    }

    .user-fill-percent .progress-status {
        font-size: 22px;
    }
}

@media only screen and (max-width: 650px), only screen and (max-device-width: 650px) {

    .front-slider .caption > div > span {
        font-size: 25px;
    }

    .front-page-startup-corner .left-text {
        font-size: 13px;
    }

    .front-page-startup-corner .center-block h3,
    .front-page-startup-corner .center-block span {
        font-size: 14px;
    }

    .front-page-startup-corner .center-block h3 {
        margin-top: -10px;
    }

    .front-page-startup-corner .center-block .line.bottom {
        margin-top: 0px;
    }

    .front-page-startup-corner .bottom-text {
        font-size: 11px;
    }

}

@media only screen and (max-width: 571px), only screen and (max-device-width: 571px) {
    /*.facebook-reg > div { font-size: 14px; }
    .linkedin-reg > div { font-size: 14px; }*/
    .grid-info-panel .sort-buttons > button {
        margin-left: 0px;
    }

    .front-slider .caption > div > span {
        font-size: 21px;
    }
}

@media only screen and (max-width: 490px), only screen and (max-device-width: 490px) {
    /*.facebook-reg span { padding-top: 5px; }
    .linkedin-reg span { padding-top: 5px; }*/
    .front-slider .caption > div > span {
        font-size: 21px;
    }

    .sign-up-under-video .video-text {
        padding: 10px;
    }
}

@media only screen and (max-width: 471px), only screen and (max-device-width: 471px) {
    .front-slider .caption > div > span {
        font-size: 15px;
    }

    .step-announce-title {
        font-size: 24px;
    }

    .step-announce {
        height: 198px;
    }

    .step-announce.step-announce-three {
        height: 296px;
    }

    .stepdone-1 p, .stepdone-2 p, .stepdone-3 p {
        left: 93px;
        top: 18px;
        width: 320%;
        font-size: 22px;
    }

    .active-steps img {
        margin-top: 12px;
    }

    .active-steps span {
        margin-left: -32px;
        margin-top: 20px;
        font-size: 24px;
    }

    .passive-step, .active-steps {
        width: 90px;
        height: 90px;
    }

    .passive-step span {
        font-size: 36px;
    }

    .row.content.announces.step-announce a {

    }

    .passed-steps img {
        width: 55px;
        height: 56px;
        margin: 10px;
    }
}

@media only screen and (max-width: 360px), only screen and (max-device-width: 360px) {
    /*.facebook-reg > div { font-size: 12px; }
    .linkedin-reg > div { font-size: 12px; }*/
    /*.facebook-reg .text, .linkedin-reg .text{ font-size: 12px; }*/
    .front-slider .bx-wrapper .caption {
        padding-top: 5px;
    }
}

@media only screen and (max-width: 324px), only screen and (max-device-width: 324px) {
    .reg-buttons-wrap .show-sign-up {
        font-size: 14px;
    }

    /* .facebook-reg > div { font-size: 10px; }
     .linkedin-reg > div { font-size: 10px; }*/

}

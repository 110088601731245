.slider {
  &.premium {
    /// BASE STEP BLOCK
    %base-step {
      @extend %step;
      background-image: url('/../../images/Discover-premium.jpg');
      .caption {
        @extend %caption;
        &:before {
          background-color: $bg-premium;
        }
        .steps-content {
          p {
            margin-bottom: 0;
            margin-top: 30px;
            font-size: 30px;
            &.title {
              margin-top: 15px;
              padding-left: 20px;
              @include respond-to('sm') {
                margin-top: 10px;
              }
            }
          }
          a {
            @include circle-arrow(150px, 5px);
            margin-top: 15px;
          }

          @include respond-to(sm) {
            p {
              margin-top: 10px;
              font-size: 26px;
            }
            a {
              @include circle-arrow(76px, 3px);
              margin-top: 12px;
            }
          }

          @include respond-to(xs) {
            p {
              margin-top: 6px;
              font-size: 18px;
            }
            a {
              margin-top: 10px;
            }
          }
        }
      }
    }

    .wishes-step, .profile-step, .project-step, .need-step, .result-step {
      @extend %base-step;
    }

    ////////////////////// FRANCE BLOCK ///////////////////////////////
    &.lang-fr {
      /// RESULT STEP BLOCK
      .result-step > .caption > .steps-content {
        a {
          @include circle-arrow(110px, 3px);
        }
        @include respond-to(sm) {
          p {
            font-size: 25px;
          }
          a {
            @include circle-arrow(76px, 3px);
          }
        }
        @include respond-to(xs) {
          p {
            font-size: 18px;
          }
        }
      }

      /// WISHES STEP BLOCK
      .wishes-step > .caption > .steps-content {
        @include respond-to(sm) {
          p {
            font-size: 25px;
          }
        }
        @include respond-to(xs) {
          p {
            font-size: 18px;
          }
        }
      }

      /// PROFILE STEP BLOCK
      .profile-step > .caption > .steps-content {

        @include respond-to(sm) {
          p {
            font-size: 25px;
          }
        }

        @include respond-to(xs) {
          p {
            font-size: 18px;
          }
        }
      }

      //PROJECT STEP BLOCK
      .project-step > .caption > .steps-content {
        a {
          @include circle-arrow(110px, 3px);
        }

        @include respond-to(sm) {
          p {

            font-size: 20px;
          }
          a {
            @include circle-arrow(76px, 3px);
            margin-top: 8px;
          }
        }

        @include respond-to(xs) {
          p {
            font-size: 18px;
          }
          a {
            margin-top: 10px;
          }
        }
      }

      //NEED STEP BLOCK
      .need-step > .caption > .steps-content {
        p {

        }
        a {
          @include circle-arrow(85px, 3px);
          margin-top: 6px;
        }

        @include respond-to(sm) {
          p {
            font-size: 20px;
          }
          a {
            @include circle-arrow(74px, 3px);
            margin-top: 6px;
          }
        }

        @include respond-to(xs) {
          p {
            font-size: 18px;
          }
          a {
            margin-top: 10px;
          }
        }

      }
    }
  }
}
header {
  z-index: 1035;
  position: fixed;
  top: 0;
  width: 100%;
  .main-header {
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
    .guest-navbar {
      background-color: white;
      margin-bottom: 0;
      border: none;
      padding: 10px 0;
      .navbar-header {
        width: 35%;
        @include respond-to(xs) {
          width: inherit;
        }
      }
      .navbar-collapse {
        width: 65%;
        float: left;
        padding: 0;
        .form-login-wrap {
          margin-top: 13px;
        }
      }
      .navbar-nav > li > a:hover, .top-header.navbar-default .navbar-nav > li > a:focus {
        background-color: transparent;
      }
      .navbar-nav > .open > a, .top-header.navbar-default .navbar-nav > .open > a:hover,
      .top-header.navbar-default .navbar-nav > .open > a:focus {
        background-color: transparent;
      }
      .nav li a.point {
        padding-top: 22px;
      }
      .settingsbtn {
        margin: 8px 0 0 15px;
        float: left;
      }
      .logo {
        margin-top: -8px;
      }

      @include respond-to(sm) {
        .logo {
          width: 200px;
          height: 50px;
          margin: 0;
        }
      }
      @include respond-to(xs) {
        padding: 5px 15px;
        .logo {
          width: 156px;
          height: 38px;
          margin: 6px 0 0 15px;
          float: left;
        }
        .navbar-collapse {
          float: none;
          width: inherit;
        }
      }

      .top-search {
        width: 400px;
        background: #00a9c5;
        padding: 7px 10px;
        margin-left: -1px;
        margin-top: 0;

        .input-group {
          width: 100%;
          .input-group-btn {
            width: 38px;
            &.dropdown {
              width: 35px;
            }
            &.dropdown .btn {
              background: #00a9c5;
              color: white;
              border: 0;
              padding: 5px;
            }
            .btn {
              background: #aaaaaa;
            }

          }
          input {
            background: #f6f6f6;
          }
        }
        .btn-link {
          color: #ffffff;
          padding-left: 6px;
          padding-right: 6px;
        }
      }
      .navbar-toggle {
        min-height: 36px;
      }
      .display-smob {
        float: left;
        width: 100%;
        padding-bottom: 3px;
        text-align: center;
      }
      .hidden-smob {
        margin: 0;
      }
      .lang-select-btn {
        .btn.dropdown-toggle {
          min-height: 36px;
          font-weight: bold;
        }
        ul.dropdown-menu {
          border: none;
        }
        &.lang-xs {
          float: right;
          margin: 8px;
          ul.dropdown-menu {
            left: auto;
            top: auto;
          }
          display: none;
          @include respond-to(xs) {
            display: inline-block;
          }
        }
      }
    }
  }
}

.blue-dropdown {
  .btn.dropdown-toggle {
    padding: 3px 6px;
    line-height: 1;
    color: white;
    border: none;
    background-color: #00a9c6;
    &:hover {
      background-color: #269abc;
    }
    &:active {
      color: #6f6f6f;
      background-color: #e6e6e6;
    }
  }
  &.open .btn.dropdown-toggle, &.open > .btn.dropdown-toggle:hover, &.open > .btn.dropdown-toggle:focus {
    background-color: #e6e6e6;
    color: #6f6f6f;
    box-shadow: none;
  }
  ul.dropdown-menu {
    background-color: #00a9c6;
    border: 1px solid #269abc;
    li > a {
      color: white;

      &.active, &:focus, &:active {
        font-weight: bold;
        color: white;
        background-color: transparent;
      }
      &.orange, &.orange.active, &:focus.orange, &:active.orange {
        color: $orange;
      }

      &:hover {
        background-color: #e6e6e6;
        color: #00a9c6;
      }

    }
  }
}

.white-dropdown {
  .btn.dropdown-toggle {
    padding: 6px 12px;
    line-height: 1;
    color: #00a9c6;
    border: none;
    background-color: white;
    &:hover {
      background-color: #f2f2f2;
    }
    &:active {
      color: #6f6f6f;
      background-color: #e6e6e6;
    }
  }
  &.open .btn.dropdown-toggle, &.open > .btn.dropdown-toggle:hover, &.open > .btn.dropdown-toggle:focus {
    background-color: #e6e6e6;
    color: #6f6f6f;
    box-shadow: none;
  }
  ul.dropdown-menu {
    background-color: white;
    border: 1px solid #e6e6e6;
    li > a {
      color: #00a9c6;
      &.active, &:focus, &:active {
        font-weight: bold;
        color: #00a9c6;
        background-color: transparent;
      }
      &.orange, &.orange.active, &:focus.orange, &:active.orange {
        color: $orange;
      }
      &:hover {
        background-color: #e6e6e6;
        color: #269abc;
      }

    }
  }
  .container > .navbar-collapse {
    margin: auto
  }
;
}

.navbar-toggle.blue-navbar-toggle {
  color: #6f6f6f;
  background-color: #e6e6e6;
  padding: 9px 6px 6px 9px;
  line-height: 1;
  border: none;
  &.collapsed {
    color: white;
    background-color: #00a9c6;
    &:hover {
      background-color: #269abc;
    }
    &:active {
      color: #6f6f6f;
      background-color: #e6e6e6;
    }
  }
}

.navbar-toggle.white-navbar-toggle {
  color: #6f6f6f;
  background-color: #e6e6e6;
  padding: 9px 6px 6px 9px;
  line-height: 1;
  border: none;
  &.collapsed {
    color: #00a9c6;
    background-color: white;
    &:hover {
      background-color: #f2f2f2;
    }
    &:active {
      color: #6f6f6f;
      background-color: #e6e6e6;
    }
  }
}

.photo-btn {
  width: 28px;
  margin-top: -4px;
  background: white;
  margin-right: 2px;
  height: 28px;
  border-radius: 100%;
}

.circle-menu {
  &:hover {
    background-color: #94f0ff;
  }
  &:active {
    background-color: #00859e;;
  }
}

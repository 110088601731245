.not-found {
  display: table-cell;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
  vertical-align: center;
  width: 40%;
  img {
    width: 70%;
    margin-bottom: 20px;
  }
  p {
    font-size: 11px;
    font-weight: bold;
    margin: 7px 0;
  }
  h5 {
    font-weight: bold;
  }
  button {
    border-radius: 16px;
    margin: 11px 0;
    background-color: #25a9c6;
    color: #ffffff;
    font-weight: bold;
  }
  button:hover {
    color: #ffffff;
  }

  @include respond-to(xs) {
    width: 99%;
    img {
        width: 70%;
        margin-bottom: 30px;
    }
    p {
      margin: 20px 0;
    }
  }
}

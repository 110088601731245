@mixin flex-size($i, $spacing) {
  $flex-size: (100% / 12 * $i);
  min-width: calc(#{$flex-size} - #{$spacing});
  max-width: calc(#{$flex-size} - #{$spacing});
}

@mixin flex-respond-sizes($spacing) {
  @for $i from 1 through 11 {
    &.flex-size-#{$i} {
      @include flex-size($i, $spacing);
    }
  }
  @for $i from 1 through 11 {
    @include respond-to(sm) {
      &.flex-sm-size-#{$i} {
        @include flex-size($i, $spacing);
      }
    }
  }
  @for $i from 1 through 11 {
    @include respond-to(xs) {
      &.flex-xs-size-#{$i} {
        @include flex-size($i, $spacing);
      }
    }
  }

  &.flex-size-12 {
    @include flex-size(12, 0px);
  }

  &.flex-sm-size-12 {
    @include flex-size(12, 0px);
  }

  &.flex-sm-size-12 {
    @include flex-size(12, 0px);
  }
}

.flex-container {
  display: flex;
  flex-wrap: nowrap;

  &.flex-center {
    align-items: center;
  }

  & > * {
    @include flex-respond-sizes(0px);
  }
  &.flex-spacing {
    justify-content: space-between;
    & > * {
      @include flex-respond-sizes(6px);
    }
  }

  @include respond-to(sm) {
    &.flex-row-sm {
      flex-direction: row;
    }
    &.flex-column-sm {
      flex-direction: column;
      & > * {
        @include flex-size(12, 0px);
      }
      &.flex-spacing {
        & > * {
          @include flex-size(12, 0px);
        }
      }
    }
  }

  @include respond-to(xs) {
    &.flex-row-xs {
      flex-direction: row;
    }
    &.flex-column-xs {
      flex-direction: column;
      & > * {
        @include flex-size(12, 0px);
      }
      &.flex-spacing {
        & > * {
          @include flex-size(12, 0px);
        }
      }
    }
  }
}

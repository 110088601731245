
.btn-circle-black {
  background-color: #505050;
  border-radius: 23px;
  font-size: 22px;
  padding: 8px 18px 6px 38px;
  color: #eee;
  box-shadow: 0 0 1px 1px #00daff;
  position: relative;
  &:focus, &:active {
    color: #ddd;
    outline: none;
  }
  &:active {
    padding: 9px 17px 5px 39px;
  }

  &:hover {
    box-shadow: 0 0 1px 1px #71e9ff;
    color: #fff;
  }
  &:before {
    position: absolute;
    left: 14px;
    content: '\f054';
    font-family: FontAwesome;
    font-style: normal;
    font-size: 24px;
    line-height: 1.3;
    text-shadow: 0 1px 1px #00daff, 0 -1px 1px #00daff;
    &:hover {
      text-shadow: 0 1px 1px #71e9ff, 0 -1px 1px #71e9ff;
    }
  }
  &:active:before {
    left: 15px;
  }
  @include respond-to(xs) {
    font-size: 16px;
    &:before {
      left: 14px;
      font-size: 20px;
      line-height: 1.1;
    }
  }
}

.gray-block {
  padding-left: 15px;
  width: 100%;
  padding-right: 15px;
  float: inherit;
  border: 6px solid #e7f2f6;
}

.slider {
  .result-step {
    background-image: url('/../../images/Discover-P.jpg');
    @extend %step;
    .caption {
      @extend %caption;
      .result-steps-content {
        a {
          @include circle-arrow(110px, 5px);
        }
        p {
          font-family: 'Open Sans';
          font-size: 40px;
          margin-top: 50px;
          padding-bottom: 50px;
        }

        @include respond-to('sm') {
          a {
            @include circle-arrow(86px, 3px);
          }
          p {
            margin-top: 10px;
            padding-bottom: 10px;
            font-size: 46px;
          }
        }
        @include respond-to('xs') {
          a {
            @include circle-arrow(56px, 2px);
          }
          p {
            margin-top: 5px;
            padding-bottom: 5px;
            font-size: 21px;
          }
        }
      }
    }
  }
}

.hp-landing-container {
  .row-content {
    background-color: white;
  }
  .bottom-slide {
    position: relative;
    background: url('/../../images/slides/hpBottomSlide.jpg') center center no-repeat;
    background-size: cover;
    .join-us-now {
      background: rgba(0, 169, 198, 0.6);
      color: #fff;
      padding: 40px;
      font-size: 46px;
      margin: 160px -15px;
      text-align: center;
    }
    @include respond-to(xs) {
      .join-us-now {
        margin-top: 40px;
        margin-bottom: 40px;
        font-size: 36px;
        padding: 15px 0;
      }
    }
  }

  .top-slide {
    height: 640px;
    position: relative;
    background: url('/../../images/slides/hpTopSlide.jpg') center center no-repeat;
    background-size: cover;
    .blue-bottom-content {
      position: absolute;
      width: 100%;
      bottom: 0;
      color: #fff;
      text-align: center;
      background-color: rgba(0, 169, 198, .75);
      padding-left: 100px;
      padding-right: 100px;
      .description, .register-now {
        margin: 15px 0;
      }
    }

    @include respond-to(sm) {
      height: 460px;
      .blue-bottom-content {
        h1 {
          font-size: 22px;
          line-height: 1.5;
        }
      }
    }

    @include respond-to(xs) {
      height: auto;
      .blue-bottom-content {
        position: initial;
        padding: 0;
        width: auto;
        h1 {
          font-size: 26px;
        }
        .description, .register-now {
          margin: 50px 0;
        }
      }
    }
  }
  .learn-more-block {
    text-align: center;
    height: 360px;
    background-color: #fff;
    margin-bottom: 10px;
    .lm-title {
      font-size: 20px;
      font-weight: bold;
      padding: 16px 0;
    }
    .lm-logo {
      padding: 25px 15px;
    }
    .lm-link {
      padding: 16px 0;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.0) 0%, white 20%);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      .btn.btn-link {
        border: none;
        padding: 0;
      }
      .lm-circle {
        border: 1px solid #00a9c6;
        border-radius: 100%;
        line-height: 0;
        padding: 8px 6px;
      }
    }
    @include respond-to(xs) {
      height: 250px;
      .lm-title {
        padding: 5px 0;
      }
      .lm-logo {
        padding-top: 5px;
        img {
          width: 50px;
          height: 50px;
        }
      }
      .lm-logo--members {
        padding-left: 50px;
        padding-right: 50px;
      }
    }
  }

  .landing-more-container {
    position: relative;
    background-color: #fff;
    margin-bottom: 15px;
    .for-entrepreneur, .for-partner {
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 15px;
      padding-right: 15px;
    }
    .left-title-icon {
      position: absolute;
      top: 0;
      left: 0;
      margin-top: 20px;
      margin-left: 20px;
      width: 115px;
      height: 115px;
    }
    .right-title-icon {
      position: absolute;
      top: 0;
      right: 0;
      width: 110px;
      text-align: center;
      margin-right: 20px;
      margin-top: 20px;
      &.green {
        a {
          color: #659800;
          &:focus {
            color: #659800;
          }
        }
      }
    }
    .lm-subtitle {
      color: #00a9c6;
      padding: 30px 0 20px;
      h2 {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
      }
    }
    .lm-desc {
      padding: 0 0 20px;
      margin: 0 20%;
      text-align: center;
    }

    .sign-up-now-block {
      display: block;
      margin-top: 110px;
      padding: 25px 15px;
      background-color: #00a9c6;
      .link-text {
        display: inline-block;
        width: 66.6666666%;
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        opacity: .67;
        padding-left: 40px;
        transition: 0.5s opacity;
      }
      .circle-arrow {
        width: 33.3333333%;
        display: inline-block;
        opacity: .67;
        transition: 0.5s opacity;
        vertical-align: top;
        @include circle-arrow(75px, 4px);
      }
      &:active, &:focus {
        .link-text, .circle-arrow {
          opacity: .6;
        }
      }
      &:hover {
        .link-text, .circle-arrow {
          opacity: 1;
        }
      }
    }
    @include respond-to(sm) {
      .sign-up-now-block {
        margin-top: 20px;
      }
    }
    @include respond-to(xs) {
      .lm-subtitle, .lm-desc {
        padding-bottom: 20px;
      }
      .lm-desc {
        margin-right: 15px;
        margin-left: 15px;
      }
      .right-title-icon {
        position: inherit;
        margin-bottom: 10px;
        width: auto;
        height: auto;
        font-size: 11px;
        .right-title-img {
          margin: auto;
          width: 80px;
          height: 80px;
        }
      }
      .left-title-icon {
        display: none;
      }
    }
  }

  .landing-video {
    .lm-video-container {
      padding: 0 145px 30px;
      position: relative;
    }
    .sing-up-now-video {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: 0 145px 30px;
      text-align: center;
      background-color: rgba(0, 169, 198, .75);
      .sign-up-now-link {
        display: block;
        margin: 15% auto 0;
        .link-text {
          font-size: 28px;
          font-weight: bold;
          color: #fff;
          opacity: .67;
          transition: 0.5s opacity;
        }
        .circle-arrow {
          width: 33.3333333%;
          display: inline-block;
          opacity: .67;
          transition: 0.5s opacity;
          vertical-align: top;
          @include circle-arrow(120px, 4px);
        }
        &:active, &:focus {
          .link-text, .circle-arrow {
            opacity: .6;
          }
        }
        &:hover {
          text-decoration: none;
          .link-text, .circle-arrow {
            opacity: 1;
          }
        }
      }
      @include respond-to(xs) {
        .sign-up-now-link .circle-arrow {
          @include circle-arrow(75px, 4px);
        }
        margin: 0 0 15px;
      }
    }
    @include respond-to(sm) {
      padding: 0 80px;
    }
    @include respond-to(xs) {
      padding: 0 15px;
      .lm-video-container {
        padding: 0 0 15px;
      }
    }
  }
  .landing-members {
    .member-block1 {
      padding: 0 60px;
      height: 460px;

      .member-block-title {
        font-size: 18px;
        margin: 0;
        text-align: center;
        font-weight: bold;
        padding: 20px 0;
      }
      .member-block-desc {
        padding: 10px 0;
        ul {
          padding-left: 6px;
          li {
            padding: 5px 0;
          }
        }
      }
    }
    .member-block2 {
      padding: 0 60px;
      border-top: 5px solid #00a9c6;
      text-align: center;
      margin: 20px 0;
      .member-block-desc {
        padding: 30px 0 15px;
        height: 140px;
      }
      .member-name {
        padding: 15px 0 30px;
        font-style: italic;
      }
      .member-link-img {
        & > a {
          cursor: pointer;
          width: 120px;
          height: 120px;
          position: relative;
          display: block;
          margin: auto;
          color: #eee;
          &:focus, &:active {
            color: #ddd;
          }
          &:hover {
            color: #fff;
          }
          & > div {
            position: absolute;
            font-weight: bold;
            font-size: 20px;
            line-height: 3.2;
            width: inherit;
            text-transform: uppercase;
            top: 0;
          }
        }
      }
    }
    @include respond-to(xs) {
      .member-block1 {
        height: auto;
        padding: 0 15px;
      }
      .member-block2 {
        padding: 0 15px;
        .member-block-desc {
          height: auto;
        }
      }
    }
  }

  .landing-benefits {

    .other-block {
      margin-top: 110px;
      .gray-block {
        padding: 10px 10px 10px 80px;
      }
      .block-item {
        height: auto;
        margin-bottom: 0;
        min-height: 45px;
        .verified-icon {
          color: #659800;
          font-size: 45px;
          float: left;
          margin-left: -60px;
          margin-top: -15px;
        }
      }

    }
    .benefits-block {
      margin-bottom: 20px;
      .gray-block {
        height: 460px;
        overflow: hidden;
        padding-left: 80px;
        padding-right: 60px;
        padding-bottom: 15px;
      }
      .block-item {
        height: auto;
        margin-bottom: 0;
        padding-bottom: 5px;
      }
      .block-item:not(.gray-title) {
        min-height: 40px;
        padding-top: 10px;
        padding-bottom: 0;
      }

      .rocket-item {
        float: right;
        margin-top: 20px;
      }
      p {
        margin: 0 0 25px;
        &:last-child {
          margin: 0;
        }
      }
      .benefits-left-icon {
        float: left;
        width: 50px;
        margin-left: -65px;
        margin-top: 10px;
        &.rocket-part {
          margin-top: 30px;
        }
        img {
          margin-bottom: 20px;
          margin-top: -10px;
        }
        &.premium-part {
          margin-top: 20px;
          img {
            margin-bottom: 35px;
          }
        }
      }
    }
    .sample-block {
      position: relative;
      padding-left: 20px;
      .sample-info {
        position: absolute;
        top: 0;
        left: 220px;
      }
    }
    .for-partner {
      .sample-block {
        .sample-info {
          top: 120px;
          left: 30px;
        }
      }
    }

    @include respond-to(xs) {
      .sample-block {
        .sample-info {
          position: static;
          text-align: center;
          padding-bottom: 10px;
        }
        .sample-img {
          width: 100%;
          height: inherit;
        }
      }
      .benefits-block {
        margin-bottom: 20px;
        .gray-block {
          height: auto;
        }
      }
    }

  }
  .landing-right-match {
    .sign-up-now-block {
      margin-top: 0;
    }
    .info-count-block {
      text-align: center;
      padding-top: 10px;
      padding-bottom: 10px;
      .count-items {
        color: darkgreen;
        font-weight: bold;
        font-size: 18px;
        display: inline-block;
      }
    }
  }
  .gray-title {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    padding: 15px 0 5px;
    color: #9caab0;
  }
  .lm-blue-title {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    padding: 15px 0 5px;
    color: #00a9c6;
  }
  #learn_more {
    background-color: transparent;
  }
}

@media screen and (max-width: 361px) {
  .hp-landing-container {
    .top-slide {
      background-image: url('/../../images/slides/HP SP w361.png');

      .blue-bottom-content {
        background-color: transparent;
      }
    }
  }
}

@media screen and (min-width: 362px) and (max-width: 600px) {
   .hp-landing-container {
    .top-slide {
      background-image: url('/../../images/slides/HP SP w501.png');

      .blue-bottom-content {
        background-color: transparent;
      }
    }
  }
}

.learn-more {
  padding: 16px 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.0) 0%, white 20%);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  &.learn-static {
    position: initial;
    display: block;
  }
  .lm-circle {
    border: 1px solid #00a9c6;
    border-radius: 100%;
    line-height: 0;
    padding: 8px 6px;
  }
}

.learn-more-banner-container {
  position: fixed;
  top: 0;
  width: 1170px;
  background: #fff;
  box-shadow: 0 1px 2px 0px rgba(0, 0, 0, .25);
  z-index: 1034;
  .learn-more-block {
    height: 85px;
    margin-bottom: 0;
    .lm-title {
      font-size: 16px;
      padding: 5px 0;
    }
    .lm-logo {
      padding: 0;
      img {
        width: 50px;
        height: 50px;
      }
    }
  }
  @include respond-to(md) {
    width: 970px;
  }
  @include respond-to(sm) {
    width: 750px;
  }
  @include respond-to(xs) {
    width: 100%;
    .learn-more-block {
      height: 50px;
      .lm-title {
        display: none;
      }
      .lm-logo {
        padding: 5px 0;
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}

.wirate-benefits {
  .left-title-icon {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 20px;
    margin-left: 20px;
    width: 115px;
    height: 115px;
  }
  .title {
    h1 {
      text-align: center;
      color: #00b3cb;
      font-size: 24px;
      font-weight: bold;
      padding: 0 120px;
    }
    h2 {
      text-align: center;
      color: #00b3cb;
      font-size: 20px;
      margin-bottom: 35px;
      margin-top: 0;
      padding: 0 120px;
    }
  }
  .desc {
    text-align: center;
    padding: 0 146px;
    margin-bottom: 30px;
  }
  @include respond-to(xs) {
    .left-title-icon {
      display: none;
    }
  ;
    .title {
      h1 {
        padding: 10px;
        margin: 0;
      }
      h2 {
        padding: 10px;
        margin: 0;
      }
    }
    .desc {
      padding: 10px;
      margin: 0;
    }
  }
  .benefits-title {
    text-align: center;
    font-size: 14px;
    color: darkgray;
    font-weight: bold;
    padding-bottom: 20px;
    padding-top: 10px;
  }

  .benefits-block {
    margin-bottom: 20px;
    padding-left: 80px;

    .block-item {
      height: auto;
      margin-bottom: 0;
      padding-bottom: 5px;
    }
    p {
      margin: 0 0 25px;
      &:last-child {
        margin: 0;
      }
    }
    .benefits-left-icon {
      float: left;
      width: 50px;
      margin-left: -65px;
      margin-top: 10px;
      &.rocket-part {
        margin-top: 30px;
      }
      img {
        margin-bottom: 20px;
        margin-top: -10px;
      }
      &.premium-part {
        margin-top: 20px;
        img {
          margin-bottom: 35px;
        }
      }
    }
  }

  .sample-block {
    width: 420px;
    background: white;
    position: absolute;
    bottom: 30px;
    left: 15px;
    z-index: 1;
    box-shadow: 0 1px 0 0 #ddd, 0 0 0 1px #eee, 0px 1px 10px 2px rgba(0, 0, 0, .4);
    .sample-info {
      position: absolute;
      top: 0;
      left: 220px;
    }
  }

  .bottom-slide {
    margin-right: -15px;
    position: relative;
    margin-left: -15px;
    margin-top: 15px;
    height: 340px;
    background: url('/../../images/slides/hpTopSlide.jpg') center center no-repeat;
    background-size: cover;
    &:before, &:after {
      display: table;
      content: " ";
    }
    &:after {
      clear: both;
    }
    .register-now {
      position: absolute;
      bottom: 0;
      background: rgba(0, 169, 198, .75);
      width: 100%;
      text-align: right;
      padding: 20px 30px;
    }
  }
  .premium-block {
    float: right;
    width: 300px;
    text-align: center;
    padding: 15px;
    margin: 0 15px;
    background: rgba(255, 255, 255, .85);
    .premium-title {
      font-weight: bold;
      padding: 5px 0;
      font-size: 20px;
      font-weight: bold;
    }
    .premium-subtitle {
      font-weight: bold;
      padding: 5px 0;
    }
    .premium-text {
      padding: 5px 0;

    }
    .premium-price {
      color: #00b3cb;
      font-size: 20px;
      font-weight: bold;
      padding: 15px 0;
      img {
        margin-right: 10px;
      }
    }
  }
  @include respond-to(xs) {
    .bottom-slide {
      height: auto;
      .register-now {
        position: relative;
        text-align: center;
      }
      .premium-block {
        margin: auto;
        float: none;
        width: auto;
      }
      .sample-block {
        position: relative;
        width: auto;
        margin: 0;
        bottom: 0;
        left: 0;
        .sample-info {
          z-index: 1;
          position: static;
          text-align: center;
          padding-bottom: 10px;
        }
        .sample-img {
          width: 100%;
          height: inherit;
        }
      }

    }
    .benefits-block {
      margin-bottom: 20px;
    }
  }

}

.form-login {
  padding: 15px;
  padding-bottom: 0;
  min-width: 300px;

  .fa {
    &.pull-left {
      margin-right: 0;
    }
  }
}

.dropdown-login {
  @include respond-to('xs') {
    .form-login {
      max-width: 280px;
      min-width: 240px;
    }
  }
}
.iv-modal-dialog {
  font-size: 12px;

  .modal-header {
    color: $blue;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    min-height: 105px;
    border-bottom: 7px solid #f6f6f6;
    margin-bottom: 15px;
  }

  &.modal-reason {
    .modal-header {
      color: $blue;
      font-size: 18px;
      text-align: center;
      font-weight: bold;
      min-height: 105px;
      border-bottom: 7px solid #f6f6f6;
      margin-bottom: 15px;
    }

    .variants {
      margin: 0 auto;
      margin-top: 15px;
      width: 330px;
      padding-left: 20px;
      padding-top: 20px;
      border-top: 1px solid #cccccc;
    }
  }

}

.iv-modal-dialog.modal-reason .modal-footer,
.iv-modal-dialog.modal-reason .modal-footer .variants {
  border: 0;
}

.iv-modal-dialog.modal-reason .modal-footer .variants {
  margin-top: -15px;
  padding-top: 0;
}

.iv-modal-dialog.modal-reason .variants label {
  margin-bottom: 0;
}

.iv-modal-dialog.modal-reason .modal-content {
  padding-left: 0;
  padding-right: 0;
}

.modal-request textarea {
  min-height: 85px;
}

.modal-request button.close, .iv-modal-dialog button.close {
  right: 15px;
  top: 10px;
  position: absolute;
  z-index: 10;
}

.modal-request .title {
  font-size: 18px;
  font-weight: bold;
  color: $blue;
  text-align: center;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 20px;
  margin-bottom: 25px;
}

.modal-request .text {
  line-height: 25px;
}

.modal-request .subtitle {
  color: $blue;
  margin-bottom: 5px;
}

.modal-request .gray-content {
  background-color: #f6f6f6;
  padding: 11px;
}

.modal-request .gray-content .content {
  padding: 11px;
  color: #8a8a8a;
  background-color: #fdffff;
}

.modal-request .content textarea {
  border: 2px $blue solid;
}

.modal-request .content textarea[disabled] {
  background-color: #fff;
  cursor: auto;
  -webkit-text-fill-color: #555;
}

.modal-request .modal-footer {
  border-top: 7px solid #f6f6f6;
  margin-left: -30px;
  margin-right: -30px;
}

.modal-request .buttons-response {
  margin-top: 20px;
}

.modal-request .buttons-response > div:last-child {
  padding-right: 60px;
  @include respond-to('xs') {
    padding-right: 15px;
    margin-top: 5px;
  }
}

.modal-request .buttons-response > div:first-child {
  padding-left: 60px;
  @include respond-to('xs') {
    padding-left: 15px;
    margin-bottom: 5px;
  }
}

.modal-dialog {
  &.modal-sm {
    width: 360px;
  }
  .buttons-bar {
    height: 25px;
    margin-top: 30px;
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .survey-form {
    width: 100%;
    display: flex;
    form {
      max-width: 100%;
      margin: auto;
    }
  }
  .img-container{
    width: 100%;
    display: flex;
    img{
      margin: auto;
    }
  }
  h4{
    text-align: center;
    font-weight: 600;
    color: #00a9c6;
    margin-top: 20px;
  }
  hr{
    border-width: 3px;
    border-color: #f7f7f7;
  }
  .green-header{
    width: 100%;
    display: flex;
    background-color: #00a9c6;
    padding: 10px 0;
    margin: 20px 0;
    h3{
      font-weight: 900;
      text-align: center;
      color: #F0F0F0;
      margin: auto;
    }
  }
  &, &.modal-sm, &.modal-lg {
    @include respond-to(xs) {
      width: auto;
      margin: 0;
    }
  }
  .modal-body {
    padding: 15px;
  }

  button.close {
    position: absolute;
    right: 10px;
    top: 5px;
  }

}

.modal-dialog.alert-big-title {

  .modal-body {
    @include respond-to(xs) {
      padding: 15px 0;
    }

  }
  .title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    background-color: #25a9c6;
    padding: 16px 32px;
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 25px;
  }
  .text {
    text-align: justify;
    margin-bottom: 15px;
    margin-right: 25px;
    margin-left: 25px;
  }
  .btns {
    text-align: right;
    margin-top: 25px;
    margin-right: 25px;
    margin-left: 25px;
  }
}

.crop-modal {
  .crop-modal-canvas {
    width: 100%;
    @include respond-to(xs) {
      height: 340px;
    }
  }
}

.modal-dialog .modal-body.download-app-modal {
  padding: 0;
  .app-info-container {
    padding: 30px;
    background: url("/../../images/app-modal-background.jpg") no-repeat;
    background-size: cover;
    background-position: center center;
    .app-info-text {
      padding: 5px;
      font-size: 38px;
      color: #fff;
      line-height: 1.1;
      text-align: center;
      background: rgba(0, 0, 0, .7);
    }
  }
  .app-download-link {
    display: block;
    text-decoration: none;
    color: #fff;
    text-align: center;
    font-size: 46px;
    padding: 10px 10px 10px 0;
    background: #00a9c6;
  }

}

.modal-content {
  border: none;
}

.fade.show {
  opacity: 1;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal.show .modal-dialog {
  transform: translate(0);
}

.modal-full-width {
  width: 100%;
}

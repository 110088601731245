.reg-modal-form, .reg-modal-type, .premium-buy-modal, .premium-again {
  $circle-size: 14px;
  $stepContainerWidth: 260px;
  $delayFor2: ($stepContainerWidth - ($circle-size*2))/4;
  $delayFor3: ($stepContainerWidth - ($circle-size*3))/6;
  .modal-title {
    font-size: 20px;
    margin: 0 15px;
    padding: 15px 0;
    text-align: center;
    font-weight: bold;
  }
  .payment-content {
    border-bottom: 8px solid #f6f6f6;
  }
  .modal-footer {
    background: #f6f6f6;
    border-top: none;
    text-align: center;
    padding: 15px;
    font-weight: bold;
  }
  .reg-modal-step {
    text-align: center;
    padding: 0 25px 10px;
    .nums {
      width: $stepContainerWidth;
      margin: -8px auto auto;
      .circle {
        float: left;
        width: $circle-size;
        height: $circle-size;
        background: darkgray;
        color: #fff;
        border-radius: 100%;
        line-height: 0.7;
        font-size: 12px;
        padding: 3px;
        margin-right: $delayFor2;
        margin-left: $delayFor2;
        &.active {
          background: #789200;
        }
        &.complete {
          background: #00a9c6;
        }
      }
    }
    .line {
      border-bottom: 2px solid #00a9c6;
      margin: 0 -25px;
    }
  }
  &.for-wirate {
    .modal-title {
      img {
        position: absolute;
        left: 0;
        top: 0;
        margin: 10px 15px;
      }
    }
    .reg-modal-step {
      .nums .circle {
        margin-right: $delayFor3;
        margin-left: $delayFor3;
      }
    }
  }
}

.reg-modal-type {
  .info-text {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-left: 3rem;
    padding-right: 3rem;
    text-align: center;
  }
  .answer-buttons {
    .btn {
      font-size: 2rem;
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;
      font-weight: bold;
      border-radius: 0;
      border: none;
    }
  }
}

.reg-modal-form {
  .modal-body {
    .annotation-title {
      font-size: 9pt;
    }
    .frontend-sign-up {
      .confirm {
        margin: 15px 0;
      }
    }
  }
}

.modal-dialog.premium-buy-modal {
  .modal-body {
    padding-left: 0;
    padding-right: 0;
    .content.shadow-block {
      box-shadow: none;
    }
  }
}

$feed_height: 584px;
.feed-side {
  .side-link-block {
    height: auto;
    flex-grow: 1;
    padding: 10px;
    line-height: 1.2;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: rgba(255, 255, 255, 0.9);
    text-align: center;
    .middle-link {
      display: inline-block;
    }
    .title {
      margin-top: 0;
      margin-bottom: 5px;
      font-size: 20px;
      font-weight: bold;
      width: 100%;
    }
    .desc {
      margin-bottom: 5px;
      text-align: left;
      font-size: 16px;
      line-height: 1.8;
      font-weight: bold;
      min-width: 70%;
    }
    .circle-arrow {
      @include circle-arrow(85px, 3px);
      opacity: 0.8;
    }
    &:hover, &:focus {
      text-decoration: none;
      color: rgba(255, 255, 255, 1);
      .circle-arrow {
        opacity: 0.9;
      }
    }
    &:active {
      color: rgba(255, 255, 255, 1);
      .circle-arrow {
        opacity: 1;
      }
    }
  }

  .share-tips-link-block {
    @extend .side-link-block;
    background: $blue;

  }

  .premium-link-block {
    @extend .side-link-block;
    background: $orange;
    position: relative;
    .desc.for-premium {
      font-size: 20px;
      line-height: 1.4;
    }
    &:after {
      content: "";
      background: url('/../../images/rockets/rocket_blue.svg') no-repeat center;
      background-size: 50%;
      opacity: 0.2;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
    }
  }
}

.notify-right-block {
  display: flex;
  flex-direction: column;
  height: $feed_height;
  margin-left: -15px;
  margin-right: -15px;
}

.swiper-pagination-bullet {
  width: 9px;
  height: 9px;
  background: #707070;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background: white;
  border: 3px solid #707070;
  box-sizing: content-box;
}

.swiper-container-horizontal {
  & > .swiper-pagination-bullets {
    .swiper-pagination-bullet-active {
      margin-bottom: -3px;
    }
  }
}

.swiper-container-horizontal {
  & > .swiper-pagination-bullets {
    bottom: -5px;
  }
}
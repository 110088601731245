.slider {
  .result-step.premium-successful {
    background-image: url('/../../images/premium-successfull.jpg');
    .caption {
      .result-steps-content {
        width: 500px;
        p {
          font-size: 46px;
        }
        @include respond-to(sm) {
          width: 460px;
          p {
            font-size: 40px;
          }
        }
        @include respond-to(xs) {
          width: 250px;
          p {
            font-size: 20px;
          }
        }
      }
    }
  }
}
.dark-green-back {
  background-color: $dark-green;
  color: $font-dark-bg;
  @include link-dark();
}

.bg-dark-green {
  @extend .dark-green-back;
}

.bg-blue {
  color: $font-dark-bg;
  background-color: $blue !important;
  @include link-dark();
}

.bg-dark-blue {
  color: $font-dark-bg;
  background-color: $dark-blue !important;
  @include link-dark();
}

.bg-green {
  color: $font-dark-bg;
  background-color: #afba04 !important;
  @include link-dark();
}

.bg-orange {
  color: $font-dark-bg;
  background-color: #fb9b00 !important;
  @include link-dark();
}

.benefits-page {

  .premium-benefits {
    margin-top: 10px;
  }

  .basic-services {
    margin-left: 5px;
  }

  .row.upgrade-separator .basic-services-title {
    margin-left: 0;
    text-align: center;
  }

  .title {
    font-size: 14px;
    color: $blue;
    font-weight: bold;
  }

  .col-md-2 {
    width: auto;
  }

  .col-md-10 {
    padding-left: 0;
  }

  .color-row {
    padding: 10px 50px 10px 0;

    &.color-row-padding p {
      font-size: 14px;
    }
  }

  .color-row-padding {
    padding: 10px 0 10px 30px;
  }

  .row.basic-services:last-child {
    margin-top: 20px;
  }

  .grow-network-block {
  }

  .promote-business-block {
    img {
      @include respond-to(md) {
        width: 82px;
      }
    }
  }

}


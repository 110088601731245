.slider {
  .result-step.connector {
    .caption {
      .result-steps-content {
        p {
          margin-top: 30px;
          padding-bottom: 15px;
          line-height: 1.2;
        }
        @include respond-to(sm) {
          p {
            margin-top: 10px;
            padding-bottom: 10px;
            font-size: 30px;
          }
          a {
            @include circle-arrow(80px, 3px);
          }
        }
        @include respond-to(xs) {
          p {
            margin-top: 2px;
            margin-bottom: 0;
            padding-bottom: 2px;
            font-size: 16px;
          }
          a {
            @include circle-arrow(46px, 2px);
          }

        }
      }
    }
  }
}
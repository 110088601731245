.reg-modal-type .modal-body {
  padding-top: 15px;
  padding-bottom: 20px;
  .type-title {
    font-size: 16px;
    padding-left: 10px;
    color: #00a9c6;
    font-weight: bold;
    text-align: center;
  }
  .gray-or {
    color: darkgray;
    padding-left: 10px;
    font-size: 16px;
  }
  .i-am {
    padding-left: 10px;
    margin-top: 20px;
  }
  .type-radio-block {
    input[type='radio'] {
      display: none;
    }
    label {
      display: block;
      margin: 20px 0;
      font-weight: normal;
      position: relative;
      padding: 10px 0 10px 55px;
      font-size: 14px;
      line-height: 1.2;
      color: black;
      & > a {
        cursor: pointer;
        color: darkgray;
      }
    }
    input[type='radio'] + label:before {
      cursor: pointer;
      content: '\f058';
      font-family: FontAwesome;
      font-size: 40px;
      top: 5px;
      color: #c3c3c3;
      position: absolute;
      left: 5px;
    }
    input[type='radio']:checked + label:before, input[type='radio']:checked + label > a {
      color: #00a9c6;
    }
  }
  .next-btn {
    margin: 25px 0 15px;
    button.cbtn-lg {
      // width: 80%;
      @include respond-to(xs) {
        //  width:100%;
      }
    }
  }
}
.top-partners {
  @extend .top-projects;

  .partners-list {
    @extend .projects-list;

    .partner-wishes {
      @extend .partner-talent;

      hr {
        margin-top: 0;
        margin-bottom: 10px;
        border-top: 3px;
      }
    }

    .partner-wishes {
      .wrap-progress {
        padding-top: 5px;
        padding-bottom: 5px;
        border: 1px solid $gray;
        border-right-width: 0;
        border-left-width: 0;
      }
    }

    .partner-info {
      padding: 10px;
      box-sizing: border-box;
      height: 90px;

      > div {
        width: 50%;
        float: left;
        font-weight: normal;
      }

      .partner-join-ideas {
        padding-left: 50px;
        text-transform: uppercase;
      }

      .partner-join-ideas {
        p {
          margin: 0;
          margin-top: 41px;
          line-height: 1.2;
          text-align: left;
          float: left;
        }
        span {
          &:first-child {
            font-weight: bold;
          }
        }
      }

      .personal-partner-info {

        h4 {
          font-weight: bold;
          text-transform: uppercase;
          margin: 0;
        }

        p {
          span {
            &:first-child {
              font-weight: bold;
              text-transform: uppercase;
            }
          }
        }

      }
    }
  }
}

.top-grid-info {
  .grid-content, .grid-footer, .grid-header {
    margin-left: -15px;
    margin-right: -15px;
    &:before, &:after {
      content: " ";
      display: table;
      box-sizing: border-box;
      clear: both;
    }
  }
  .grid-header {
    background: white;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: 3px solid #00a9c6;
    h2 {
      color: #00a9c6;
      font-weight: bold;
      text-align: center;
    }
    h3 {
      color: $dark-green;
      text-align: center;
      font-size: 14px;
      margin: 5px 0 15px;
    }
  }
  .grid-content {
    padding-top: 20px;
    background: #FBFFFF;
    min-height: 260px;
    .block-item {
      float: left;
      width: 25%;
      padding-right: 10px;
      padding-left: 10px;
      background: transparent;
      .bar-item {
        border: 2px solid #e7f2f6;
      }
      @for $i from 1 to 12 {
        &.col-#{$i} {
          width: percentage($i / 12);
        }
      }
      @include respond-to(sm) {
        width: 50% !important;
      }
      @include respond-to(xs) {
        width: 100% !important;
      }
    }
  }
  .grid-footer {
    background: white;
    border-top: 3px solid #00a9c6;
    padding-left: 15px;
    padding-right: 15px;
    .grid-buttons {
      float: left;
      width: 66.666666%;
      .btn {
        margin-right: 0.25rem;
      }
      @include respond-to(xs) {
        width: 100%;
      }
      margin-top: 15px;
      margin-bottom: 15px;
    }
    .grid-total {
      margin-top: 5px;
      margin-bottom: 5px;
      float: left;
      text-align: right;
      width: 33.333333%;
      @include respond-to(xs) {
        width: 100%;
      }
      color: $dark-green;
      .main-counter {
        margin: 0;
      }
    }
  }
}

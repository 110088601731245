.verification-form-block {
  .content {
    padding-top: 17px;
    padding-bottom: 17px;
    margin-bottom: 10px;
  }
  h2 {
    font-size: 16px;
    font-weight: bold;
    padding: 5px 0;
    margin-top: 0;
  }
  .rect-number {
    display: inline-block;
    padding: 2px 8px;
    margin-top: 10px;
    background-color: #00a9c6;
    color: white;
    font-weight: bold;
    font-size: 16px;
  }
  .slider {
    margin-top: 0;
    .result-step {
      background-image: url('/../../images/slide_verification.jpg');
      height: 280px;
      .caption {
        display: flex;
        margin: auto;
        height: inherit;
        transform: skewX(-14deg);
        width: 50%;
        .result-steps-content {
          margin: auto;
          padding: 0;
          transform: skewX(14deg);
          display: inline-block;
          font-size: 2.5em;
          width: 60%;
        }
      }
    }
    @include respond-to(xs) {
      .result-step {
        height: 180px;
        .caption {
          width: 100%;
          transform: skewX(0deg);
          .result-steps-content {
            transform: skewX(0deg);
            width: 60%;
          }

        }
      }
    }
  }
  .order-information {
    margin-top: 30px;
    .verification-get-for-label {
      font-weight: bold;
      font-size: 22px;
    }
    .verification-amount {
      display: inline-block;
      color: #00a9c6;
      font-size: 34px;
      font-weight: bold;
      margin-left: 15px;
    }
  }
}

.verification-order {
  .content {
    padding: 17px 15px;
    margin-bottom: 10px;
  }
  .header-section {
    .fa-check-circle {
      font-size: 6em;
      color: #afb904;
      float: right;
      @include respond-to(xs) {
        font-size: 4em;
        padding: 18px 0;
        float: none;
      }
    }
  }
  .billing-section {
    .billing-title {
      color: #afba04;
      font-weight: bold;
      font-size: 14px;
    }
  }

  &.ng-dirty {
    .doc-container-section.doc-dirty {
      input[type='file'] + label {
        background-color: #a94442;

      }
      *[ng2FileDrop] {
        background-color: #e4b9co;
        border: 5px dashed #c85756;
        .drop-label {
          color: #c85756;
        }
      }
    }
  }

  .doc-container-section {
    img {
      max-width: 100%;
      max-height: 100%;
      min-width: 60%;
      min-height: 60%;
      border: 5px solid #00a9c6;
      margin: auto;
      display: inline-block;
    }

    .pdf-container {
      margin: auto;
      width: 80%;
      background-color: white;
      height: 80%;
      display: flex;
      border: 5px solid #00a9c6;
      .doc-name {
        font-size: 30px;
        font-weight: bold;
        margin: auto;
        text-transform: uppercase;
        text-align: center;
        color: #00a9c6;
      }
    }

    input[type='file'] {
      display: none;
      & + label {
        margin-top: 10px;
        text-align: center;
        padding: 5px 10px;
        text-transform: none;
      }
    }
    *[ng2FileDrop] {
      display: flex;
      padding: 5px;
      margin-top: 10px;
      height: 360px;
      @include respond-to(xs) {
        height: 300px;
      }
      background-color: #fbfbfb;
      border: 5px dashed #F2F2F2;

      .drop-label {
        font-size: 35px;
        font-weight: bold;
        display: inline-block;
        margin: auto;
        padding: 20px;
        text-align: center;
        color: #f2f2f2;
      }

      &.doc-over {
        background-color: #e6fbff;
        border-color: #00a9c5;
        .drop-label {
          color: #00a9c5;
        }
      }

    }
  }

  .pay-type-section {
    label {
      font-weight: normal;
    }
    .pay-button {
      text-align: left;
      min-width: 140px;
    }
  }
}

.verified-profile-block {
  background-color: white;
  padding: 10px 0;
}

.verification-menu {
  .content {
    padding-top: 17px;
    padding-bottom: 17px;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 16px;
    font-weight: bold;
    padding: 5px 0;
    margin-top: 0;
  }
  .slider {
    margin-top: 0;
    .result-step {
      background-image: url('/../../images/slide_verification.jpg');
      height: 340px;
      .caption {
        display: flex;
        margin: auto;
        padding-top: 0;
        height: inherit;
        transform: skewX(-14deg);
        width: 50%;
        .result-steps-content {
          margin: auto;
          padding: 0;
          transform: skewX(14deg);
          display: inline-block;
          font-size: 3em;
          width: 60%;
        }
      }
    }
    @include respond-to(xs) {
      .result-step {
        height: 200px;
        .caption {
          width: 100%;
          transform: skewX(0deg);
          .result-steps-content {
            transform: skewX(0deg);
            width: 80%;
          }

        }
      }
    }
  }
}

.blue-title {
  text-align: center;
  background-color: #00a9c6;
  color: white;
  padding: 15px;
  font-size: 24px;
  font-weight: bold;
  margin: -17px -15px 20px;
}

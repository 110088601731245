$sliderHeight: 362px;
$sliderWidth: 725px;
$sliderTextWidth: $sliderWidth*0.8;

$sliderHeightSM: $sliderHeight*0.75;
$sliderWidthSM: $sliderWidth*0.75;
$sliderTextWidthSM: $sliderWidthSM*0.8;

$sliderHeightXS: $sliderHeightSM*0.75;
$sliderWidthXS: $sliderWidthSM*0.75;
$sliderTextWidthXS: $sliderWidthXS*0.8;

$bg: rgba($blue, .76);
$bg-premium: rgba($light-orange, .76);

@mixin circle-arrow($radius, $border) {
  @extend .circle;

  $arrow-size: $radius*0.55;
  $arrow-i-size: $radius*0.65;

  width: $radius;
  height: $radius;
  display: inline-flex;
  position: relative;
  border: $border solid #fff;
  text-align: center;

  img {
    width: $arrow-size;
    height: $arrow-size;
    margin: auto;
  }

  i {
    display: inline-block;
    transform: rotate(90deg);
    margin: auto;
    &:before {
      opacity: 1;
    }

    & {
      font-size: $arrow-i-size;
    }
  }
}

%step {
  background-size: cover;
  min-height: $sliderHeight;
  text-align: center;

  @include respond-to('sm') {
    min-height: $sliderHeightSM;
  }
  @include respond-to('xs') {
    min-height: 0;
  }
}

%imageSize {
  width: 105px;
}

%caption {
  display: inline-block;
  margin-top: 0;
  padding-top: 0;
  min-height: $sliderHeight;
  width: $sliderWidth;

  font-family: 'Open Sans', sans-serif;
  z-index: 0;
  position: relative;
  &:before {
    z-index: -1;
    content: ' ';
    background-color: $bg;
    height: 100%;
    width: 90%;
    left: 5%;
    position: absolute;
    transform: skewX(-18deg);
  }
  > * {
    padding-top: 15px;
    padding-bottom: 25px;
    color: #fff;
    text-align: center;
  }

  @include respond-to('sm') {
    min-height: $sliderHeightSM;
    width: $sliderWidthSM;
  }
  @include respond-to('xs') {
    width: 100%;
    min-height: 0;
    margin-top: 0;
    &:before {
      width: 60%;
      left: 20%;
      transform: skewX(-16deg);
    }
  }

}
textarea {
  resize: none;
}

%big-button {
  @extend .row-table;

  text-align: center;
  vertical-align: middle;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
}

.upgrade-button a {
  @extend %big-button;
}

.sign-button-wrap {
  padding-left: 0;
  padding-right: 0;
}

.sign-button {
  @extend %big-button;

  height: 70px;
  width: 100%;
  background-color: $link-color;
}

.sign-button:hover {
  background-color: $link-color-hover;
}

.upgrade-button.account a {
  font-size: 18px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.btn, .input-group-btn {
  outline: 0;
  &:active {
    outline: 0;
    &:focus {
      outline: 0;
    }
  }
}

.ui-select-container {
  .icon {
    width: 15px;
  }
}

.form-control {
  padding: 6px 6px;
}

.radio {
  input[type=radio].ng-dirty.ng-invalid + label {
    border-bottom: 1px solid $red-error;
    box-shadow: 0 0 5px $red-error;
    border-radius: 4px;
  }
}

%cbtn {
  $height: 24px;

  position: relative;
  outline: 0;
  padding-left: 20px;
  font-style: italic;
  font-size: 14px;
  color: #fff;
  border: 0;
  display: inline-block;
  min-width: 70px;
  background-color: $link-color;
  text-transform: uppercase;
  height: $height;
  border-radius: $height / 2;
  padding-top: 2px;
  padding-bottom: 0;

  .loader {
    display: none;
    height: $height - 4;
    width: $height - 4;
    margin-left: -16px;
  }

  > div {
    float: left;
    display: inline-block;
  }

  .text {
    padding-left: 5px;
  }

  &:hover,
  &:focus {
    outline: 0;
    background-color: $link-color-hover;
    color: #fff;
  }

  &.btn-default[disabled] {
    background-color: #bbb;
  }

  &.inverse {
    background-color: #fff;
    color: $link-color;

    &:hover,
    &:focus {
      background-color: $link-color-hover-dark;
      color: $link-color;
    }
  }

  &:before {
    position: absolute;
    left: 7px;
    content: '\f054';
    font-family: FontAwesome;
    font-style: normal;
  }

  &.loading-progress {
    &:before {
      display: none;
    }
    .loader {
      display: block;
    }
  }

  &.loading {
    &:before {
      content: url('/../../images/circle-cbtn.svg');
      top: 4px;
      left: 4px;
      width: 16px;
      height: 16px;
    }
  }
}

.cbtn {
  $height: 42px;
  @extend %cbtn;
  &.cbtn-2x {
    height: $height;
    border-radius: $height/3.5;
    word-wrap: normal;
    &:before {
      top: 7px;
      left: 5px;
      font-size: 21px;
    }
  }
  .loader {
    display: none;
    height: $height - 4;
    width: $height - 4;
    margin-left: -16px;
  }
}

.advanced_search{
  margin-top: 20px;
  span{
    color: #00a9c6;
    cursor: pointer;
  }

}

.cbtn-premium {
  background-color: $light-orange;
  color: #fff;

  &:hover,
  &:focus {
    background-color: $orange;
  }

  &.inverse {
    background-color: $link-color;
    color: #fff;

    &:hover,
    &:focus {
      background-color: $link-color-hover-dark;
    }
  }
}

.cbtn-gray {
  background-color: $light-gray;

  &:hover,
  &:focus {
    background-color: $gray;
  }

}

.cbtn-lg {
  $height: 30px;
  border-radius: $height/2;
  height: $height;
  line-height: 1.8;
  font-size: 16px;
  padding-left: 24px;
  font-weight: bold;
  .loader {
    display: none;
    height: $height - 4;
    width: $height - 4;
    margin-left: -16px;
  }
  &.loading {
    &:before {
      top: 3px;
      left: 3px;
      width: 19px;
      height: 19px;
    }
  }
}

.btn-link {
  color: #00a9c6;

  &:hover,
  &:focus,
  &:active,
  &:link {
    color: #269abc;
    outline: none;
  }
}

// For bootstrap-ui rating
span {

  &.ng-invalid-rating-required {
    &.ng-dirty {
      border: 1px #843534 solid;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
    }
  }

  &:active:focus {
    outline: none;
  }

  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }
}

.group-icons {
  display: inline-block;
}

.btn-group {
  .cbtn {
    @extend %cbtn;

    &:before {
      content: '';
      font-style: normal;
      margin-right: 0;
    }

    &:first-child {
      border-radius: 10px 0 0 10px;
    }

    .active {
      background-color: #afba04;
    }

  }
}

.facebook-reg {
  background-color: #2d78bd;
  color: #fff;
  min-height: 40px;
  //display: block;
  height: 40px;
  width: 40px;
  > div {
    @extend %big-button;
    padding: 0;
    min-height: 40px;
    height: 100%;
  }

  .fa {
    min-height: 40px;
    height: 40px;
    background-color: #296fae;
    width: 40px;
    text-align: center;
    font-size: 20px;
  }

  .text {
    background-color: #2d78bd;
    padding: 0 10px 0 0;
    font-size: 10px;
    text-align: center;
  }

  &:hover {
    color: #fff;
    text-decoration: none;
  }
}

.linkedin-reg {
  @extend .facebook-reg;
  background-color: #017fb2;
  .fa {
    background-color: #0175a4;
  }

  .text {
    background-color: #017fb2;
  }
}

.apple-reg {
  @extend .facebook-reg;
  background-color: #070707;
  .fa {
    background-color: #070707;
  }

  .text {
    background-color: #017fb2;
  }
}

.facebook-reg-top {
  background-color: #2d78bd;
  display: inline-block;
  color: #fff;
  height: 40px;
  margin: 0 0 10px 0px;
  

  > div {
    @extend %big-button;
    font-weight: normal;
    text-transform: none;
    padding: 0;
  }

  .fa {
    height: 40px;
    padding-top: 11px;
    background-color: #296fae;
    width: 40px;
    text-align: center;
    font-size: 22px;
  }

  .text {
    //padding: 10px 15px 5px 5px;
    font-size: 16px;
    text-align: center;
    width: 100%;
  }

  &:hover {
    color: #fff;
    text-decoration: none;
  }
}

.linkedin-reg-top {
  @extend .facebook-reg-top;
  background-color: #017fb2;
  .fa {
    background-color: #0175a4;
  }
  margin: 0 0 10px 15px;
}

.apple-reg-top {
  @extend .facebook-reg-top;
  background-color: #070707;
  .fa {
    background-color: #070707;
  }
  margin: 0 0 10px 15px;
}

ng-select {
  &.ng-invalid.ng-dirty {
    .ng-select-container {
      border: 1px #843534 solid;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
    }
  }
}

iv-input-with-currency {
  &.ng-invalid.ng-dirty {
    .ng-select-container:not(.ng-has-value) {
      border: 1px #843534 solid;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
    }
  }
}

label {
  &.focused,
  &.focused.blue-star:after,
  &.focused.black-star:after,
  &.focused.orange-star:after {
    color: $blue;
  }
}

.administrate-account {
  margin-left: 5px;
  @include respond-to(xs) {
    margin-left: -15px;
  }
  h2 {
    @include respond-to(xs) {
      font-size: 22px;
    }
  }
  .order-history {
    h2 {
      display: inline-block;
      margin-bottom: 5px;
      font-weight: bold;
      float: left;

    }
    .history-title i.fa.fa-cog {
      color: #00A9C6;
      font-size: 68px;
      @include respond-to(xs) {
        margin-left: 0;
        font-size: 50px;
      }
    }
    .order-details {
      background-color: #FFFFFF;
      line-height: 2;
      padding: 15px;
    }
  }
}

.admin-info h2 {
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: bold;
  float: left;
  margin-left: 150px;
  @include respond-to(xs) {
    margin-left: 0;
  }
}

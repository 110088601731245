$x-large: 1200px;
$medium: 1199px;
$small: 991px;
$x-small: 767px;

$blue: #00a9c6;
$dark-blue: #1d8ea1;
$lighten-blue: #00A9C5;
$light-gray: #9caab1;
$lighter-gray: #c6c6c6;
$gray: darkgray;
$dark-gray: #767676;
$green: #afba04;
$dark-green: #789200;
$light-orange: #ff9933;
$orange: #fb9b00;
$dark-orange: #c67e00;
$black: #000;
$red-error: #f00;
$dark-red: #a94442;

$main-background: #f6f6f6;

$link-color: $blue;
$link-color-hover: #269abc;
$link-color-dark: #fff;
$link-color-hover-dark: #ddd;

$font-dark-bg: #fff;
$font-light-bg: #000;

@mixin respond-to($media) {
  @if $media == lg {
    @media only screen and (min-width: $x-large) {
      @content;
    }
  } @else if $media == md {
    @media only screen and (max-width: $medium) {
      @content;
    }
  } @else if $media == sm {
    @media only screen and (max-width: $small) {
      @content;
    }
  } @else if $media == xs {
    @media only screen and (max-width: $x-small) {
      @content;
    }
  }
}

@mixin link-light() {
  a {
    color: $link-color;
    &:hover, &:focus {
      color: $link-color-hover;
      outline: none;
    }
    &:focus {
      text-decoration: none;
    }
  }
}

@mixin link-dark() {
  a {
    color: $link-color-dark;
    &:hover, &:focus {
      color: $link-color-hover-dark;
      outline: none;
    }
    &:focus {
      text-decoration: none;
    }
  }
}

@mixin make-circle($diameter, $border-width) {
  @extend .circle;
  width: $diameter;
  height: $diameter;
  border: $border-width solid;
}

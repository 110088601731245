@import "variables";

rating {
  font-size: 18px;

  &.ng-dirty.ng-invalid {
    border-bottom: 1px solid #a94442;
    box-shadow: 0 0 5px #a94442;
    border-radius: 4px;
  }

  .bs-rating-star {
    color: $light-gray;
    margin: 0 1px;
  }

  .bs-rating-star.active {
    color: $blue;
  }

}
.partnership-slider {
  .slider-header, .slider-content, .slider-footer {
    margin-left: -15px;
    margin-right: -15px;
    &:before, &:after {
      content: " ";
      display: table;
      box-sizing: border-box;
      clear: both;
    }
  }
  .slider-header {
    background: white;
    border-bottom: 3px solid #00a9c6;
    h2 {
      color: #00a9c6;
      font-weight: bold;
    }
  }
  .slider-footer {
    background: white;
    border-top: 3px solid #00a9c6;
  }

  .slider-content {
    background: #FBFFFF;
    padding: 0;
    position: relative;
    .swiper-button-prev, .swiper-button-next {
      position: absolute;
      color: #00a9c6;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: 70px;
      margin: 0;
      height: 120px;
      width: 50px;
      top: 10px;

      padding-top: 30px;
      line-height: 70px;
      text-shadow: rgba(0, 0, 0, 0.6) 0 1px 2px;
      text-rendering: auto;
      background: none;

      &.swiper-button-disabled {
        color: #e4e4e4;
        opacity: 1;
      }
    }
    .swiper-button-prev {
      &:before {
        content: "\F053";
      }
      left: 0;
    }
    .swiper-button-next {
      &:before {
        content: "\F054";
      }
      right: 0;
    }

    .swiper-container {
      margin: 10px 60px;
      max-width: calc(100% - 120px);
      .swiper-slide {
        border: 2px solid #e7f2f6;
        line-height: 118px;
        min-width: 120px;
        min-height: 120px;
        background-color: #fff;
        text-align: center;
        img {
          display: inline;
          max-width: 100px;
          max-height: 100px;
        }
      }
      @include respond-to(sm) {
        margin: 10px;
        max-width: calc(100% - 20px);
      }
    }
    @include respond-to(sm) {
      .swiper-button-prev, .swiper-button-next {
        display: none;
      }
    }
  }
}
.SliderPartnership {
    padding-left: 55px;
    padding-right: 65px;
}

.SliderPartnership .bx-wrapper {
    margin-right: -15px !important;
    margin-left: -15px !important;
    -moz-box-shadow: 0 0 0;
    -webkit-box-shadow: 0 0 0;
    box-shadow: 0 0 0;
    border: 0px;
    background: transparent;
}

.SliderPartnership .bx-wrapper .bx-viewport {
    -ms-touch-action: none;
    -moz-box-shadow: 0 0 0 0;
    -webkit-box-shadow: 0 0 0 0;
    box-shadow: 0 0 0 0;
    border: 0;
    left: auto;
    background: transparent;
}

.slider ul {
    padding-left: 0;
}

.carousel-control.left {
    background-image: none;
    margin-left: -50px;
    width: 40px;
    padding-top: 40px;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.carousel-control.right {
    background-image: none;
    right: -50px;
    width: 40px;
    padding-top: 40px;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.slider3 .carousel-control.left {
    background-image: none;
    margin-left: -55px;
    width: 40px;
    padding-top: 18px;
}

.slider3 .carousel-control.right {
    background-image: none;
    right: -50px;
    width: 40px;
    padding-top: 18px;
}

.carousel-control, .carousel-control:hover {
    opacity: 1;
}

.bx-next, .bx-prev {
    color: #00a9c6;
    opacity: .9;
    font-size: 65px;
}

.bx-next:hover, .bx-prev:hover {
    color: #269abc;
}

.slider3 .item img {
    display: inline;
}

.slider3 li {
    margin-right: 3px !important;
}




.comment-photo {
  width: 80%;
  border-radius: 50%;
  margin-right: -5px;
  margin-top: 5px;
  float: right;
}

.comment {
  margin-bottom: 5px;
}
@import "variables";

.site-footer {

  padding-top: 60px;

  a.black:visited {
    color: black;
  }

  .link-group {
    padding-bottom: 16px;
  }

  .footer-logo__img {
    margin: auto;
    width: 217px;
    max-width: 100%;
    padding-bottom: 20px;
  }

  .footer-logo-container {
    padding-bottom: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 20px;
    max-width: 150px;
    margin: auto;

/*    & > a {
      display: inherit;
    }*/
  }

  .menu-item {
    padding-top: 16px;
  }

  .app-links__img {
    max-height: 40px;
  }

  .app-link--first {
    padding-right: 5px;
  }

  .app-link--second {
    padding-left: 5px;
  }

  .copyright {
    padding-top: 90px;
    padding-bottom: 12px;
  }

  .legal-notice {
    padding-right: 64px;
  }

  @media (min-width: $x-small) {
    .link-group {
      display: inline-block;
      vertical-align: top;
      text-align: left;
      padding-right: 0;
    }

    .link-group-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  @media (min-width: $x-large) {
    .link-group-wrapper {
      padding-left: 40px;
    }

    .copyright {
      padding-top: 130px;
    }

    .copyright-row {
      display: flex;
      align-items: flex-end;
    }
  }
}

.entrepreneur-menu {
  .project-logo-part {
    text-align: left;
    .logo {
      margin: 0;
      width: 100%;
      height: auto;
    }
    @include respond-to(xs) {
      text-align: center;
      .logo {
        max-width: 140px;
      }
    }
  }
}

.video-block {
  text-align: center;
  padding-left: 20%;
  margin-top: 15px;
  padding-right: 20%;
  margin-bottom: 15px;
  @include respond-to(xs) {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.create-project-button {
  text-align: center;
  font-weight: bold;
  padding: 15px;
  a:hover {
    text-decoration: none;
  }
  .fa-plus {
    font-size: 70px;
  }
}

.my-project-counters {
  display: table;
  width: 100%;
  &.green-line {
    border-bottom: 6px solid $dark-green;

  }
  &.orange-line {
    border-bottom: 6px solid $orange;
  }
  .counter-row {
    display: table-row;
  }
  .counter-more-lnk {
    display: table-cell;
    text-align: center;
    color: $black;
    padding-top: 10px;
    padding-bottom: 10px;
    .counter-icon {
      font-size: 2em;
      &.fa-stack {
        font-size: 1em;
      }
      &.stars {
        font-size: 1.4em;
        @include respond-to(xs) {
          font-size: 11px;
        }
      }
      &.green {
        color: $dark-green;
      }
      &.orange {
        color: $orange;

      }
      &.blue {
        color: $dark-blue;
      }
    }
    &:not(.active):not(.disabled):hover {
      text-decoration: none;
      color: $dark-gray;
      .counter-icon {
        &.green {
          color: $green;
        }
        &.orange {
          color: #ffbe00;
        }
        &.blue {
          color: $blue;
        }
      }
    }
    &.active {
      text-decoration: none;
      background: $dark-green;
      color: white;
      position: relative;
      &:after {
        content: " ";
        position: absolute;
        bottom: -13px;
        z-index: 1;
        left: calc(50% - 20px);
        width: 40px;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 8px solid $dark-green;
      }
      &.orange {
        background: $orange;
        &:after {
          border-top-color: $orange;
        }
      }

      .counter-icon {
        &.green, &.blue, &.orange {
          color: white;
        }
        &.orange {
          .fa-inverse {
            color: $orange;
          }
        }
      }
    }
  }
}
.buy-slider {
  .content {
    position: relative;
    .caption {
      position: absolute;
      top: 0;
      left: 20px;
      display: inline-block;
      margin-top: 0;
      height: 362px;
      width: 520px;
      background-color: rgba(0, 169, 198, 0.6);
      transform: skewX(-10deg);
      text-align: center;
      color: #fff;
      font-family: 'Open Sans', sans-serif;
      > * {
        cursor: pointer;
        transform: skewX(10deg);
        text-align: center;
        width: 460px;
        height: 362px;
        margin-left: 30px;
        padding-top: 20px;
        .slide-icon {
          margin: auto;
          width: 80px;
        }
        .slide-title {
          padding-top: 22px;
          font-size: 26pt;
          font-weight: bold;
        }
        .slide-description-block {
          bottom: 20px;
          left: 0;
          right: 0;
          position: absolute;
          display: flex;
          .slide-description {
            margin: auto;
            font-size: 20pt;
            text-align: left;
            float: left;
          }
        }
      }

      &.slide-2 {
        width: 660px;
        > * {
          width: 640px;
          margin-left: 10px;
        }
      }
      &.slide-3 {
        width: 590px;
        > * {
          width: 560px;
          margin-left: 10px;
          .slide-description-block {
            bottom: 40px;
          }
        }
      }

      @include respond-to(xs) {
        transform: skewX(-6deg);
        width: 290px;
        left: 10px;
        display: flex;
        > * {
          padding-top: 0;
          left: 30px;
          transform: skewX(6deg);
          width: 260px;
          height: auto;
          margin-left: 20px;
          margin-bottom: auto;
          margin-top: auto;
          .slide-icon {
            width: 100px;
          }
          .slide-title {
            padding-bottom: 20px;
            font-size: 18pt;
          }
          .slide-description-block {
            position: inherit;
            padding-top: 15px;
            .slide-description {
              font-size: 14pt;
              margin: inherit;
              margin-left: -10px;
            }
          }
        }
        &.slide-2 {
          width: 290px;
          > * {
            width: 260px;
            margin-left: 15px;
            .slide-description-block {
              .slide-description {
                font-size: 12pt;
              }
            }
          }
        }
        &.slide-3 {
          width: 290px;
          > * {
            width: 260px;;
            margin-left: 15px;
          }
        }
      }
    }
  }
}
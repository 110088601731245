.scroll-arrows {
  font-size: 50px;
  color: rgba(255, 255, 255, 0.6);
  position: fixed;
  right: 0;
  display: none;
  bottom: 0;
  margin-bottom: .2em;
  margin-right: .2em;
  z-index: 999999;
  text-align: center;
  line-height: 0;
  & > * {
    cursor: pointer;
    margin: 2px;
  }
  i {
    display: block;
    background-color: rgba(100, 100, 100, 0.6);
    border-radius: 100%;
    &.fix-margin:before {
      margin: 0;
    }
  }
  .down > i {
    transform: rotate(180deg);
  }

  .down, .up {
    opacity: 0;
    transform: translateX(100%) translateY(0);
    transition: opacity 300ms, transform 300ms ease-out;
    &.in {
      opacity: 1;
      transform: translateX(0) translateY(0);
      transition: opacity 300ms, transform 300ms ease-in;
    }
    &.in-down {
      opacity: 1;
      transform: translateX(0) translateY(100%);
      transition: opacity 300ms, transform 300ms ease-in;
    }
  }

  @include respond-to(xs) {
    & {
      display: block;
    }
  }
}

.south-african-chapter {
  padding-bottom: 35px;
}

.row-chapter {
  padding-top: 30px;
  padding-bottom: 35px;
}

.team-member {
  padding-bottom: 60px;
}

.team-member__img {
  width: 170px;
  margin: auto;
}

.team-member__img-thumbnail {
  padding-top: 20px;
  padding-bottom: 20px;
}

.partnerships,
.partnerships-header {
  padding-bottom: 90px;
}

.partnerships-header {
  padding-top: 60px;
}

.partnerships-img-container {
  padding-top: 50px;
  padding-bottom: 30px;
  margin: auto;
}

.partnerships-img {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  background: white;
}

.partnerships-img img {
  max-width: 115px;
  padding: 5px;
}

.partnerships-img--lg img {
  max-width: 260px;
}

@media (max-width: 992px) {
  .p-top-x4-sm {
    padding-top: 20px;
  }

  .p-top-x3-sm {
    padding-top: 15px;
  }

  .p-bottom-x3-sm {
    padding-bottom: 15px;
  }
}
.block-item {
  vertical-align: top;
  height: 215px;
  margin-bottom: 20px;

  .row-with-photo { /*min-height: 130px;*/
  }

  %localA {
    color: #000;
    display: block;
    overflow: hidden;
  }

  a.bar-item {
    @extend %localA;
  }

  .bar-item {
    background-color: #fff;
    border-radius: 2px;
    margin-bottom: 20px;
    font-size: 11px;
    padding: 10px 10px 0 10px;
    height: 100%;
    position: relative;
    //overflow: hidden;
    overflow: visible;

    .close-item {
      position: absolute;
      right: 10px;
      top: 10px;
      height: 18px;
      width: 18px;
      font-size: 15px;
      z-index: 1;
      border: 1px solid $gray;
      text-align: center;
      border-radius: 4px;
      color: $gray;
      cursor: pointer;
      display: none;

      & + .tooltip {
        top: -40px !important;
      }
    }

    a {
      @extend %localA;
    }

    &:hover {
      .close-item {
        display: block;
      }
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 40px;
      background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%);
      background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%);
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
    }

    &.premium-item {
      &:after {
        height: 60px;
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 35%);
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 35%);
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 35%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
      }
      .content-item {
        max-height: 160px;
      }
    }

    %name {
      text-transform: uppercase;
      color: #000;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 60px;
    }
    .name {
      @extend %name;

      > * {
        @extend %name;
      }
    }

    .photo-wrap {
      width: 75px;
      margin: 5px;
      margin-right: 0;

      .photo, .logo {
        max-width: 100%;
        height: auto;
        margin: 0;
        max-height: 75px;
      }
    }

    &.more-block {
      background-color: #00a9c6;

      a {
        text-align: center;
        padding-top: 10px;
        color: #fff;
        font-size: 14px;

        &:hover {
          text-decoration: none;
        }
      }

      .text {
        margin-top: 10px;
      }

      .circle {
        @include make-circle(150px, 5px);

        padding-top: 37px;
        margin: 0 auto;
        opacity: .67;
        border-color: #fff;
      }

      &:hover {
        .circle {
          opacity: 1;
        }
      }

    }

  }

  .bar-item-partner {
    .block-title {
      min-width: 66px;
      display: inline-block;
    }

    .photoblock {
      padding: 0 20px 0 0;
      max-height: 60px;
      overflow: visible;
    }
  }

  .bar-item-project {
    .name {
      min-height: 45px;
    }
    .block-title {
      color: #9caab1;
    }
    .blocklogo {
      padding: 0 20px 0 0;
      overflow: hidden;
      text-align: right;

      img {
        width: 100%;
        max-width: 90px;
        max-height: 90px;
      }
    }
    .premium-icon {
      position: absolute;
      bottom: 5px;
    }
  }
}

.group-grid .bar-item:after, .bar-item.more-block:after {
  height: 0;
}

.group-grid {
  height: 165px;
  .bar-item {
    padding: 16px 16px 0;
    .block-title {
      min-width: 60px;
      display: inline-block;
    }
  }
}

.profile-card {
  text-align: center;
  position: relative;
  border: 2px solid #e7f2f6;
  height: 520px;
  padding: 15px;
  margin: 15px 5px;
  background-color: #fff;
  .profile-name {
    font-weight: bold;
  }
  .profile-photo {
    padding: 15px 0;
    img {
      border-radius: 100%;
      width: 128px;
      height: 128px;
    }
  }
  .profile-logo {
    padding: 15px 0;
    img {
      border-radius: 12px;
      width: 128px;
      height: 128px;
    }
  }
}

.grid-info-block {
  border-top: 3px solid #00a9c6;
  border-bottom: 3px solid #00a9c6;
  background-color: #FBFFFF;
  margin: 0 -15px;
  padding: 0 10px;
  .grid-title > h2 {
    text-transform: uppercase;
    margin: 0;
    text-align: center;
    padding-top: 20px;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 5px;
  }
  .grid-content {
  }
}

.small-logo-card {
  text-align: center;
  margin-bottom: 10px;
  &:hover {
    background: #f5f5f5;
  }
  a:hover {
    text-decoration: none;
  }
  .card-title {
    font-weight: bold;
    color: $gray;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .card-logo {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 0;
    max-width: 95%;
    height: auto;
    margin-bottom: 5px;
  }
}
.welcome-south-african {
  min-height: 700px;
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
  background: url("/../../images/slides/welcomeAfrican.png") no-repeat;
  background-size: auto 100%;
  @include respond-to(xs) {
    min-height: auto;
  }
  .title {
    color: $blue;
    background: rgba(255, 255, 255, .9);
    text-align: center;
    margin: 0;
    padding: 25px;
    h1 {
      margin: 0;
      font-size: 30px;
      font-weight: bold;
    }
    h2 {
      font-size: 16px;
      margin: 10px 0 0;
    }
  }
  .info-text {
    font-size: 16px;
    box-shadow: 0 1px 0 0 #ddd, 0 0 0 1px #eee, 0 1px 10px 2px rgba(0, 0, 0, .4);
    background: rgba(255, 255, 255, .9);
    margin: 120px calc(50% - 300px) 200px;
    width: 600px;
    display: inline-block;
    text-align: center;
    padding: 15px 90px;
    p {
      margin-bottom: 25px;
      &.green {
        margin-top: 35px;
        color: #67b168;
        margin-bottom: 0;
      }
    }
    @include respond-to(xs) {
      width: auto;
      padding: 15px;
      margin: 15px;
    }
  }
  .event-form {
    box-shadow: 0 1px 0 0 #ddd, 0 0 0 1px #eee, 0 1px 10px 2px rgba(0, 0, 0, .4);
    background: rgba(255, 255, 255, .9);
    margin: 80px calc(50% - 300px) 160px;
    width: 600px;
    display: inline-block;
    text-align: center;
    padding: 15px 90px;
    form {
      label.control-label {
        font-weight: normal;
      }
      .check-buttons {
        display: inline-block;
        text-align: left;
        .check-checkbox-button {
          margin: 6px 0;
        }
      }
    }
    @include respond-to(xs) {
      width: auto;
      padding: 15px;
      margin: 15px;
    }
  }
  .bottom-blue {
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(0, 169, 198, .75);
    padding: 15px 40px;
    right: 0;
    .buttons {
      float: right;
      margin-right: 5px;
    }
    @include respond-to(xs) {
      text-align: center;
      position: static;
      padding: 15px;
      .buttons {
        float: none;
        .btn {
          margin-right: 0;
          margin-bottom: 5px;
        }
      }
    }
  }
}

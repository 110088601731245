.menu {
  background-color: #00a9c6;
  min-height: 40px;
  margin-bottom: 0;
  border-radius: 0;
  padding-top: 4px;

  .navbar-nav {
    > li {
      > a {
        padding-top: 10px;
      }
    }
  }

  .fa-stack {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  .circle-menu {
    i {
      &:first-child {
        font-size: 30px;
      }
    }
  }

  .counter {
    font-size: 12px;
    color: #fff;
    font-family: "Open Sans", Arial, serif;

    position: absolute;
    background-color: $dark-green;
    right: -5px;
    font-weight: bold;
    height: 20px;
    display: block;
    padding: 5px 4px 5px 4px;
    line-height: 1;
    font-style: normal;
    min-width: 20px;
    text-align: center;
    border-radius: 50%;
    // top: 5px;
  }

  .counter2 {
    @extend .counter;
  }

}

.navbar-default {
  .menu {
    .navbar-nav {
      > li {
        > a {
          color: #fff;
          font-weight: bold;
        }
      }
    }
  }
}
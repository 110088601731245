.page-not-found {
  text-align: center;
  margin: 7% auto 0 auto;
  padding: 0;
  font-size: 18px;
  width: 480px;
  h1 {
    color: #00a9c6;
    font-size: 42px;
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;
  }
  h2 {
    color: #00a9c6;
    font-size: 88px;
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;
  }
  h3 {
    font-size: 24px;
    font-weight: bold;
    margin: 15px auto;
  }
  .logo-content {
    text-align: center;
  }

  .title-content {
    margin: 50px auto auto auto;
    border: 6px solid #00a9c6;
    .first, .second {
      padding: 0;
    }
    .second {
      border-left: 6px solid #00a9c6;
    }
  }
  .desc-content {
    text-align: left;
    margin: 40px 30px;
    .link-label {
      margin: 0;
      color: #9caab1;
      font-weight: bold;
      a {
        color: #00a9c6;
      }
    }
  }
  .broken-gears {
    width: 75%;
    height: 75%;
    margin: 30px auto;
  }
  @include respond-to(sm) {
    .logo-content {
      .logo {
        width: 380px;
        height: 92px;
        float: inherit;
        margin: auto;
      }
    }
  }
  @include respond-to(xs) {
    width: 100%;
    font-size: 14px;
    h1 {
      font-size: 27px;
    }
    h2 {
      font-size: 54px;
    }

    h3 {
      font-size: 18px;
    }
    .logo-content {
      .logo {
        width: 300px;
        height: 72px;
      }
    }
    .title-content {
      border: 4px solid #00a9c6;
      margin: 30px 10px auto 10px;

      .second {
        border-left: 4px solid #00a9c6;
      }
    }
    .desc-content {
      margin: 30px 20px;
    }
    .broken-gears {
      margin: 20px auto;
    }
  }
}
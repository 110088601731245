
input[type=number].input-number {
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  text-align: right;
  border: none;
  border-radius: 3px;
  color: #66afe9;
  width: 48px;
  &:hover {
    outline: 0;
  }
  &:focus {
    outline: 0;
  }
  &.ng-invalid.ng-dirty {
    color: #a94442;
    & + .angles > .fa-angle-up, & + .angles > .fa-angle-down {
      border-color: #a94442;
      color: #a94442;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 2px #ce8483;
    }
  }

}

input[type=number].input-number + .angles {
  max-width: 11px;
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;
  line-height: 0;
  vertical-align: middle;
  > .fa-angle-up, .fa-angle-down {
    display: inline-block;
    color: #66afe9;
    border: 1px solid #66afe9;
    border-radius: 50%;
    cursor: pointer;
    font-size: 10px;
    font-weight: bolder;
    background: white;
    line-height: 0;
    padding: 5px 2px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 2px rgba(102, 175, 233, .6);
    &:hover {
      background: #eeeeee;
    }
  }
  > .fa-angle-up {
    border-bottom: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    z-index: 1;
  }
  > .fa-angle-down {
    border-top: none;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    z-index: 0;
  }
}

.recom-slider {
  .bx-wrapper {
    box-shadow: 0 0 0;
    .bx-controls-direction a {
      z-index: 1000;
      margin-top: -30px;
    }
    .bx-next {
      right: -46px;
      background-image: none;
      overflow: hidden;
      height: 75px;
      width: 42px;
      text-align: left;
      text-decoration: none;
      &:before {
        content: '\f054';
        font-family: FontAwesome, serif;
        font-style: normal;
        text-indent: 0;
        display: block;
      }

    }
    .bx-prev {
      @extend .bx-next;
      left: -46px;
      text-align: right;

      &:before {
        content: '\f053';
      }
    }
  }
}

.top-projects {

  .image-wrapper {
    min-height: 105px;
  }

  .orange-circle {
    @extend .circle;

    text-align: center;
    display: table;
    width: 134px;
    height: 134px;
    background-color: $orange;
    color: #FFFFFF;
    font-family: Arial, sans-serif;
    font-size: 18px;
    font-weight: bold;
    margin: 30px 0 10px 30px;
    text-decoration: none;

    &a {
      text-decoration: none;
      color: #FFFFFF;
    }

    p {
      display: table-cell;
      vertical-align: middle;
      line-height: 1;
    }

  }

  background-color: #fff;
  margin-bottom: 30px;
  padding: 15px;

  .row {
    margin-right: 0;
    margin-left: 0;
  }

  .logos {
    .logo-wrap {
      display: inline-block;
      position: relative;
    }
    .banner-wrap {
      @extend .logo-wrap;
      margin: 0 auto;
    }
  }

  .projects-list {
    background-color: #fff;
    //background-color: #F6F6F6;
    padding: 10px;
    box-sizing: border-box;
    display: block;
    color: #000;

    &:hover,
    &:focus {
      text-decoration: none;
      color: #000;
    }

    p {
      margin: 0;
    }

    .logo {
      max-width: 180px;
      margin: 0;
    }

    .partners-photo {
      @extend %imageSize;
      position: absolute;
      top: 15px;
      right: 10px;
      padding: 5px;
    }

    .partner-premium-logo {
      .partners-photo {
        top: 0;
        right: 0;
        padding: 0;
      }

      span {
        color: #000000;
        font-weight: bold;
      }

      .row {
        text-align: center;

        img {
          @extend %imageSize;
          position: relative;
          margin-top: 25px;
          display: inline;
        }
      }
    }

    .partner-talent {
      //padding: 5px;
      box-sizing: border-box;
      background-color: #FFFFFF;

      h4 {
        font-weight: bold;
        text-transform: uppercase;
        margin-top: 0;
      }

      span {
        &:first-child {
          color: #9caab1;
          font-weight: bold;
          line-height: 1.3;
        }

        color: #333;
        font-weight: normal;
      }

    }

  }

  .no-top {
    padding-top: 40px;
    padding-bottom: 20px;
    h3 {
      @extend .bolder;
      font-size: 18px;
      text-align: center;
    }
    p {
      font-size: 18px;
      margin-top: 30px;
      text-align: center;
    }
    a {
      //@extend .circle;

      $circleSize: 100px;
      //$arrowHeight: $circleSize * 0.75;

      @include circle-arrow($circleSize, 5px);

      display: block;
      margin: 0 auto;
      border: 5px $blue solid;
      color: $blue;
      //font-size: $arrowHeight;
      text-align: center;
      &:hover,
      &:focus {
        text-decoration: none;
        border-color: $dark-blue;
        color: $dark-blue;
      }
    }
  }
  .closed-needs {
    padding-top: 40px;
    padding-bottom: 20px;
  }
}

.closed-needs {
  h3 {
    @extend .bolder;
    font-size: 18px;
    text-align: center;
  }
}

.closed-needs {
  p {
    font-size: 18px;
    margin-top: 30px;
    text-align: center;
  }
  .check-radio-buttons {
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: left;
    .check-radio-button {
      margin: auto;
      width: 176px;
      padding-left: 16px;
    }
  }
  a {
    $circleSize: 50px;
    @include circle-arrow($circleSize, 3px);
    display: block;
    margin: 0 auto;
    border-color: $blue;
    color: $blue;
    //font-size: $arrowHeight;
    text-align: center;
    &:hover,
    &:focus {
      text-decoration: none;
      border-color: $dark-blue;
      color: $dark-blue;
    }
  }
}

.circle-arrow-blue {
  @include circle-arrow(80px, 5px);
  display: block;
  margin: 0 auto;
  border-color: $blue;
  color: $blue;
  //font-size: $arrowHeight;
  text-align: center;
  &:hover,
  &:focus {
    text-decoration: none;
    border-color: $dark-blue;
    color: $dark-blue;
  }
}
.project-zoom {

  .embed-responsive {
    padding-left: 15px;
    padding-right: 15px;
  }

}

.load-banner.load-banner-back {
  @include respond-to(xs) {
    width: auto;
  }
}

.need-detail {
  dd {
    padding-bottom: 10px;
  }
}

.list-projects {
  padding-bottom: 6px;
}

.project-logo-part {
  text-align: center;
  display: block;
  color: black;
  &:hover, &:active {
    color: #222222;
    text-decoration: none;
  }
  &:focus {
    color: black;
  }

  .main-subtitle {
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: bold;
  }
  .logo {
    margin-top: 6px;
    margin-bottom: 10px;
    width: 140px;
    float: none;
    height: 140px;
  }
}

.project-banner {
  width: 100%;
}

.icon-heart-plus:before {
  //font-size: 42px;
}

.project-needs-header {
  position: relative;
  .needs-button {
    text-align: center;
    position: absolute;
    bottom: 20px;
    right: 50px;
    .need-list-toggle {
      padding: 8px 18px;
      border-radius: 8px;
      cursor: pointer;
      border: 2px solid $gray;
      .icon-bar {
        border-bottom: 4px solid $gray;
        display: block;
        margin: 10px 0;
      }
      &:hover {
        background: #f2f2f2;
      }
      &:active {
        padding: 9px 17px 7px 19px;
        background: #d4d4d4;
      }
    }
    @include respond-to(xs) {
      display: none;
    }
  }
}

.green-counter-icon {
  color: $dark-green;
  font-size: 2em;
  @include respond-to(xs) {
    font-size: 14px;
  }
}

.blue-counter-icon {
  color: $blue;
  font-size: 2em;
  padding-top: 10px;
  height: 45px;
  display: block;
  @include respond-to(xs) {
    font-size: 24px;
  }
}

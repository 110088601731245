header {
  .logged-header.premium-header {
    .logo-part {
      .navbar.navbar-default {
        background: #00a9c6;
      }
      .container {
        padding: 0;
      }
      .navbar-collapse {
        margin-left: 0;
        margin-right: 0;

        .auth-user {
          @include respond-to(xs) {
            background-color: white;
            border-bottom: 3px solid #00a9c6;
            border-top: 1px solid rgb(231, 231, 231);
          }
          .white-dropdown {
            .head-login {
              background: white;
              color: #00a9c6;
              border-color: #00a9c6;
              img {
                border-color: #00a9c6;
              }
              &:hover {
                background: #f2f2f2;
              }
              &:active {
                background-color: #e6e6e6;
                color: #6f6f6f;
                img {
                  border-color: #6f6f6f;
                }
                box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
              }
            }
          }
        }
      }
    }
    .menu {
      .navbar-nav > li > a {
        color: #00a9c6;
        &:hover {
          color: #00bfdd;
        }
        &:active {
          color: #00859e;
        }
        &.orange {
          color: #fb9b00;
          &:hover {
            color: #ffbf54;
          }
          &:active {
            color: #85491f;
          }
        }
      }
      .navbar-nav > li {
        &.current {
          position: relative;
          &:before {
            border-top: 8px solid #00a9c6;
          }
          @include respond-to(xs) {
            background: #00a9c6;
            a {
              color: white;
            }
          }
        }
      }
      .navbar-nav.navbar-right > li {
        &.current {
          &:before {
            border-top: 8px solid #00a9c6;
          }
        }
      }

      .container {
        .navbar-header .navbar-toggle {
          background-color: transparent;
          .icon-bar {
            background-color: #00859e;
          }
          &.collapsed {
            .icon-bar {
              background-color: #00a9c6;
            }
            &:hover {
              .icon-bar {
                background-color: #00bfdd;
              }
            }
            &:active {
              .icon-bar {
                background-color: #00859e;
              }
            }
          }
        }
      }
      .circle-menu {
        background: transparent;
        a i.blue:hover {
          color: #00bfdd;
        }
        a i.blue:active {
          color: #00859e;
        }
      }

      .top-search {
        width: calc(100% - 240px);
        .input-group-btn {
          .btn {
            color: #00a9c6;
            &:hover {
              color: #00bfdd;
            }
            &:focus {
              box-shadow: none;
              outline: none;
              color: #00a9c6;
            }
            &:active {
              box-shadow: none;
              outline: none;
              color: #00859e;
            }
          }
          &.dropdown.open {
            .btn:focus, .btn:hover, .btn {
              box-shadow: none;
              outline: none;
              color: #00859e;
            }
          }
        }
        .navbar-form .input-group input.form-control {
          border-left: 3px solid #00a9c6;
          border-right: 3px solid #00a9c6;
        }
      }
    }
  }
}
@import "variables";

.blue {
  color: $blue
}

.light-gray {
  color: $light-gray
}

.gray {
  color: $gray
}

.green {
  color: $green
}

.dark-green {
  color: $dark-green
}

.orange {
  color: $orange
}

.black {
  color: $black
}

.error {
  color: $red-error
}

// changed from #ff9933
.premium-color {
  color: $orange
}

.premium-header .top-search-xs {
  background-color: #00A9C6;
}

.premium-header .container-fluid.menu {
  background-color: #FFFFFF;
  height: 100%;

  .title-menu {
    @include respond-to(xs) {
      color: #00A9C6;
    }
  }
}

.circle {
  border-radius: 50%;
}

.underline {
  text-decoration: underline;
}

.break-word {
  word-wrap: break-word;
}

.pointer {
  cursor: pointer;
}

.bolder {
  font-weight: bold !important;
}

#loading-bar {
  .bar {
    background-color: $dark-green;
    height: 5px;
  }

  .peg {
    height: 5px;
    -moz-box-shadow: $dark-green 1px 0 6px 1px;
    -ms-box-shadow: $dark-green 1px 0 6px 1px;
    -webkit-box-shadow: $dark-green 1px 0 6px 1px;
    box-shadow: $dark-green 1px 0 6px 1px;
  }
}

#loading-bar-spinner {
  $size: 30px;

  top: calc(50% - #{$size} / 2);
  left: 50%;
  margin-left: $size / -2;

  .spinner-icon {
    width: $size;
    height: $size;
    border-radius: $size / 2;
    border: solid 6px transparent;
    border-top-color: $dark-green;
    border-left-color: $dark-green;

    //#29d;
  }
}

.img-thumbnail {
  padding: 0;
}

%star {
  &:after {
    content: '*';
  }
}

.blue-star:after {
  @extend %star;
  color: $blue;
}

.black-star:after {
  @extend %star;
  color: $black;
}

.orange-star:after {
  @extend %star;
  color: $orange;
}

.list-table {
  display: table;
  width: 100%;
  table-layout: fixed;

  .list-row {
    display: table-row;

    .list-col {
      display: table-cell;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 10px;
      float: none;
      border-bottom: 1px solid #bdbec0;
      vertical-align: top;
      overflow: hidden;

      &:last-child {
        padding-right: 0;
      }

      &.col-bottom {
        vertical-align: bottom;
      }

      &.col-middle {
        vertical-align: middle;
      }

      &.fixed-size-col {
        width: 75px;
        padding-left: 0;
        padding-right: 0;
      }

      &.img-col {
        position: relative;
        width: 75px;
        height: 75px;
        padding-left: 0;
        padding-right: 0;

        .photo-max-width {
          width: 100%;
          height: auto;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-bottom: none;
        }

        .p-indicator {
          border-bottom: 5px solid $dark-green;
        }

        .e-indicator {
          border-bottom: 5px solid $blue;
        }
      }
    }
  }

  &.bottom-line-none > .list-row > .list-col {
    border-bottom: none;
  }
}

.img-thumbnail {
  &.p-indicator {
    border-bottom: 5px solid $dark-green;
  }

  &.e-indicator {
    border-bottom: 5px solid $blue;
  }
}

.row-table {
  display: table;
  width: 100%;
  height: 100%;
  position: relative;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;

  > * {
    float: none;
    display: table-cell;
    vertical-align: middle;

    &.td-top {
      vertical-align: top;
    }

    &.td-bottom {
      vertical-align: bottom;
    }
  }

}

.row-table-signup {
  display: table;
  width: 32%;
  height: 100%;
  position: relative;
  margin: 0 auto;

  > * {
    float: none;
    display: table-cell;
    vertical-align: middle;

    &.td-top {
      vertical-align: top;
    }
    &.td-bottom {
      vertical-align: bottom;
    }
  }

}

.btn-default-focus {
  outline: 0;
}

.two-content {
  > * {
    width: 49%;
  }

  > .seporator {
    width: 2%;
    background-color: transparent;
  }

  .info {
    padding-top: 14px;
  }
}

.two-content .signup {
  padding-left: 23px;
  padding-right: 23px;
}

.two-content .signup h2 {
  font-size: 24px;
}

.two-content .signup .annotation-title {
  margin-bottom: 20px;
}

.two-content .signup .confirm {
  color: #8a8a8a;
  margin-top: 17px;
}

.help-block {
  padding-right: 10px;
  padding-left: 10px;
}

.two-content .signup .help-block {
  margin: -5px 0 0;
  height: 20px;
  position: absolute;
}

.col-static {
  position: static;
}

.m-top-x1 {
  margin-top: 5px !important;
}

.m-top {
  margin-top: 10px !important;
}

.m-top-x2 {
  margin-top: 20px !important;
}

.m-top-x3 {
  margin-top: 30px !important;
}

.m-bottom {
  margin-bottom: 10px !important;
}

.m-bottom-x2 {
  margin-bottom: 20px !important;
}

.m-right {
  margin-right: 10px !important;
}

.m-left {
  margin-left: 10px !important;
}

.m-right-x2 {
  margin-right: 20px !important;
}

.m-left-x2 {
  margin-left: 20px !important;
}

.m-left-x3 {
  margin-left: 30px !important;
}

.p-top {
  padding-top: 5px !important;
}

.p-top-x2 {
  padding-top: 10px !important;
}

.p-top-x3 {
  padding-top: 15px !important;
}

.p-right {
  padding-right: 5px !important;
}

.p-right-x2 {
  padding-right: 15px !important;
}

.p-right-x3 {
  padding-right: 25px !important;
}

.p-left {
  padding-left: 5px !important;
}

.p-bottom {
  padding-bottom: 5px !important;
}

.p-bottom-x2 {
  padding-bottom: 10px !important;
}

.p-bottom-x3 {
  padding-bottom: 15px !important;
}

.p-bottom-x4 {
  padding-bottom: 28px !important;;
}

.p-y {
  padding-bottom: 5px !important;
  padding-top: 5px !important;
}

.p-y-x2 {
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}

.p-y-x3 {
  padding-bottom: 15px !important;
  padding-top: 15px !important;
}

.announces .feeds section article .person.nolink {
  color: #00a9c6;
}

@include link-light();

.single {
  .photo {
    padding: 0;
    max-width: 140px;
    margin: 15px 0 0;
    background-color: white;
    width: 100%;
  }

  .logo {
    padding: 0;
    max-width: 140px;
    margin: 0;
    float: right;
    background-color: white;
    width: 100%;
  }
}

.photo-max-width {
  width: 100%;
  @include respond-to(xs) {
    width: 64px;
    height: 64px;
  }
}

.photo-link {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  @include respond-to(xs) {
    width: 64px;
    height: 64px;
    .verification-id-verified-user-list {
      font-size: 14pt;
      margin-top: 5px;
      margin-right: 5px;
    }
  }
}

.widget_user {
  font-size: 12px;

  .photo {
    margin: 15px;
    width: 95px;
    height: 95px;
    @include respond-to(xs) {
      width: 78px;
      height: 78px;
    }
  }
}

.sponsor-logo-block {
  height: 380px;
  width: 100%;
  display: flex;
  background-color: white;

  img {
    margin: auto;
  }

  @include respond-to(sm) {
    height: 300px;
    img {
      width: 200px;
      height: 200px;
    }
  }
  @include respond-to(xs) {
    height: 320px;
  }
}

.account-verified {
  position: absolute;
  bottom: 0;
  margin-right: 15px;
  z-index: 1;
  right: 0;
  font-size: 2.5em;
  color: #789200;
}

.verification-id-verified-user-list {
  color: #789200;
  position: absolute;
  right: 0;
  font-size: 2em;
  margin-top: 0.2em;
  margin-right: 0.2em;
  text-align: center;
  border-radius: 100%;
  text-shadow: none;
}

.project-zoom {
  .verification-id-verified-user-list {
    margin-right: 0.8em;
  }
}

.carousel-control {
  .bx-prev, .bx-next {
    transition: 0.5s color, 0.3s text-shadow;

    &:active {
      color: #1b7f9c;
      text-shadow: 0 0 1px rgba(0, 0, 0, .6);
    }

    &.disabled {
      color: #e4e4e4;

      &:hover, &:active {
        cursor: not-allowed;
        color: #e4e4e4;
        text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
      }
    }
  }
}

.grid-bottom-info {
  position: absolute;
  bottom: 0;
  left: 20px;
  width: 100%;
  padding: 6px;
  z-index: 1;
  height: 47px;

  & > * {
    min-height: 32px;
    position: relative;
    float: left;
    line-height: 1;
  }

  .premium-block-user {
    position: relative;
    left: 0;
    bottom: 0;
    width: 18%;
  }

  .shared-rev-i {
    width: 50%;
    color: #d4d4d4;
    font-size: 11px;
    padding: 4px 0;
    // margin-top: -4px;
    .fa-retweet {
      // vertical-align: middle;
    }

    .review-count {
      padding-left: 2px;
      font-size: 14px;
    }
  }

  .account-verified {
    position: relative;
    width: 14%;
    margin: 0;

    .fa-check-circle {
      float: right;
    }
  }
}

.blue-label {
  font-size: 13px;
  font-weight: bold;
  color: #00a9c6;
}

.gray-label {
  font-size: 13px;
  font-weight: bold;
  color: darkgray;
}

.sort-by {
  .btn-default {
    border-left-width: 0;
    box-shadow: 0 0 0;
    border-color: #ccc;

    &:hover {
      border-color: #ccc;
      background-color: #fff;
      box-shadow: 0 0 0;
    }

    &:focus {
      border-color: #ccc;
      background-color: #fff;
      box-shadow: 0 0 0;
    }

    &:active {
      border-color: #ccc;
      background-color: #fff;
      box-shadow: 0 0 0;
    }
  }

  &.input-group {

    .input-group-addon {
      background-color: #fff;
      border-right-width: 0;
    }

    select {
      border-left-width: 0;
      box-shadow: 0 0 0;

      &:focus {
        border: 1px solid #ccc;
        border-left-width: 0;
      }
    }

    .form-control {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .ui-select-match-text {
      font-weight: bold;
    }

    .ui-select-bootstrap {

      > input.ui-select-search.form-control {
        border-radius: 0 4px 4px 0;
        border: 1px solid #ccc;
        border-left-width: 0;
        box-shadow: 0 0 0;

        &:focus {
          border: 1px solid #ccc;
          border-left-width: 0;
          box-shadow: 0 0 0;
        }
      }

      .ui-select-choices-row {
        &.active > a {
          background-color: transparent;
          color: #000;
          font-weight: bold;
        }
      }
    }
  }
}

.search-result-total {
  font-size: 16px;
  padding: 7px 15px 0;
}

.left-col {
  padding-right: 25px;
  @include respond-to(xs) {
    padding-right: 15px;
  }
}

.nav-profile-fill {
  background-color: #fff;

  & > li {
    & > a {
      color: #00a9c6;
      border-radius: 0;
    }

    & > a:hover {
      background-color: #FBFBFB;
      color: #1b7f9c;
    }

    &.active {
      & > a, & > a:hover, & > a:active, & > a:focus {
        background-color: #00a9c6;
        color: #fff;
      }
    }
  }
}

.reject-notify {
  margin-bottom: 10px;
  margin-top: 10px;
}

.shadow-block {
  box-shadow: 0 1px 0 0 #ddd, 0 0 0 1px #eee;
}

.blue-rect {
  background: $blue;
  padding: 4px 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
  display: inline-block;
}

.check-radio-button {
  font-weight: normal;

  input[type='radio'] {
    display: none;
  }

  label {
    margin: 0;
    font-weight: normal;
  }

  input[type='radio'] + label:before {
    cursor: pointer;
    content: '\f058';
    vertical-align: middle;
    font-family: FontAwesome;
    font-size: 26px;
    line-height: 1.1;
    color: #c3c3c3;
    margin-right: 6px;
  }

  input[type='radio']:checked + label:before {
    color: #00a9c6;
  }

}

.visit-us-label {
  display: inline-block;
}

editor {
  &.ng-invalid.ng-dirty {
    .mce-tinymce.mce-container.mce-panel {
      border: 1px solid #a94442;
    }
  }

  &.ng-invalid.ng-dirty.focus {
    .mce-tinymce.mce-container.mce-panel {
      outline-color: #ce8483;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
    }
  }
}

.check-checkbox-button {
  font-weight: normal;
  min-height: 28px;

  input[type='checkbox'] {
    display: none;
  }

  label {
    margin: 0;
    font-weight: normal;
    position: relative;
    padding-left: 34px;
    vertical-align: middle;
  }

  input[type='checkbox'] + label:before {
    cursor: pointer;
    content: '\f058';
    vertical-align: middle;
    font-family: FontAwesome;
    font-size: 26px;
    line-height: 1.1;
    color: #c3c3c3;
    margin-right: 6px;
    position: absolute;
    left: 5px;
    margin-top: -3px;
  }

  input[type='checkbox']:checked + label:before {
    color: #00a9c6;
  }

}

.re-captcha-block {
  margin-top: 10px;
  margin-bottom: 10px;
}

@include respond-to(xs) {
  .form-group .form-margin-bottom-xs {
    margin-bottom: 10px;
  }
}

.top-small-info-gray {
  color: darkgray;
  font-size: 12px;
  margin: 10px 0;
}

.ta-toolbar {
  background-color: #F0F0F0;
  padding: 5px 5px 0 0;
  margin-left: 0; /* Override bootstrap */
  border: 1px solid #EEE;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.ta-toolbar .btn-group {
  margin-bottom: 5px;
}

.ta-editor.white-box {
  background-color: #FFF;
  border: 1px solid #EEE;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ta-bind.ta-editor, .white-box {
  padding: 10px;
}

textarea.ta-bind {
  width: 100%;
}

.col-clean-padding {
  padding-left: 0;
  padding-right: 0;
}

.addthis_default_style a {
  float: none !important;
}

.send-form-title {
  text-align: center;
  font-size: 21px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 20px;
}

.additional-recom-filter-select.ng-empty {
  background-color: #f2f2f2;
}

input.form-control.gray-invite-code {
  color: $dark-gray;
  background-color: #f2f2f2;
}

.social-counter {
  display: flex;
  align-items: center;

  .icon {
    width: 40px;
    display: inline-block;
  }

  .name {
    width: calc(75% - 40px);
    vertical-align: middle;
    padding-left: 15px;
  }

  .count {
    width: 25%;
    text-align: right;
    font-weight: bolder;
    color: $gray;
  }
}

.left-container {
  padding-right: 25px;
  @include respond-to(sm) {
    padding-right: 15px;
  }
}

.main-container {
  padding-top: 15px;
  padding-bottom: 15px;
}

.m-auto {
  margin: auto !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-1 {
  padding-right: 5px !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mr-1 {
  margin-right: 5px !important;
}

@media (min-width: $x-small) {
  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0 {
    padding-right: 0 !important;
  }
}

@media (min-width: $small) {
  .mt-md-0 {
    margin-top: 0 !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }
}

@media (min-width: $x-large) {
  .ml-lg-auto {
    margin-left: auto !important;
  }

  .pl-lg-0 {
    padding-left: 0 !important;
  }

  .pr-lg-0 {
    padding-right: 0 !important;
  }

  .text-lg-left {
    text-align: left !important;
  }
}

.text-white {
  color: white !important;
}

.text-small {
  font-size: 12px !important;
}

.prev-next-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  a {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    box-shadow: 0 1px 0 0 #ddd, 0 0 0 1px #eee;
    padding-top: 5px;
    width: 25px;
    height: 30px;
  }
  span {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    box-shadow: 0 1px 0 0 #ddd, 0 0 0 1px #eee;
    padding-top: 5px;
    width: 25px;
    height: 30px;
    color: darkgray;
    cursor: not-allowed;
  }
}

@font-face {
  font-family: Helvetica;

  src: local('Helvetica'), url("/assets/fonts/Helvetica/Helvetica.woff2") format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Helvetica;
  font-weight: bold;
  font-style: normal;
  src: local('Helvetica-Bold'), local('Helvetica Bold'), url("/assets/fonts/Helvetica/Helvetica-Bold.woff2") format('woff2');
}

.profile-fill-title {
  background-color: #00a9c6;
  color: #fff;
  h1 {
    float: left;
    text-transform: uppercase;
    margin-top: 15px;
  }
  .navbar-toggle {
    background: transparent;
    margin: 10px 0 0;
  }
  @include respond-to(xs) {
    h1 {
      font-size: 26px;
    }
  }
}

.make-sure-type-comment {
  margin-top: 15px;
}

.verification-btn-block {
  text-decoration: none;
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 15px 5px;
  background-color: #00a9c6;
  transition: 0.3s background-color, padding;
  .title {
    opacity: .80;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 16px;
    color: white;
    transition: 0.3s opacity;
  }
  .description {
    opacity: .80;
    margin-top: 5px;
    color: white;
    margin-bottom: 5px;
    transition: 0.3s opacity;
  }
  .circle-arrow {
    opacity: .67;
    transition: 0.5s opacity;
    @include circle-arrow(80px, 4px);
  }
  &:hover {
    .title, .circle-arrow, .description {
      opacity: 1;
    }
  }

  &:active {
    background-color: #008fa9;
    padding: 16px 4px 14px 6px;
    .title, .circle-arrow, .description {
      opacity: .9;
    }
  }
}

#gender label {
   border-bottom: 1px solid #ffffff;
   box-shadow: 0 0 5px #ffffff;
   border-radius: 4px;
}

.content.verified-block {
  padding-top: 20px;
  padding-bottom: 20px;
  .v-title {
    span {
      padding-right: 6px;
    }
  }
  .v-checked {
    span {
      padding-left: 12px;
    }
  }
  .v-checked, .v-title {
    i {
      font-size: 20px;
      color: #789200;
    }
  }
}

.gray-title {
  padding-bottom: 10px;
  font-weight: bold;
  color: darkgray;
}

.profile-review-block {
  .review-list-link {
    @extend %review-rating;
    display: block;
    background-color: white;
    padding: 15px;
    color: #333;
    text-decoration: none;
    &:hover {
      background-color: #fDfDfD;
    }
    &:active {
      background-color: #fBfBfB;
      padding: 16px 14px 14px 16px;
    }
  }
  .fa.fa-retweet {
    color: darkgray;
    font-size: 24px;
    padding-left: 0;
    padding-right: 8px;
  }
  .review-list-link-pub {
    @extend %review-rating;
    display: inline-block;
    width: 100%;
    background-color: white;
    padding: 15px;
    color: #333;
    text-decoration: none;
    .fa.fa-retweet {
      font-size: 30px;
    }
    .rate-stars {
      padding-left: 8px;
    }
  }
  a.review-list-link-pub {
    &:hover {
      background-color: #fDfDfD;
    }
    &:active {
      background-color: #fBfBfB;
      padding: 16px 14px 14px 16px;
    }
  }
}

.email-confirm-info {
  background-color: #fcf8e3;
  border: 1px solid #faebcc;
  border-radius: 3px;
  color: #8a6d3b;
  padding: 10px 15px;
  margin-bottom: 15px;
}

.profile-step-arrows-clean {
  display: none;
  height: 66px;
  @include respond-to(xs) {
    height: 56px;
  }

  &.visible {
    display: block;
  }
}

.profile-step-arrows {
  height: 66px;
  .scroll-top {
    position: absolute;
    height: 66px;
    width: 28px;
    color: white;
    line-height: 64px;
    padding-left: 5px;
    background-color: #00a9c6;
    border-right: 3px solid white;
    z-index: 1;
    cursor: pointer;
    &.active {
      background: #789200;
    }
    @include respond-to(xs) {
      line-height: 50px;
      width: 20px;
      height: 56px;
      padding-left: 1px;
    }
  }
  .profile-step-arrow {
    background-color: #00a9c6;
    padding: 2px 0 2px 15px;
    height: 66px;
    display: inline-block;
    border-left: 4px solid white;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.8);
    &:not(.disabled):hover {
      color: #fff;
    }
    transition: 150ms color;
    text-align: center;
    position: relative;
    .step-icon {
      line-height: 1;
      font-size: 50px;
    }
    .step-title {
      line-height: 1;
      font-size: 12px;
    }

    &:before {
      width: 0;
      height: 0;
      border-top: 33px inset transparent;
      border-bottom: 33px inset transparent;
      border-left: 16px solid #fff;
      position: absolute;
      content: "";
      top: 0;
      left: 0;
    }

    .whitefix {
      width: 2px;
      height: inherit;
      background-color: inherit;
      position: absolute;
      content: "";
      z-index: 2;
      top: 0;
      right: -1px;
    }
    &:after {
      width: 0;
      height: 0;
      border-top: 33px inset transparent;
      border-bottom: 33px inset transparent;
      border-left: 16px solid #00a9c6;
      position: absolute;
      content: "";
      top: 0;
      right: -16px;
      z-index: 2;
    }

    &.disabled {
      background-color: darkgray;
      cursor: not-allowed;
      color: rgba(255, 255, 255, 0.8);
      text-decoration: none;
      &:after {
        border-left-color: darkgray;
      }
    }
    &.active {
      background-color: #789200;
      &:after {
        border-left-color: #789200;
      }
    }
    @include respond-to(xs) {
      padding: 2px 0 2px 0;
      height: 56px;
      .step-icon {
        font-size: 50px;
        padding-left: 10px;
      }
      .step-title {
        display: none;
      }
      &:before, &:after {
        border-left-width: 10px;
        border-top-width: 28px;
        border-bottom-width: 28px;
      }
      &:after {
        right: -10px;
      }
    }
    &:nth-child(2) {
      border-left: none;
      &:before {
        border: none;
      }
    }
    &:last-child {
      .whitefix {
        display: none;
      }
      &:after {
        border: none;
      }
    }
  }
  &.fixed-top {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1034;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);

  }
  @include respond-to(xs) {
    height: 56px;
  }
}

.fill-profile-body {
  position: relative;
  /*height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  @include respond-to(xs){
    height: auto;
  }*/
  a:focus {
    text-decoration: underline;
  }
}

.user-fill-percent {
  .progress {
    margin-bottom: 0;
    height: 32px;
    white-space: nowrap;
    overflow-x: hidden;
    background-color: darkgray;
    position: relative;
    margin-left: -10px;
    margin-right: -5px;
    box-shadow: none;
    border-radius: 0;
    .percent-steps {
      width: auto;
      position: absolute;
      height: inherit;
      top: 0;
      left: 0;
      right: 0;
      .percent-step {
        text-align: center;
        display: inline-block;
        color: rgba(255, 255, 255, 0.8);
        height: inherit;
        position: relative;
        border-right: 3px solid #fff;
        &:last-child {
          border-right: none;
        }
        .percent-step-progress {
          position: absolute;
          height: inherit;
          top: 0;
          left: 0;
          background: #00a9c6;
          content: " ";
          box-shadow: none;
          -o-transition: width .6s ease;
          transition: width .6s ease;
        }

        .step-icon {
          padding: 4px;
          position: absolute;
          width: 100%;
          font-size: 18px;
        }
      }
      a.percent-step {
        &:not(.disabled):hover {
          border-color: #fff;
          color: #fff;
          transition: 150ms color, 150ms border-color;
        }
        &.disabled {
          cursor: not-allowed;
        }
        &:not(.disabled):focus, &.active:focus {
          color: rgba(255, 255, 255, 0.8);
        }
        &.active, &.active:active, &:not(.disabled):active {
          color: rgba(255, 255, 255, 0.4);
        }
      }
    }
  }
}

.fixlowsize {
  height: 105px;
  @include respond-to(xs) {
    display: none;
  }
}

.sponsor-how-to-redeem {
  .check-checkbox-button {
    min-height: 40px;
    input[type='checkbox'] + label {
      padding-left: 0;
      &:before {
        font-size: 40px;
        vertical-align: middle;
        position: initial;
      }
    }
  }
}

.intl-tel-input .country-list {
  z-index: 3;
}

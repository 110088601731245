.refer-slide {
  background: url('/../../images/refer_slide.jpg') center center no-repeat;
  background-size: cover;
  overflow: hidden;
  .blue-background {
    margin-left: 10%;
    width: 600px;
    padding: 75px;
    color: white;
    position: relative;
    z-index: 0;
    text-align: center;
    vertical-align: text-top;
    &.left {
      margin-left: -14%;
      padding-left: calc(14% + 30px);
    }
    &:before {
      content: ' ';
      background: rgba(0, 169, 198, 0.85);
      top: 0;
      bottom: 0;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: -1;
      transform: skewX(-14deg);
    }
    .refer-text {
      display: inline-block;
      vertical-align: middle;
      font-size: 36px;
      line-height: 1;
      .small-title {
        font-weight: bolder;
        font-size: 24px;
        margin: 0;
      }
      .small-text {
        font-size: 24px;
      }
      .big-text {
        font-size: 80px;
        margin: 0;
        display: inherit;
      }
    }

    @include respond-to(xs) {
      &:before {
        display: none;
      }
      padding: 75px 40px;
      &.left {
        margin-left: 0;
        padding-left: 40px;
      }
      background: rgba(0, 169, 198, 0.85);
      width: 100%;
      margin-left: 0;
      .refer-text {
        font-size: 24px;
        .big-text {
          font-size: 80px;
        }
      }
    }
  }
}

.blue-rect-number {
  display: inline-block;
  line-height: 0.5;
  padding: 10px;
  margin: 25px;
  border-radius: 5px;
  background: #00a9c6;
  color: white;
}

p.p-padding {
  padding: 0 40px;
}

.my-token-account {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: -15px;
  margin-right: -15px;
  .token-header {
    overflow-x: hidden;
  }
  .header-left {
    padding: 30px 15px;
    position: relative;
    z-index: 0;
    h1 {
      color: white;
      font-weight: bolder;
      margin: 0;
      font-size: 26px;
      text-align: left;
    }
    &:before {
      position: absolute;
      content: ' ';
      z-index: -1;
      background: $blue;
      top: 0;
      bottom: 0;
      width: 114%;
      left: -14%;
      transform: skewX(-14deg);
    }
  }
  .header-right {
    text-align: right;
    padding-top: 8px;
    padding-bottom: 8px;
    span {
      vertical-align: middle;
      font-size: 26px;
    }
  }

  .row-header {
    text-align: center;
    font-weight: bolder;
  }

  .row-header, .row-token {
    padding-top: 8px;
    padding-bottom: 8px;
    &:hover {
      background: #f6f6f6;
    }
    &:nth-child(even) {
      background: #f8f8f8;
      &:hover {
        background: #f2f2f2;
      }
    }
    &:before {
      display: table;
      content: " ";
    }
    &:after {
      display: table;
      content: " ";
      clear: both;
    }
  }
  .amount-container {
    width: 60px;
    display: inline-block;
    text-align: right;
  }

  .premium-membership-block {
    background: $blue;
    color: #fff;
    padding: 30px 25px;
    margin-bottom: 15px;
    text-align: center;
    font-size: 36px;
    line-height: 1;
    h4 {
      font-size: 48px;
      font-weight: bolder;
      margin-bottom: 0;
      text-transform: capitalize;
      line-height: 1;
      & + span {
        font-size: 30px;
      }
    }
    .percent {
      color: rgba(255, 255, 255, 0.75);
      font-size: 26px;
    }
  }
  .premium-benefits-block {
    padding-left: 80px;
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-right: 30px;
    .left-icons {
      float: left;
      margin-left: -60px;
      width: 50px;
      img {
        margin-top: 10px;
      }
    }
  }
}
header {
  .logged-header {
    .navbar {
      border: none;
      border-radius: 0;
      min-height: inherit;
      .menu {
        min-height: inherit;
        padding: 0;
        .navbar-nav > li > a {
          padding: 12px 15px;
          &:hover {
            color: #94f0ff;
          }
          &:active {
            color: #00859e;
          }
        }
        .navbar-header {
          margin: 0;

          .container {
            padding: 0;
          }
          .title-menu {
            float: left;
          }
          .navbar-toggle {
            background: #00859e;
            border-radius: 100%;
            margin: 2px 15px 2px -4px;
            border: none;
            box-shadow: none;
            font-size: 30px;
            width: 40px;
            height: 40px;
            outline: none;
            .icon-bar {
              background: #00a9c6;
            }
            &.collapsed {
              background: white;
            }
          }
        }

        .premium {
          width: 40px;
          height: 40px;
          padding: 7px;
          display: inline-block;
          margin-top: 2px;
          background: #ff9933;
          border-radius: 100%;
          &:hover {
            background: #ffbf54;
          }
          &:active {
            background: #85491f;
          }
          .premium-menu {
            height: 26px;
            width: 26px;
          }
        }

        .navbar-collapse {
          padding: 0;
          margin: 0;
          .navbar-left {
            li > a {
              @include respond-to(sm) {
                padding: 12px;
              }
            }
          }
        }

        .navbar-nav > li {
          &.current {
            position: relative;
            &:before {
              content: " ";
              position: absolute;
              top: 0;
              left: calc(50% - 20px);
              width: 40px;
              border-left: 20px solid transparent;
              border-right: 20px solid transparent;
              border-top: 8px solid white;
            }
            @include respond-to(xs) {
              &:before {
                display: none;
              }
              background: white;
              a {
                color: #00a9c6;
              }
            }
          }
        }
        .navbar-nav.navbar-left {
          margin-left: -15px;
        }
        .navbar-nav.navbar-right {
          margin-right: -10px;
          li {
            padding: 0 10px;
          }
        }
        .navbar-nav.navbar-right > li {
          &.current {
            position: relative;
            &:before {
              content: " ";
              position: absolute;
              top: 0;
              left: calc(50% - 12px);
              width: 24px;
              border-left: 12px solid transparent;
              border-right: 12px solid transparent;
              border-top: 8px solid white;
            }
          }
        }
      }
    }
    .logo-part {
      &.container {
        padding: 0;
      }
      .navbar-header {
        width: 32%;
        .logo {
          margin-top: 0;
        }
        @include respond-to(sm) {
          .logo {
            width: 200px;
            height: 50px;
          }
        }
        @include respond-to(xs) {
          margin: 0;
          width: 100%;
          margin-left: 6px;
        }
      }
      .navbar.navbar-default {
        background: none;
        margin: 0;
        padding: 6px 0 10px 0;
        @include respond-to(xs) {
          padding: 0;
        }
      }
      .navbar-collapse {
        padding: 0;
        margin-top: 8px;
        width: 68%;
        @include respond-to(sm) {
          margin-top: 4px;
        }
        @include respond-to(xs) {
          width: 100%;
        }
        float: left;
        .auth-user {
          margin: 0;
          .head-login {
            color: #789200;
          }
          .blue-dropdown, .white-dropdown {
            margin: 6px;
            .head-login {
              float: left;
              padding: 6px 4px 2px 12px;
              border: none;
              border-right: 2px solid white;
              background: #00a9c6;
              margin-right: 1px;
              color: white;
              .name {
                float: left;
                max-width: 180px;
                font-size: 16px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              img {
                width: 28px;
                margin-top: -4px;
                height: 28px;
                background: white;
                border: 1px solid white;
                margin-left: 4px;
                border-radius: 100%;
              }
              &:hover {
                background-color: #269abc;;
              }
              &:active {
                background-color: #e6e6e6;
                color: #6f6f6f;
                img {
                  border-color: #6f6f6f;
                }
                box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
              }
            }
            .btn.dropdown-toggle {
              font-size: 20px;
            }
          }
        }
      }
    }
    .top-search {
      padding: 5px 0;
      float: left;
      width: calc(100% - 560px);
      @include respond-to(sm) {
        width: auto;
      }
      @include respond-to(xs) {
        width: 100%;
      }
      form {
        display: flex;
        padding: 0;
        margin: 0;
      }
      .input-group {
        margin: auto;
      }
      .input-group-btn {
        .btn {
          background-color: transparent;
          color: white;
          font-size: 18px;
          outline: none;
          line-height: 1;
          border: none;
          &:hover {
            color: #94f0ff;
          }
          &:focus {
            box-shadow: none;
            outline: none;
            color: white;
          }
          &:active {
            box-shadow: none;
            outline: none;
            color: #00859e;
          }
        }
        &.dropdown.open {
          .btn:focus, .btn:hover, .btn {
            box-shadow: none;
            outline: none;
            color: #00859e;
          }
        }
      }

      .navbar-form .input-group input.form-control {
        width: 300px;
        @include respond-to(sm) {
          width: 200px;
        }
        @include respond-to(xs) {
          width: 100%;
        }
        background: white;
        border: none;
        box-shadow: inset 0 0 8px 1px rgba(0, 0, 0, 0.15);
        &:focus {
          box-shadow: inset 0 0 8px 2px rgba(0, 0, 0, 0.15);
        }
      }
    }
    .wrap-counters {
      width: 140px;
      float: right;
      .circle-menu {
        margin-left: 20px;
      }
    }
  }
}
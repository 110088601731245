ng-select.ng-select.ng-select-multiple {
  .ng-select-container {
    .ng-value-container {
      .ng-value {
        color: #333;
        background-color: #fff;
        border: 1px solid #ccc;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 3px;

        .ng-value-icon {
          float: right;
          border-left: none;

          &.left {
            border-right: none;
          }

          &:hover {
            color: #333;
            background-color: #e6e6e6;
            border-color: #adadad;
          }
        }
      }
    }
  }
}

.ng-select {
  &.ng-select-focused {
    &:not(.ng-select-opened) > .ng-select-container,
    & > .ng-select-container,
    & > .ng-select-container:hover {
      border-color: #66afe9;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
    }
  }
}

%review-rating {
  rating.ng-dirty.ng-invalid {
    border-bottom: 1px solid #a94442;
    box-shadow: 0 0 5px #a94442;
    border-radius: 4px;
  }

  .bs-rating-star {
    color: $light-gray;
    margin: 0 1px;
  }

  .bs-rating-star.active {
    color: $blue;
  }

  .contact-rating {
    padding-top: 4px;
    font-size: 18px;
  }

  @include respond-to(xs) {
    .contact-rating {
      padding-top: 2px;
      font-size: 13px;
    }
  }
}

.network {
  .main-subtitle {
    font-size: 28px;
    text-transform: uppercase;
    margin: 0;
    margin-top: 18px;
  }
  .contact-list {
    @extend %review-rating;

    .other-reviews {
      .fa-retweet {
        font-size: 20px;
        padding-right: 8px;
        color: darkgray;
      }
      .counter {
        color: darkgray;
        padding-right: 16px;
        vertical-align: text-bottom;
        font-size: 12px;
        font-weight: bold;
      }
      @include respond-to(xs) {
        font-size: 10px;
        .fa-retweet {
          padding-right: 2px;
          font-size: 14px;
        }
        .counter {
          font-size: 10px;
          vertical-align: initial;
          padding-right: 2px;
        }
      }
    }

    .contact-rating {
      padding-top: 4px;
      font-size: 18px;

      .create-review {
        margin-left: 10px;
        font-size: 22px;
      }
    }

    @include respond-to(xs) {
      .contact-rating {
        padding-top: 2px;
        font-size: 13px;

        .create-review {
          margin-left: 0;
          font-size: 15px;
        }
      }
    }
  }

  .create-review {
    text-decoration: none !important;
  }

  .list-items {
    &.row-table {
      border-bottom: 1px solid #bdbec0;
    }

  }

  .premium-block-col {
    padding-bottom: 40px;
    .premium-block-user {
      position: absolute;
      bottom: 8px;
      left: 10px;
      z-index: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $blue;
    }
  }

  .left-col {
    .content {
      padding-bottom: 6px;
    }
    .project-item {
      a {
        @include link-light();
      }

      &.active-project {
        a {
          font-weight: bold;
          color: #000;
        }
      }

      dd.counter {
        font-weight: bold;
      }

    }
    a.network-menu-link {
      &:focus, &.active {
        color: black;
        font-weight: bolder;
      }
    }
    .network-menu-counter {
      color: $blue;
      &.active {
        color: black;
        font-weight: bolder;
      }
    }
  }
}

.review-contact {
  @extend %review-rating;

  .main-subtitle {
    font-size: 28px;
    text-transform: uppercase;
    margin: 18px 0 0;
    .title-name {
      text-transform: none;
    }

    .title-type {
      text-transform: none;
      font-size: 14px;
    }
  }

  .subtitle {
    font-size: 28px;
    margin-top: 20px;

  }
  .subtitle-clear {
    font-size: 24px;
    margin-top: 0;

  }
  .form-group {
    &:after {
      content: '';
      clear: both;
      display: block;
    }
  }

  &.review-contact-list {
    box-shadow: 0 1px 0 0 #ddd, 0 0 0 1px #eee;
    .review-block {
      > .row {
        padding-top: 10px;
        padding-bottom: 16px;
        border-bottom: 8px solid $main-background;
        box-shadow: inset 0 -1px 0 0 #ddd,
        inset 0 1px 0 0 #eee,
        -1px 0 0 0 $main-background,
        1px 0 0 0 $main-background,
        inset -1px 0 0 0 #eee,
        inset 1px 0 0 0 #eee;
        &:first-child {
          border-top: 8px solid $main-background;
          &:before {
            border-top: 1px solid #ddd;
            width: 100%;
            margin-top: -18px;
            height: 18px;
          }
        }
      }
      .avg-rate {
        @include respond-to(xs) {
          font-size: 12px;
        }
      }
      .actions {
        @include respond-to(xs) {
          font-size: 12px;
        }
      }
    }
    .profile-fill-link {
      margin-top: -25px;
      @include respond-to(xs) {
        margin-top: 0;
      }
    }
  }
  @include respond-to(xs) {
    .main-subtitle {
      font-size: 26px;
    }
    .subtitle {
      font-size: 24px;
      .icon-info {
        font-size: 14pt;
      }
    }
  }
}

.bonus-token-number {
  font-size: 34px;
  vertical-align: middle;
  line-height: 1;
}

.bonus-token-list-img {
  margin-right: 50px;
  @include respond-to(xs) {
    margin-right: 0;
  }
}
i.ng-hide-add-active, i.ng-hide-remove {
    display: inline-block !important;
}

.translate-cloak {
    display: none;
}

.modal-video .video-wrapper .btn-close {
    width: 100%;
    height: 40px;
    border-radius: 0px;
    font-size: 25px;
}

.modal-dialog button.close {
    z-index: 1000;
}

.modal-dialog button.close.big-white {
    color: white;
    font-size: 32px;
    opacity: .5;
}

/* FIXED BOOTSTRAP */
.checkbox input[type=checkbox], .checkbox-inline input[type=checkbox], .radio input[type=radio], .radio-inline input[type=radio] {
    margin-left: 0px;
}

.blured {
    text-shadow: 0px 0px 10px black;
    color: transparent !important;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.blured-blue {
    text-shadow: 0px 0px 10px #00a9c6;
}

.error-message {
    color: #a94442 !important;
}

.title-icon {
    font-size: 280px;
}

.clear-wrap[role=dialog] .modal-content {
    border: 0;
}

.clear-wrap[role=dialog] button.close {
    margin-right: 15px;
    margin-top: 10px;
}

.modal-info .bg-orange {
    padding-top: 20px;
    padding-bottom: 20px;
}

.modal-info h2 {
    font-weight: bold;
}

.modal-full-width {
}

.modal-full-width .modal-dialog {
    width: auto;
}

.modal-full-width .modal-content {
    background-color: #fb9b00;
}

.block-center {
    margin: 0px auto;
}

.modal-upgrade-block {
    display: block;
    width: 250px;
    padding-top: 30px;
    padding-bottom: 10px;
}

.modal-upgrade-block .circle {
    width: 150px;
    height: 150px;
    margin: 0px auto;
    border: 5px #fff solid;
}

.modal-upgrade-block .circle img {
    margin-top: 40px;
}

a.modal-upgrade-block:hover, a.modal-upgrade-block:focus {
    color: #fff;
}

.modal-content {
    border-radius: 0px;
}

.iv-modal-dialog .modal-content {
    font-size: 12px;
    font-family: Arial;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 10px;
    background-color: #fdffff;
}

dl {
    margin-bottom: 0;
}

.ng-hide {
    display: none;
}

.main-wrap {
    min-height: 100%;
    margin: auto;
}

body {
    background-color: #f6f6f6;
}

html, body {
    height: 100%;
    margin: 0;
}

.table-nopadding > div {
    padding: 0px;
    padding-left: 10px;
    padding-right: 5px;
}

.table-nopadding > div:first-child, .table-nopadding > div:last-child {
    padding: 0px;
}

.title {
    margin-top: 10px;
}

.button-square {
    border-radius: 0px;
    border: 0px;
    padding: 5px 10px;
}

.button-blue {
    color: #fff;
    background-color: #00a9c6;
}

.button-x4 {
    font-size: 20px;
}

.display-smob {
    display: none;
}

.clear {
    clear: both;
}

.highlight .form-control, .highlight.form-control {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

.premium-label-b:before {
    content: ' ';
    background: url('/../../images/premium_2.png') no-repeat;
    background-size: cover;
    width: 24px;
    height: 23px;
    display: inline-block;
    top: 5px;
    position: relative;
}

.form-edit-btn {
    display: block;
    margin-top: -30px;
}

.form-edit-btn.active {
    margin-top: -5px;
}

.removed {
    text-decoration: line-through;
}

.no-active {
    opacity: .6
}

.navbar-toggle {
    background-color: #fff;
}

.left-labels .control-label {
    text-align: left;
}

.title-menu {
    color: #fff;
    font-size: 40px;
    padding-left: 10px;
    padding-top: 5px;
}

a.title-menu:hover {
    color: #fff;
    text-decoration: none;
}

a.title-menu:focus {
    color: #fff;
}

.help-message {
    font-size: 11px;
    color: darkgray;
}

.form-group.has-feedback.search-grp .form-control {
    padding-right: 25px;
}

.form-control[readonly].date-input {
    background-color: #fff;
    cursor: text;
}

.form-control[disabled].date-input {
    background-color: #eee;
    cursor: text;
}

.loader-option {
    height: 15px;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

.src-text {
    white-space: pre-line;
}

.title-desc {
    font-weight: bold;
    color: #9caab1;
}

#carousel-example-generic .carousel-indicators {
    bottom: -25px;
}

#carousel-example-generic2 .carousel-indicators {
    bottom: 20px;
}

.carousel-indicators li {
    border: 1px solid #00a9c6;
}

.carousel-indicators li.active {
    background-color: #00a9c6;
}

#carousel-example-generic .carousel-inner > .item {
    margin-left: 50px;
}

.buy-slider {
    margin-right: -15px;
    margin-left: -15px;
}

.buy-slider .bx-wrapper {
    margin-bottom: 0;
}

.buy-slider .bx-pager {
    bottom: 10px;
    text-align: right;
}

.buy-slider .bx-pager .bx-pager-item {
    text-align: center;
}

.buy-slider .bx-wrapper .bx-pager a {
    background-color: #fff;
}

.buy-slider .bx-wrapper .bx-pager a:hover {
    background-color: #00a9c6;
}

.buy-slider .bx-wrapper .bx-pager a.active {
    background-color: #00a9c6;
}

.buy-slider .content {
    padding: 0;
    background-color: transparent;
}

.form-inline-head > div {
    display: inline-block;
}

.guest-menu .navbar-default .navbar-collapse {
    float: right;
    width: 57%;
}

.logged-top-menu .navbar-default .navbar-collapse {
    float: right;
    width: 68%;
}

.search-menu {
    width: 100%;
}

header .menu-xs {
    position: relative;
}

.lang-select-btn .btn, .lang-select-btn .btn-default:hover, .lang-select-btn .btn-default:focus, .lang-select-btn .dropdown-menu {
    background: #269abc;
    color: #ffffff;
    text-transform: uppercase;
    border: 0;
}

header .menu-xs .lang-select-btn .dropdown-menu {
    left: auto;
    top: auto;
}

.lang-select-btn .dropdown-menu {
    min-width: 30px;
    text-transform: uppercase;
}

.lang-select-btn .dropdown-menu > li > a {
    color: #ffffff;
}

.lang-select-btn .dropdown-menu > li > a:hover, .lang-select-btn .dropdown-menu > li > a:focus {
    color: #269abc;
    background: #e6e6e6;
}

.wrap-menu {
    margin-bottom: 0;
}

.wrap-menu .navbar-collapse {
    overflow-x: hidden;
}

.circle-menu {
    background-color: #fff;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    margin: 2px 0;
    display: block;
    float: left;
}

.navbar-nav > li.circle-menu > a {
    padding: 0;
    font-size: 20px;
}

.home-block .main-subtitle {
    font-size: 22px
}

.main-subtitle-orange {
  color: #fb9b00;
  font-weight: bold;
}

.widget-title {
    font-size: 16px;
    text-transform: uppercase;
    margin: 0;
    margin-top: 12px;
    display: inline-block;
}

.share-btn {
    background-color: #00a9c6;
    color: #fff;
    width: 100%;
}

.share-btn:focus, .share-btn:hover {
    background-color: #269abc;
    color: #fff;
}

.cradio input {
    display: none;
}

.cradio input + div {
    position: relative;
    padding-left: 24px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.cradio input + div:before {
    content: '\f1db';
    font-family: FontAwesome;
    font-size: 18px;
    color: #c3c3c3;
    position: absolute;
    left: 0;
    top: 1px;
}

.cradio input:checked + div:before {
    content: '\f058';
    color: #00a9c6;
}

.nav .seporator {
    width: 150px;
    height: 20px;
}

.nav li a.point {
    text-transform: uppercase;
    color: #00a9c6;
    font-size: 12px;
    padding-top: 20px;
    font-weight: bold;
    margin-right: 20px;
}

@media screen and (max-width: 1200px) {
  .nav li a.point {
    margin-right: 10px;
  }
}

.nav li a.point:hover {
    color: #269abc;
}

.hide-long-right {
    position: relative;
    overflow-x: hidden;
}

.hide-long-right:after {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    top: 0px;
    right: 0px;
    width: 20px;

    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(255, 255, 255, 1)));
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1);
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    background-color: #00a9c6;
    border-color: #00a9c6;
}

.soc-auth-reg-wrap > *:first-child {
    margin-bottom: 5px;
}

.fb-logo, .fb-logo:hover {
    text-decoration: none;
    color: #3A5795;
}

.in-logo, .in-logo:hover {
    text-decoration: none;
    color: #1884BC;
}

.tw-logo, .tw-logo:hover {
    text-decoration: none;
    color: #1884bc;
}

.reg-form.form-horizontal .control-label {
    text-align: left;
}

.horizontal-space-row > div {
    margin-bottom: 35px;
    margin-top: 35px;
}

.badge-green {
    background-color: #afba04;
}

.badge-light-green {
    background-color: #afba04;
}

.badge {
    font-size: 14px;
}

.list-projects .active .badge {
    background-color: #afba04;
}

.list-projects .active a {
    color: #afba04 !important;
}

.project-logo img {
    margin-top: 15px;
}

.project-logo img.banner {
    width: 100%;
    max-width: 646px;
    max-height: 200px;
}

.project-logo img.logo {
    width: 170px;
}

.line-block {
    border-top: 1px solid #cccccc;
}

.line-block-text {
    margin: 0 auto;
    margin-bottom: 20px;
    text-align: center;
}

.line-text {
    background-color: #fff;
    padding-left: 10px;
    padding-right: 10px;
}

.home-description h1 {
    font-size: 18px;
    font-weight: bold;
}

.row-content {
    margin-bottom: 30px;
}

.content {
    background-color: #fff;
}

.contentblock {
}

.contentblock .shadow-block {
    box-shadow: none;
}

.fill-profile-body .contentblock {
    margin-bottom: 15px;
}

.fill-profile-body div[ng-form] {
    border: 0;
}

.content-green {
    background-color: #afba04;
    padding-bottom: 4px;
    color: #fff;
}

.content-dark-green {
    background-color: #789200;
    padding-bottom: 4px;
    color: #fff;
    min-height: 200px;
}

.content-dark-green a {
    color: #fff;
    text-decoration: underline;
}

.content-dark-green a:hover, .content-dark-green a:focus {
    color: #fff;
    text-decoration: none;
}

.content-p {
    padding-top: 15px;
    padding-bottom: 15px;
}

.user-info-block {
    padding-bottom: 12px;
}

.dl-horizontal.widget-counters dt {
    width: 85%;
    text-align: left;
    font-weight: normal;
}

.dl-horizontal.widget-counters dd {
    text-align: right;
}

.main-counter {
    font-size: 30px;
    font-weight: bold;
    color: #afba04;
    display: inline-block;
    margin-top: 18px;
}

.main-counter:hover {
    color: #afba04;
}

.front-registration-cols .staf-pick {
    width: 100%;
    cursor: pointer;
}

.front-registration-cols .front-page-video .front-video-btn {
    text-align: center;
}

.front-registration-cols .front-page-video .front-video-btn img {
}

.feed-side .staf-pick {
    border: 15px #00aac3 solid;
    padding: 32px;
    color: #fff;
    background-color: #fff;
}

.feed-side .staf-pick > .circle {
    height: 160px;
    width: 160px;
    border: 2px #00aac3 solid;
}

.feed-side .staf-pick > .circle > .circle {
    height: 100%;
    width: 100%;
    border: 2px #fff solid;
    background-color: #00aac3;
    text-align: center;
}

.feed-side .staf-pick > .circle > .circle.fr {
    padding-top: 14px;
}

.feed-side .staf-pick .text-logo {
    width: 65px;
    margin-top: 2px;
}

.feed-side .staf-pick .first-text {
    font-size: 36px;
    display: block;
    line-height: 1;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
}

.feed-side .staf-pick .first-text.fr {
    font-size: 25px;
}

.feed-side .staf-pick .second-text {
    font-weight: bold;
    font-size: 46px;
    display: block;
    line-height: 1;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
}

.feed-side .staf-pick .second-text.fr {
    font-size: 31px;
}

.feed-side .staf-pick .line {
    height: 3px;
    background-color: #fff;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 5px;
    margin-bottom: 2px;
}

.feed-side .staf-pick .bolt {
}

.feed-side .staf-pick .bolt:before {
    content: ' ';
    display: inline-block;
    width: 0;
    height: 0;
    border-bottom: 15px solid #fff;
    border-left: 10px solid transparent;
}

.feed-side .staf-pick .bolt:after {
    content: ' ';
    display: inline-block;
    position: relative;
    top: 9px;
    width: 0;
    height: 0;
    border-top: 15px solid #fff;
    border-right: 10px solid transparent;
}

.feed-side .startups-corner {
    border: 15px #999999 solid;
    padding: 32px;
    color: #999999;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
}

.feed-side .startups-corner .line {
    height: 3px;
    background-color: #999999;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 5px;
    margin-bottom: 2px;
}

.feed-side .startups-corner .first-text {
    font-weight: bold;
    font-size: 27px;
}

.feed-side .startups-corner .second-text {
    font-size: 23px;
}

.feed-side .startups-corner .fa-play {
    font-size: 27px;
}

.registration-required .dropdown-menu {
    top: auto;
    left: auto;
    margin-left: -230px;
}

.registration-required .sign-up-wrap {
    padding: 0;
    padding-top: 10px;
}

.registration-required .sign-up {
    display: inline-block;
    font-size: 26px;
    height: auto;
    padding-left: 30px;
}

.registration-required .modal-title h4 {
    padding-right: 20px;
}

.registration-required .modal-title .login-block {
    padding-left: 35px;
    padding-top: 10px;
}

.form-login-wrap > .form-group {
    display: inline-block;
}

.form-login-wrap {
    margin-top: 13px;
}

.form-login-wrap > .form-group > .cbtn {
    font-size: 13px;
    height: 26px;
}

.form-login-wrap > .form-group {
    margin-left: 40px;
    margin-bottom: 0;
}

.form-login-wrap > .form-group:first-child {
    margin-left: 0;
}

.icon-entity {
    margin-top: 15px;
    max-width: 45px;
}

div.main-block {
    border-top: 3px #00a9c6 solid;
}

.block-title {
    text-transform: uppercase;
    font-weight: bold;
    color: #00a9c6;
}

.bottom-plus-url {
    padding-top: 15px;
    padding-right: 25px;
}

footer {
    background: #00a9c6;
    color: #fff;
    position: absolute;
    bottom: 0;
    width: 100%;
}

footer ul {
    list-style: none;
    padding: 0;
}

footer .bottom-menu {
    margin-top: 10px;
}

footer .bottom-menu a {
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
}

footer .bottom-menu a:first-child {
    border-left: 0;
}

footer .social-btns {
    margin-top: 50px;
    font-weight: bold;
    font-size: 11px;
}

footer .social-btns a {
    text-decoration: none;
}

footer .social-btns a img {
    border-radius: 16px;
}

.slider {
    margin-top: 5px;
}

.slider.slider3 {
    height: 120px;
}

.slider .item {
    padding: 5px;
    padding-left: 15px;
    vertical-align: top;
    color: #000;
    display: inline-block;
    width: 241.5px;
    height: 170px;
    margin-right: -1px;
}

.slider .item h6 {
    height: 30px;
    white-space: normal;
    color: #00a9c6;
    font-weight: bold;
    text-transform: uppercase;
}

.slider .item > .row {
    width: 235px;
    margin: 0;
}

.slider .item .slide-text {
    height: 30px;
    font-size: 11px;
}

.slider .item h6 {
    height: 30px;
    white-space: normal;
    color: #00a9c6;
    font-weight: bold;
    text-transform: uppercase;
}

.slider .item > .row {
    width: 235px;
    margin: 0;
}

.slider .item .slide-text {
    height: 30px;
    font-size: 11px;
}

.slider3 .item {
    line-height: 118px;
    padding: 0;
    width: 119px;
    height: 120px;
    text-align: center;
}

.slider3 .item img {
    max-width: 100px;
    max-height: 100px;
}

.right-col > div {
    margin-bottom: 10px;
}

.single .right-col > div {
    margin-bottom: 10px;
}

.single .left-col > div {
    margin-bottom: 10px;
}

.single .left-col strong {
    color: #9caab1;
}

.p-title {
    text-transform: uppercase;
    font-weight: bold;
}

.content-dark-green a.user-social.fa {
    text-decoration: none;
}

.content-dark-green a.user-social.fa:hover {
    text-decoration: none;
    color: #DBDBDB;
}

.progress-project {
    padding-top: 5px;
    padding-bottom: 10px;
    border-top: 1px solid #afd9ee;
    border-bottom: 1px solid #afd9ee;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.user-fill-percent {
    padding-top: 20px;
    padding-bottom: 20px;
}

.user-fill-percent .progress-status {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    color: #00a9c6;
}

.user-fill-percent .progress-bar {
    background-color: #00a9c6;
}

.green-progress .user-fill-percent .progress-bar {
    background-color: #76912e;
}

.green-progress .progress-status {
    color: #76912e;
}

.contact-btn {
    cursor: pointer;
    padding-left: 0;
    min-height: 80px;
}

.contact-btn .btn-text {
    font-weight: bold;
    font-size: 27px;
    font-style: italic;
}

.contact-btn .circle-arrow i:last-child {
    color: #789200;
}

.circle-arrow {
    width: 90px;
    height: 76px;
}

.circle-arrow i:last-child {
    font-size: 80px;
    height: 80px;
    margin-top: 18px;
    margin-left: 4px;
}

.circle-arrow i:first-child {
    color: #fff;
    font-size: 80px;
}

.contact-btn:hover .circle-arrow i:first-child {
    color: #789200;
}

.contact-btn:hover .circle-arrow i:last-child {
    color: #fff;
}

.message-btn {
    cursor: pointer;
    padding-left: 0;
    min-height: 80px;
}

.message-btn .btn-text {
    font-weight: bold;
    font-size: 27px;
    font-style: italic;
}

.message-btn:hover .circle-arrow i:first-child {
    color: #afba04;
}

.message-btn:hover .circle-arrow i:last-child {
    color: #fff;
}

.message-btn .circle-arrow i:last-child {
    color: #afba04;
}

.message-btn.message-btn-connector {
    padding-left: 15px;
    font-style: normal;
}

.message-btn.message-btn-connector .btn-text {
    font-style: normal;
}

.favorite-btn {
    cursor: pointer;
    min-height: 50px;
}

.favorite-btn a {
    font-weight: bold;
    font-size: 27px;
    padding-left: 10px;
}

.favorite-btn .circle > i:first-child {
    color: #fff;
    font-size: 29px;
    margin-top: 17px;
    margin-left: 15px;
}

.favorite-btn .circle > i:last-child {
    margin-top: 6px;
    margin-left: 15px;
    font-size: 24px;
}

.favorite-btn .icon-favorite {
    width: 50px;
    height: 50px;
}

.favorite-btn .icon-favorite i {
    color: #00a9c6;
}

.favorite-btn .icon-favorite > i:first-child {
    font-size: 50px;
}

.favorite-btn:hover a {
    text-decoration: underline;
}

.project-action > div > div {
    opacity: .4;
    cursor: pointer;
}

.project-action > div > div:hover {
    opacity: 1;
}

.comment-name-user {
    font-weight: bold;
    color: #9caab1;
}

.comment-date {
    font-size: 9px;
    color: #9caab1;
}

.comment-block {
    opacity: .3;
}

.comment-block:hover {
    opacity: 1;
}

.comment-new {
    color: #789200;
}

.reply-comment {
    color: #333;
}

.needs-menu > div {
    cursor: pointer;
}

.needs-menu > div > * {
    border-bottom: 1px dashed transparent;
}

.needs-menu > div > *:hover {
    border-bottom: 1px dashed #000;
}

.needs-menu .need-item {
    background: url('/../../images/arrow_need.svg') no-repeat left center;
    padding-left: 35px;
    margin-bottom: 10px;
}

.needs-menu .need-item a {
    color: #000;
    text-decoration: none;
    display: block;
}

.need-buttons {
    padding-top: 5px;
    position: static;
}

.need-detail dt {
    text-align: left;
}

.remove-btn {
    margin-top: -10px
}

#photo {
    display: none;
}

.premium-services, .premium-services h2, .premium-services h3 {
    font-weight: bold;
}

.premium-services {
    padding-bottom: 12px;
}

.premium-services h3 {
    margin-top: 10px;
    margin-bottom: 3px;
}

.premium-services p {
    margin: 0;
}

.premium-services .row h2 {
    margin-bottom: 3px;
}

.front-premium-btn {
    margin-bottom: 10px;
    margin-top: 16px;
}

.front-premium-btn > div {
    background-color: #00a9c6;
    color: #fff;
    height: 135px;
}

.front-premium-btn-invert > div {
    background-color: #e6e6e6;
    color: #00a9c6;
}

.share-upd {
    padding-bottom: 10px;
    margin-right: 0px;
    border-bottom: 1px #eee solid;
    position: absolute;
    right: 0px;
    top: 10px;
    left: 16px;
    z-index: 1000;
}

.share-upd .form-control-feedback {
    top: 0px;
}

.circle-btn {
    margin-top: -10px;
    cursor: pointer;
}

.circle-btn .fa-edit {
    padding-left: 3px;
    padding-top: 1px;
}

.circle-btn .fa-chevron-up {
    margin-top: -2px;
}

.form-profile-edit-btn {
    margin-top: -35px;
}

.form-profile-edit-btn.active {
    margin-top: 0px;
}

.send-message-btn {
    float: right;
}

.chats img, .msg-avatar {
    width: 50px;
}

.chats .col-date {
    width: 130px;
}

.chats .col-avatar {
    width: 60px;
}

.chats tr:hover {
    cursor: pointer;
    background-color: #afd9ee;
}

.chat-not-read {
    background-color: #afd9ee;
}

.chats .last-message {
    padding: 3px;
    word-break: break-all;
}

.msg-name {
    font-weight: bold;
    color: #1884bc;
    font-size: 11px;
}

.msg-data {
    font-size: 11px;
}

.msg-text {
    white-space: pre-wrap;
}

.msg {
    margin-bottom: 10px;
}

.hotkey-help {
    color: #cccccc;
    font-size: 11px;
}

.chat-view {
    max-height: 600px;
}

[data-inverse] {
    overflow-y: scroll;
}

.spiner-lg {
    font-size: 40px;
}

.social-share {
    margin-top: 5px;
    margin-bottom: 2px;
}

.social-share .addthis_sharing_toolbox {
    display: inline-block;
}

.social-share strong {
    display: inline-block;
    margin-top: 0;
}

.partnership_right_bar > div {
    margin-bottom: 15px;
}

.partnership_category {
    padding-left: 5px;
    border: rgba(82, 168, 236, 0.75) 1px solid;
    box-shadow: 0 0 8px rgba(82, 168, 236, 0.5);
    border-radius: 2px;
}

.partnership_category.ng-dirty.ng-invalid {
    border-color: #a94442;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
}

.partnership_category_check {
    box-shadow: 0 0 12px rgba(120, 146, 0, 0.5);
    border-color: rgba(120, 146, 0, 0.75)
}

.partnership_category label {
    display: block;
    cursor: pointer;
    font-weight: normal;
    color: #9caab1;
    font-size: 12px;
}

.partnership_category label:hover {
    text-decoration: underline;
    color: #00a9c6;
    font-weight: bold;
}

.partnership_category label.active {
    text-decoration: underline;
    color: #00a9c6;
    font-weight: bold;
}

.partnership_category input {
    display: none;
}

.partnership_logo {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-color: rgba(82, 168, 236, 0.75);
    box-shadow: 0 0 8px rgba(82, 168, 236, 0.5);
    border-radius: 2px;
}

.partnership_logo img {
    max-width: 100px;
    max-height: 100px;
}

.partnership_logo_btn input {
    display: none;
}

.table td.date {
    width: 120px;
}

.table td.view {
    width: 110px;
}

.table td.accept {
    width: 65px;
}

form #summary {
    height: 200px;
}

form textarea.accomplishments {
    height: 150px;
}

dd {
    min-height: 20px;
}

.iconsub {
    margin-top: -8px;
}

.announces {
    padding: 20px 0;
    padding-left: 15px;
    height: 584px;
    padding-top: 55px;
}

.announces section {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
}

.announces section .date {
    color: #888;
}

.announces section .person {
    font-weight: bold;
}

.announces .stepdone {
    text-decoration: line-through;
}

.step-announce {
    background-image: url('/../../images/step1-back.jpg');
    height: 362px;
}

.navbar-collapse.in {
    overflow-y: visible;
}

.rating-sx > * > i:first-child {
    font-size: 40px;
}

.rating-sx > * > i:last-child {
    color: #fff;
}

.animate:focus {
    -webkit-transition: height 50ms ease-in-out;
    -moz-transition: height 50ms ease-in-out;
    -o-transition: height 50ms ease-in-out;
    transition: height 50ms ease-in-out;
}

.project-action > div > div.rating-my-project {
    opacity: 1;
    cursor: auto;
}

/*.rating-my-project i:first-child {font-size: 55px;}*/
/*.rating-my-project i:last-child {color: #fff; width: 50px; font-size: 30px;}*/

.form-control.ng-dirty.ng-invalid, .form-control.ng-dirty.ng-invalid-ui-select-required, .ta-root.ng-dirty.ng-invalid .form-control.ta-text, .ta-root.ng-dirty.ng-invalid .ta-root.focussed .ta-scroll-window.form-control {
    border-color: #a94442;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

img.ng-dirty.ng-invalid, .form-control.ng-dirty.ng-invalid:focus, .form-control.ng-dirty.ng-invalid-ui-select-required:focus {
    border-color: #843534;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
}

.init-show-error .ng-invalid {
    border-color: #a94442;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.init-show-error .ng-invalid:focus {
    border-color: #843534;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
}

.navbar-header .menu-xs {
    padding: 5px;
    margin-top: 4px;
    float: right;
}

.xs-slide-info img {
    margin: 20px;
}

.xs-slide-info img:first-child {
    float: left;
}

.xs-slide-info img:last-child {
    float: right;
}

.front-price-info-xs {
    margin-bottom: 20px;
    padding-bottom: 15px;
}

.front-price-info-xs .info-text {
    font-weight: bold;
}

.front-price-info-xs .front-premium-btn > div {
    padding-top: 30px;
    font-weight: bold;
}

.clickable_feed:hover {
    cursor: pointer;
    background-color: #e6fbff;
}

.has-video-partner .video-logo {
    position: relative;
    right: 0;
    margin-top: 5px;
}

.has-video-project .video-logo {
    margin-left: -20px;
}

.video-logo {
    width: 50px;
    height: 30px;
    background-color: #00a9c6;
    padding-top: 6px;
    padding-left: 21px;
    border-radius: 6px;
}

.video-logo > div {
    height: 0;
    width: 5px;
    border: 9px solid transparent;
    border-right-width: 0;
    border-left-color: #fff;
}

.profile-education {
    border-bottom: 2px solid #afd9ee;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.profile-list:not(:last-child) {
    border-bottom: 1px solid #afd9ee;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.profile-jobs {
    border-bottom: 2px solid #afd9ee;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.popup-body {
    padding: 10px;
}

.user-social {
    color: #fff;
    margin-left: 10px;
}

.user-social:hover {
    color: #ddd;
    text-decoration: none;
}

.profile-global-save-wrap {
    padding-top: 45px;
    padding-bottom: 20px;
}

.feed-avatar {
    border-radius: 45px;
}

.content.ng-hide-remove {
    -webkit-transition: all linear 0.5s;
    -moz-transition: all linear 0.5s;
    -ms-transition: all linear 0.5s;
    -o-transition: all linear 0.5s;
    transition: all linear 0.5s;
}

.content.ng-hide-remove {
    opacity: 0;
}

.content.ng-hide-remove.ng-hide-remove-active {
    opacity: 1;
}

.feed {
    overflow: visible;
}

.feed div {
    overflow: visible;
}

.animated.ng-enter {
    -webkit-transition: all linear 0.5s;
    -moz-transition: all linear 0.5s;
    -ms-transition: all linear 0.5s;
    -o-transition: all linear 0.5s;
    transition: all linear 0.5s;
}

.animated.ng-enter,
.animated.ng-leave.ng-leave-active {
    opacity: 0;
}

.animated.ng-leave,
.animated.ng-enter.ng-enter-active {
    opacity: 1;
}

.premium-img-list {
    width: 25px;
}

.premium-img-single {
    width: 35px;
}

.premium-block-single {
    position: absolute;
    bottom: 5px;
    right: 5px;
}

.premium-menu {
    height: 40px;
}

.menu .navbar-nav .premium a {
    padding: 0px;
}

.upgrade-section header {
    margin-bottom: 0;
}

.container.upgrade-section, .small-upgrade > .upgrade-section {
    /*margin-top: 15px;*/
    padding: 15px;
}

.small-upgrade > .upgrade-section {
    padding: 0px;
    margin-bottom: 15px;
}

.upgrade-section .col-md-8 h2.title {
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-size: 18px;
    color: #333;
}

.upgrade-section .col-md-1 {
    width: 6.333333%;
    padding: 0;
    margin: 0;
}

.upgrade-section .row .col-md-1:first-child {
    width: 4%;
}

.upgrade-section .form-inline {
    margin-top: 20px;
    padding-bottom: 15px;
}

.upgrade-section .form-inline .form-control {
    width: 240px;
    margin-left: 20px;
}

.upgrade-section .button-square {
    padding: 8px 13px;
    text-transform: uppercase;
    margin-top: 15px;
    margin-bottom: 10px;
}

.upgrade-section .col-md-12.content {
    padding: 25px 0 0 0;
}

.upgrade-section .col-md-12 .row.content-row {
    padding-bottom: 25px;
}

.premium-content.col-md-12.content {
    padding: 25px 0 25px 15px;
}

.premium-padding {
    padding-bottom: 15px;
}

.premium-content .container.upgrade-section {
    padding-top: 0;
    padding-bottom: 0
}

.basic-services-title {
    display: block;
    position: relative;
    margin-left: 15px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: #262626;
    padding-bottom: 20px;
}

.basic-services-subtitle {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #9CAAB0;
}

.basic-services .row {
    margin-right: 0;
    margin-left: 0;
}

.basic-services .col-md-12.content {
    padding: 25px;
}

.basic-services .row .col-md-1 {
    width: 20%;
}

.basic-services .row .col-md-1:first-child {
    width: 10%;
}

.basic-align .col-md-3 {
    text-align: center;
}

.basic-services.upgrade-section .col-md-3 {
    width: 50%;
}

.basic-services-img {
    height: 100px;
}

.row.upgrade-separator {
    background-color: #00A9C6;
    font-family: Arial, sans-serif;
    font-size: 30px;
}

.row.upgrade-separator .basic-services-title {
    color: #fff;
    font-size: 30px;
    margin-left: 100px;
    padding-bottom: 0;
}

.row.basic-services.upgrade-section .upgrade-separator {
    margin-top: -15px;
}

.popular-upgrade.active {
    display: none;
    position: absolute;
    margin-top: -40px;
    width: 100%;
}

.popular-upgrade.active {
    display: inline-block;
}

.upgrade-sale {
    margin-top: 10px;
    position: relative;
    display: block;
}

.order-cost > div {
    padding-right: 0;
    padding-left: 0;
}

.order-cost .circle {
    width: 115px;
    height: 115px;
    text-align: center;
    padding-top: 10%;
}

.count-sale {
    color: #aaaaaa;
}

.upgrade-check {
    width: 100%;
    height: 100%;
    padding: 10px;
}

.order-section {
    width: 67%;
    display: table;
    margin: 0 auto;
}

.order-section .col-md-12 {
    padding-bottom: 15px;
}

.more-information {
    margin-top: 25px;
    padding: 25px;
}

.more-information .basic-services-subtitle {
    text-decoration: underline;
    margin-left: 15px;
}

.small-upgrade .upgrade-section .more-information.col-md-12.content {
    padding: 25px 25px 0 25px;
}

.form-horizontal .control-label {
    text-align: left;
}

.form-horizontal .form-control {
    display: block;
    /*max-width: 555px;*/
}

.form-horizontal.customer-form .input-group .form-control {
    max-width: 514px;
}

.form-horizontal .form-control.ta-editor {
    max-width: none;
}

.form-group.premium-subscription {
    margin-left: 0;
    margin-bottom: 0;
}

.order-validity {
    padding-bottom: 20px;
}

.text-1line {
    display: block; /* Fallback for non-webkit */
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 15px;
    -webkit-box-orient: vertical;
}

.text-2lines {
    display: block; /* Fallback for non-webkit */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 30px;
    -webkit-box-orient: vertical;
}

.title-2lines {
    display: block; /* Fallback for non-webkit */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 37px;
    -webkit-box-orient: vertical;
}

.clear-margin {
    margin: 0;
}

.grid-info-panel .sort-buttons > button:first-child {
    margin-left: 80px;
}

.grid-info-panel .sort-buttons > button {
    margin-left: 20px;
}

.grid-info-panel .sm-conter {
    text-align: right;
}

/* FOOTER MENU */

footer .footer-menu > li {
    display: inline-block;
    vertical-align: top;
    padding-right: 25px;
}

footer .footer-menu > li a {
    padding: 0 0 0 25px;
    display: inline-block;
}

footer .footer-menu > li:last-child, footer .footer-menu > li:last-child a {
    padding-right: 0;
}

footer .footer-menu > li:first-child a {
    padding-left: 0;
}

footer .footer-menu > li:first-child:before {
    content: "";
}

footer .footer-menu > li:before {
    content: "|";
    font-size: 20px;
    font-weight: normal;
    vertical-align: top;
}

footer .footer-menu .sub-menu {
    margin-top: -8px;
    margin-left: 5px;
}

footer .footer-menu > li:first-child .sub-menu {
    margin-left: 0;
}

footer .footer-menu .sub-menu a {
    font-weight: normal;
}

footer .footerfooter-menu li {
    display: inline-block;
}

footer .footerfooter-menu li:after {
    content: "|";
}

footer .footerfooter-menu li:last-child:after {
    content: "";
}

footer .footerfooter-menu a {
    color: #FFFFFF;
    display: inline-block;
    padding: 0 20px;
    font-size: 11px;
}

/* NEW HOME */

.sign-up-under-video > div {
    min-height: 70px;
}

.sign-up-under-video .video-text {
    padding: 10px;
    padding-left: 93px;
    padding-right: 93px;
}

.front-slider {
    margin-right: -15px;
    margin-left: -15px;
}

.front-slider .bx-wrapper {
    margin-bottom: 0px;
    border: 0px;
}

.front-slider .bx-wrapper .bx-viewport {
    border: 0px;
    left: 0px;
}

.front-slider .content {
    padding: 0px;
}

/*.front-slider .content img{ width: 1170px; }*/

.front-slider .bx-wrapper .caption {
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 60%;
    background-color: rgba(0, 169, 198, 0.75);
    transform: skewX(-20deg);
    text-align: center;
    left: 20%;
    color: #fff;
    padding-top: 10px;
}

.front-slider .bx-wrapper .caption .text-logo {
    width: 141px;
    display: inline;
    padding-bottom: 75px;
}

.front-slider .bx-wrapper .caption .bg-dark-blue, .front-slider .bx-wrapper .caption .bg-green {
    padding-left: 10px;
    padding-right: 10px;
}

.front-slider .caption > div {
    text-align: center;
    transform: skewX(20deg);
}

.front-slider .caption > div > span {
    font-family: 'Open Sans', sans-serif;
    display: block;
    text-align: center;
    font-size: 30px;
}

/*
.fa-chevron-right:before {
    margin: 10px;
}
*/
.comming-soon img {
    width: 100%;
}

video {
    max-width: 100%;
    height: auto;
}

iframe,
embed,
object {
    max-width: 100%;
}

.premium-suggest img {
    width: 100%;
}

.premium-suggest .checkboxes {
    padding-left: 20px;
}

.premium-suggest .checkboxes input[type=checkbox] {
    margin-left: -20px;
    margin-right: 5px;
}

.announces.step-announce {
    padding: 0;
    overflow: hidden;
}

.row.content.announces.step-announce a {
    text-decoration: none;
}

.upgrade-button {
    height: 70px;
    width: 100%;
    margin-top: 10px;
    background-color: #FF9933;
    display: table;
    cursor: pointer;
}

.step-announce p:first-child {
    position: absolute;
    width: 1170px;
    text-align: center;
    z-index: 3;
    margin-top: 10px;
}

.step-announce div {
    float: left !important;
}

.passive-step-back {
    background-color: rgba(154, 184, 189, 0.9);
}

.active-step-back {
    background-color: rgba(0, 169, 198, 0.9);
}

.passed-step-back {
    background-color: rgba(154, 184, 189, 0.9);
}

.passive-step-back .passive-step {
    background-color: rgb(114, 114, 116);
}

.passive-step-back .passed-steps,
.active-step-back .passive-step,
.active-step-back .passed-steps {
    display: none;
}

.active-step-back .active-steps {
    display: inline-block;
}

.stepdone-1 {
    width: 43%;
    height: 100%;
    transform: skewX(-20deg);
    margin-left: -66px;
    display: table;
}

.stepdone-2 {
    width: 37%;
    height: 100%;
    transform: skewX(-20deg);
    margin-left: -1px;
    display: table;
    position: relative;
    z-index: 2;
}

.stepdone-3 {
    width: 35%;
    height: 100%;
    transform: skewX(-20deg);
    margin-right: -69px;
    display: table;
    margin-top: -362px;
    float: right !important;
    z-index: 1;
}

.stepdone-1.passed-step-back .steps-tooltip-1,
.stepdone-2.passive-step-back .steps-tooltip-2,
.stepdone-2.passed-step-back .steps-tooltip-2 {
    display: inline-block;
}

.stepdone-1.active-step-back + .stepdone-2 {
    width: 41%;
    margin-left: -90px;
    background-color: rgba(151, 152, 152, 0.9);
}

.stepdone-2.active-step-back {
    background-color: rgba(16, 177, 199, 0.9);
}

.step1-content,
.step2-content,
.step3-content {
    width: 100%;
    position: absolute;
    height: 250px;
    bottom: 0;
    display: table;
    text-align: center;
}

.passive-step {
    width: 80px;
    height: 80px;
    display: inline-block;
    position: relative;
    transform: skewX(20deg);
    background-color: rgb(128, 156, 161);
    border-radius: 50%;
    margin-bottom: 5px;
    margin-top: 60px;

}

.passive-step span {
    font-family: 'Open Sans';
    font-size: 48px;
    font-weight: bold;
    color: #fff;
    margin-top: 5px;
    display: inline-block;
}

.stepdone-2 .passive-step {
    background-color: rgb(114, 114, 116);
}

.step-announce .passive-step > p {
    margin-left: -175px;
    width: 340px;
    margin-top: 10px;
}

.step1-content .passive-step > p,
.step1-content .passed-step > p {
    margin-left: -110px;
}

.active-steps {
    width: 275px;
    height: 270px;
    display: inline-block;
    position: relative;
    transform: skewX(20deg);
    border: 10px solid #fff;
    border-radius: 50%;
    margin-bottom: 5px;
    display: none;
}

.active-steps img {
    margin: 10px auto 5px auto;
    width: 136px;
    height: 128px;
}

.active-steps span {
    position: absolute;
    margin-left: -80px;
    margin-top: 40px;
    font-family: 'Open Sans';
    font-weight: bold;
    font-size: 48px;
}

.steps-tooltip-2 {
    position: absolute;
    font-size: 48px;
    font-family: 'Open Sans';
    margin-top: 38px;
    z-index: 70;
    display: none;
    width: 600px;
    text-align: center;
    color: #fff;
    margin-left: -120px;
    transform: skewX(20deg);
}

.steps-tooltip-1 {
    position: absolute;
    font-size: 48px;
    font-family: 'Open Sans';
    margin-top: 25px;
    z-index: 70;
    display: none;
    width: 300px;
    transform: skewX(20deg) rotate(-20deg);
    text-align: center;
    color: #fff;
    line-height: 1;
    margin-left: 15px;
}

.stepdone-1 p,
.stepdone-2 p,
.stepdone-3 p {
    font-family: 'Open Sans';
    font-size: 36px;
    color: #fff;
    margin-left: 0;
    margin-top: 0;
    line-height: 1.2;
}

.passed-steps img {
    position: absolute;
    margin-left: -55px;
    margin-top: 20px;
    transform: rotate(10deg) skewX(20deg);
}

.front-page-startup-corner {
    padding-left: 2%;
    background-image: url('/../../images/startup-corner.jpg');
    background-size: cover;
    color: #fff;
    vertical-align: top;
}

.front-page-startup-corner .left-text {
    position: absolute;
    -ms-transform: rotate(-30deg); /* IE 9 */
    -webkit-transform: rotate(-30deg); /* Safari */
    -moz-transform: rotate(-30deg); /* Safari */
    transform: rotate(-30deg);
    top: 40px;
    left: 0px;
    font-size: 25px;
    text-align: center;
}

.front-page-startup-corner .bottom-text {
    text-align: center;
    font-size: 20px;
}

.front-page-startup-corner .center-block {
    background-color: #25a9c6;
    padding: 3px;
    margin: 0 auto;
    width: 50%;
    margin-top: 35px;
}

.front-page-startup-corner .center-block > div {
    border: 2px #fff solid;
    width: 100%;
    text-align: center;
    padding-top: 15px;
    text-transform: uppercase;
    font-family: Arial, Helvetica, sans-serif;
    font-family: 'Open Sans', sans-serif;
}

.front-page-startup-corner .center-block .text-logo {
    height: 16px;
}

.front-page-startup-corner .center-block .line {
    height: 4px;
    background-color: #fff;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.front-page-startup-corner .center-block .line.bottom {
    margin-top: -10px;
}

.front-page-startup-corner .center-block h3 {
    margin-top: -15px;
    font-size: 50px;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
}

.front-page-startup-corner .center-block span {
    font-size: 45px;
    font-family: 'Open Sans', sans-serif;
}

.front-page-startup-corner .center-block div.fa-play {
    margin-top: -10px;
    margin-bottom: 5px;
}

.stepdone-2 .step2-content.step2-content-partner p {
    font-size: 48px;
    transform: skewX(20deg);
    width: 100%;
}

.passive-step-back {
    background-color: rgba(154, 184, 189, 0.9);
}

.stepdone-2 {
    background-color: rgba(151, 152, 152, 0.9);
}

.stepdone-3 {
    width: 35%;
    margin-right: -67px;
    margin-top: 0;
}

.passive-step-back .passive-step {
    background-color: rgb(128, 156, 161);
    margin-top: 50px;
}

.stepdone-1.passed-step-back {
    width: 40%;
    margin-left: -73px;
}

.result-step.owner-result-step {
    background-image: url('/../../images/Discover-E.jpg');
}

.result-step.connector.published {
    background-image: url('/../../images/Discover-C.jpg');
}

.fans-hidden {
    font-size: 18px;
    padding: 20px;
}

.notify-upgrade {
    padding: 10px;
    font-size: 16px;
}

.welcome-premium img {
    width: 100%;
    background-color: #fff;
    padding: 5px;
    box-sizing: border-box;
}

.grow-circle-grey {
    display: table;
    position: relative;
    text-align: center;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: 5px solid #d4d4d4;
    background-color: rgba(156, 170, 176, 0.58);
    margin-top: 10px;
}

.grow-circle-grey.blue {
    background-color: #00A9C6;
    opacity: 0.58;
    border: 5px solid #a6e1ec;
}

.grow-circle-grey.recom {
    display: block;
    margin: 0 auto;
    width: 60px;
    height: 60px;
    border-width: 3px;
    padding-top: 9px;
    margin-bottom: 10px;
}

.grow-circle-grey.recom > img {
    width: 40px;
    height: 35px;
}

.color-row .grow-circle-grey img {
    display: inline-table;
    margin-top: 11px;
    opacity: 0.67;
    cursor: pointer;
    width: 40px;
    height: 40px;
}

.basic-services .col-md-12.content.color-row,
.content.color-row {
    padding: 0;
}

.color-row {
    background-color: rgba(0, 169, 198, 0.16);
    padding: 10px 50px 10px 70px;
    margin-right: 0;
    margin-left: 0;
}

.color-row:nth-child(odd) {
    background-color: #fff;

}

.color-row p {
    font-family: Arial, sans-serif;
}

.premium-text-align .col-md-10,
.premium-text-align .col-md-2 {
    height: 70px;
    position: relative;
    display: table;

}

.premium-text-align .color-row p,
.premium-text-align .color-row img {
    vertical-align: middle;
    display: table-cell;
}

.row.partner-premium .partners-list {
    background-color: #FFFFFF;
}

.row.partner-premium .partner-talent p {
    margin: 0;
}

.row.partner-premium .partner-talent p.green {
    margin-top: 10px;
}

.premium-admin-back {
    background-image: url("/../../images/premium-admin.png");
    width: 100%;
    height: 305px;
    display: inline-table;
    position: relative;
    background-size: cover;
    text-align: center;
}

.premium-admin-back p {
    display: table-cell;
    vertical-align: middle;
    color: #FFFFFF;
    font-size: 40px;
}

.account-admin {
    background-color: #FFFFFF;
    padding: 10px;
    text-transform: uppercase;
}

.account-admin h5 {
    color: #9caab1;
    font-weight: bold;
}

.account-admin > p > a {
    color: #00b3cb;
}

.admin-info {
    background-color: #FFFFFF;
}

.admin-info > .row {
    padding: 10px;
}

.admin-info .row:first-child {
    background-color: #00b3cb;
    color: #FFFFFF;
    text-align: center;
}

.admin-info .col-md-2,
.order-history .col-md-2 {
    text-align: center;
}

.admin-info i {
    font-size: 46px;
    color: #00b3cb;
}

.admin-info i > span {
    font-size: 10px;
    color: #9caab1;
    display: table;
    position: relative;
    margin-top: 5px;
}

.start-day {
    margin-top: 10px;
}

.order-history > .row {
    padding: 2px 10px;
}

.order-history > .row:first-child {
    background-color: #a6e1ec;
    color: #9CAAB0;
    text-align: center;
}

.order-details .table > tbody > tr > td:last-child > i {
    color: #00A9C6;
    font-size: 30px;
}

.content.unsubscribe {
    padding-bottom: 20px;
}

.content.unsubscribe h3 {
    color: #00A9C6;
    font-weight: bold;
}

.unsubscribe-description {
    margin-top: 10px;
}

.content.unsubscribe input[type="checkbox"] {
    opacity: 0;
    position: absolute;
}

.content.unsubscribe i.fa {
    color: #00A9C6;
    font-size: 18px;
    margin-top: 5px;
}

.content.unsubscribe i.fa.fa-check-square-o {
    display: none;
}

.content.unsubscribe input[type="checkbox"]:checked + label > span i.fa.fa-square-o {
    display: none;
}

.content.unsubscribe input[type="checkbox"]:checked + label > span i.fa.fa-check-square-o {
    display: block;
    margin-right: -3px;
}

.go-top-arrow-wrap {
    position: fixed;
    transform: translate(-50%);
    left: 50%;
    bottom: 0px;
    z-index: 1000;
    /* TODO: show in another place */
    display: none;
}

.go-top-arrow-wrap .go-top-arrow {
    position: fixed;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    border: 3px #fff solid;
    background-color: #00a9c6;
    color: #fff;
    bottom: 30px;
    right: 30px;
    font-size: 35px;
}

.go-top-arrow-wrap .go-top-arrow:hover {
    cursor: pointer;
    background-color: #269abc;
}

.messages .messages-title > div {
    display: table-cell;
}

.stoping-complete-profile.container {
    width: 520px;
    font-size: 12px;
    font-family: Arial, serif;
}

.stoping-complete-profile h2 {
    font-size: 18px;
    font-family: Arial, serif;
}

.stoping-complete-profile .content {
    padding-right: 50px;
    padding-left: 50px;
}

.stoping-complete-profile form {
    padding-left: 20px;
    border-top: 1px solid #ccc;
    padding-top: 10px;
    padding-bottom: 10px;
}

textarea.form-control {
    min-height: 60px;
}

.quill-wrapper .advanced-wrapper {
    background-color: #fff;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    box-shadow: 0 0 10px #ccc;
    margin-left: auto;
    margin-right: auto;
    min-width: 320px;
    width: 100%;
}

.quill-wrapper .toolbar {
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
}

.no-logo {
    background-size: contain;
    background: url('/../../images/partner_no_logo.png') no-repeat center;
}

.partnership-registered-msg {
    border-radius: 50px 50px 50px 50px / 30px 30px 30px 30px;
    width: 583px;
    height: 200px;
    margin: 0 auto;
}

.chat-view {
    overflow-y: auto;
}

.btn-default-focus .ui-select-toggle {
    border-color: #66afe9;
}

a:focus {
    text-decoration: underline;
}

.ui-select-container.ng-invalid.ng-dirty .ui-select-toggle.form-control {
    border-color: #a94442;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.ui-select-container.ng-invalid.ng-dirty .btn-default-focus {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
}

.ui-select-container.ng-invalid.ng-dirty .btn-default-focus .ui-select-toggle {
    border-color: #843534;
}

.ui-select-multiple.ui-select-bootstrap {
    position: relative;
    min-height: 34px;
    padding: 5px;
}

.ui-select-multiple input.ui-select-search {
    position: absolute;
}

.ui-select-multiple.ui-select-bootstrap .ui-select-match-item {
    margin: 0 3px 2px 0;
}

.ui-select-multiple.ui-select-bootstrap .ui-select-match .close {
    font-size: 1.6rem;
    line-height: initial;
}

.ui-select-bootstrap .ui-select-toggle > a.btn.btn-link {
    color: #333;
    margin-top: 0;
}

.vertical-middle {
    vertical-align: middle;
    display: inline;
}

.vertical-top {
    vertical-align: top;
}

.vertical-bottom {
    vertical-align: bottom;
}

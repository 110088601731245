.requests-info {
  margin-bottom: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  .blue-arrow {
    margin-top: 20px;
    display: inline-block;
    background-color: #00a9c6;
    border-radius: 100%;
    border: 1px solid #00a9c6;
    transition: .5s background-color, .5s border-color;
    a {
      opacity: .8;
      transition: .5s opacity;
      @include circle-arrow(60px, 3px);

      &:hover {
        opacity: 1;
        transition: 0.2s opacity;
      }
      &:active {
        opacity: .9;
      }
    }
    &:hover {
      background-color: #25a9c6;
      border-color: #00a2be;
      transition: .2s background-color, .2s border-color;
    }
    &:active {
      border-color: #00a2be;
      background-color: #00a2be;
    }
  }
  @include respond-to(xs) {
    margin-top: 20px;
  }
}

.modal-request {
  &.iv-modal-dialog.modal-dialog .modal-content {
    font-size: 14px;
  }

  &.modal-dialog {
    .modal-content {
      padding-bottom: 0;
      .modal-title {
        color: #00a9c6;
        font-size: 15px;
        font-weight: bold;
        padding: 10px 35px 6px 15px;
        border-bottom: 3px solid #f6f6f6;
        margin: 0 -30px;
      }
      .modal-footer {
        border-top: 3px solid #f6f6f6;
        .buttons-response {
          margin-top: 0;
          & > div:last-child {
            margin: 0;
            padding-right: 15px;
          }
          & > div:first-child {
            margin: 0;
            padding-left: 15px;
          }
          button {
            width: 80%;
          }
        }
      }
      .modal-title, .modal-footer {
        background: white;
        height: 60px;
        z-index: 1;
      }
      .modal-body {
        margin-left: -30px;
        margin-right: -30px;
        padding: 30px 15px 30px;
        &.with-buttons {
          max-height: -moz-calc(100% - 60px);
          max-height: -webkit-calc(100% - 60px);
          max-height: calc(100% - 60px);
          overflow-y: auto;
        }
        .request-info-block {
          margin-top: 15px;
          padding-bottom: 10px;
          .info-title {
            color: #00a9c6;
            padding: 5px 0;
            position: relative;
            &.with-num {
              padding-left: 25px;
              .detail-num {
                position: absolute;
                top: 6px;
                font-weight: bold;
                left: 0;
                font-size: 11px;
                color: #fff;
                background-color: #00a9c6;
                border-radius: 5px;
                line-height: 1;
                padding: 4px 7px;
              }
            }
            .info-detail-link {
              text-decoration: underline;
            }
          }
          .info-detail {
            color: darkgray;
            margin-left: 22px;
            margin-bottom: 5px;
          }
          .info-content {
            margin-left: 22px;
            white-space: pre-wrap;
            position: relative;
            line-height: 0;
            &:before {
              content: "";
              width: 0;
              height: 0;
              margin-left: 3px;
              margin-top: 2px;
              position: absolute;
              border-top: 8px inset transparent;
              border-bottom: 8px inset transparent;
              border-left: 11px solid #00a9c6;
              transition: border-color ease-in-out .15s;
            }
            &.invalid-field:before {
              border-left-color: #a94442;
            }
            textarea {
              border: none;
              border-radius: 0;
              padding: 1px;
              padding-left: 16px;
              border-left: 1px solid #00a9c6;
              box-shadow: none;
              &.ng-invalid.ng-dirty {
                border-color: #a94442;
                box-shadow: none;
              }
              &.ng-invalid.ng-dirty::placeholder {
                color: #e59f9d;
              }
            }
          }
        }

        &.view-request {
          .request-info-block {
            .info-content {
              border-left: 1px solid #00a9c6;
              padding: 3px 0 30px 5px;
              line-height: initial;
              min-height: 50px;
              &:before {
                border: none;
              }
            }
          }
        }
        .request-user-content {
          margin-top: 15px;
        }
      }
      .modal-title + .modal-body {
        padding-top: 15px;
        &.with-buttons {
          max-height: -moz-calc(100% - 120px);
          max-height: -webkit-calc(100% - 120px);
          max-height: calc(100% - 120px);
        }
      }
    }
    @include respond-to(lg) {
      &.modal-sm {
        width: 500px;
        .modal-content {
          .modal-title {
            padding: 18px 50px 16px 30px;
            height: auto;
          }
          .modal-body {
            padding: 30px 30px 45px;
            .request-info-block {
              margin-top: 25px;
              padding-bottom: 20px;
            }
          }
          .modal-footer {
            padding: 18px 30px;
            height: 70px;
          }
        }
      }
    }

    @include respond-to(xs) {
      margin: 0;
      position: fixed;
      top: 0;
      bottom: 0;
      width: 100%;
      .modal-content {
        height: 100%;
        width: inherit;
        position: relative;
        .modal-footer {
          width: 100%;
          position: absolute;
          bottom: 0;
        }
      }
    }
  }
}

.chat-content {
  padding-bottom: 15px;
  h2 {
    margin-top: 5px;
  }
  *[name="chat_input"] {
    border-radius: 0;
    border-color: #d3d3d3;
  }
  .messages-container {
    position: relative;
    overflow-x: hidden;
    border: 1px solid lightgrey;
    &:before {
      content: " ";
      position: absolute;
      top: 0;
      display: table;
      width: 100%;
      height: 6px;
      z-index: 1;
      box-sizing: border-box;
      background: linear-gradient(to bottom, rgba(211, 211, 211, 0.4) 0%, rgba(0, 0, 0, 0) 75%);
    }
    &:after {
      content: " ";
      display: table;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 6px;
      z-index: 1;
      box-sizing: border-box;
      background: linear-gradient(to top, rgba(211, 211, 211, 0.4) 0%, rgba(0, 0, 0, 0) 75%);
    }
  }

  .messages {
    overflow-x: hidden;
    padding-bottom: 15px;
    padding-top: 15px;
    .msg-typing {
      text-align: left;
      padding: 3px 0 15px;
      color: darkgray;
      margin-left: 64px;
    }
    .message {
      padding: 5px 15px;
      &.unread {
        background: #d4d4d4;
      }
      a {
        text-decoration: none;
      }

      img.rounded-photo {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        vertical-align: bottom;
        &.right-photo {
          display: none;
        }
      }
      .msg-text {
        background: lightgray;
        border-radius: 15px;
        color: #505050;
        padding: 10px 15px;
        display: inline-block;
        margin-left: 10px;
        position: relative;
        cursor: pointer;
        vertical-align: bottom;
        &:before {
          content: " ";
          position: absolute;
          border-bottom: 8px solid transparent;
          border-right: 8px solid lightgrey;
          left: -20px;
          width: 30px;
          border-radius: 50%;
          height: 30px;
          top: 2px;
          transform: rotate(-25deg);
        }

        .loading {
          display: inline-block;
          width: 12px;
          margin-left: 6px;
          height: 12px;
          line-height: 1rem;
          vertical-align: middle;
          margin-right: -6px;
          content: url('/../../images/circle-cbtn.svg');
        }
      }
      .msg-date {
        font-size: 11px;
        color: darkgray;
        margin-left: 58px;
      }
      &.my-msg {
        text-align: right;
        .msg-text {
          background: #00a9c6;
          color: #e6fbff;
          margin-left: 0;
          margin-right: 10px;
          &:before {
            display: none;
          }
          &:after {
            content: " ";
            position: absolute;
            border-bottom: 8px solid transparent;
            border-left: 8px solid #00aac3;
            right: -20px;
            width: 30px;
            border-radius: 50%;
            height: 30px;
            top: 2px;
            -webkit-transform: rotate(40deg);
            transform: rotate(25deg);
          }
        }
        img.rounded-photo {
          display: none;
          &.right-photo {
            display: initial;
          }
        }
        .msg-date {
          margin-right: 54px;
          margin-left: 0;
        }
      }
      &.hide-photo, &.my-msg.hide-photo {
        img.rounded-photo, img.rounded-photo.right-photo {
          display: none;
        }
        .msg-text {
          &:before, &:after {
            display: none;
          }
        }
      }
      &.hide-photo {
        .msg-text {
          margin-left: 49px;
        }
      }
      &.my-msg.hide-photo {
        .msg-text {
          margin-right: 49px;
        }
      }

      &.typing {
        .msg-text {
          background: #eaeaea;
          color: #aaaaaa;
          &:before {
            border-right-color: #eaeaea;
          }
        }
      }
    }

    @include respond-to(xs) {
      .message, .message.my-msg {
        img.rounded-photo, img.rounded-photo.right-photo {
          display: none;
        }
        &.hide-photo .msg-text, .msg-text {
          margin: 0;
        }
        .msg-date {
          margin: 0;
        }
      }
    }
  }
}

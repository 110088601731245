.header {
  background-image: url("/../../images/static-pages/about/header.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: #ecf7fc;
}

.header-circle-container {
  max-width: 500px;
  margin: 10px auto;
}

.header-circle {
  width: 100%;
  background: rgba(40, 179, 204, 0.47);
  display: table;
  border-radius: 50%;
  text-align: center;
  position: relative;
}

.header-circle:before {
  content: " ";
  float: left;
  height: 0;
  width: 1px;
  margin: 100% 0 0;
}

.header-circle h1 {
  padding: 12% 0 12% 0;
  margin: 0;
  color: #505050;
  font-weight: bold;
}

.header-circle p {
  padding-bottom: 10%;
  color: white;
  font-size: 23px;
  letter-spacing: 1px;
  margin: 0;
  cursor: pointer;
}

.mission {
  padding-bottom: 70px;
}

.solutions-for-entrepreneurs {
  padding-bottom: 150px;
}

.entrepreneur-solution {
  min-height: 200px;
  padding: 20px 0 40px 0;
}

.entrepreneur-solution__sign-up-btn {
  margin-top: 15px;
  font-size: 13px;
}

.entrepreneur-solution--light-blue {
  background-color: #ecf7fc;
}

.entrepreneur-solution__image {
  width: 70px;
  margin: auto;
}

.around-world {
  padding-bottom: 40px;
}

.about-team__quote {
  padding-bottom: 40px;
}

.about-team img {
  width: 170px;
}

.about-team .img-link {
  width: 42px;
}

.about-team-member {
  padding-bottom: 90px;
}

.about-team-member__description {
  padding-top: 15px;
}

@media (min-width: 992px) {
  .entrepreneur-solution__image-container {
    padding-right: 0;
  }

  .vertical-align {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .entrepreneur-solution__sign-up-btn {
    margin-left: -10px;
  }

}

@media (max-width: 536px) {
  .header-circle h1 {
    font-size: 20px;
  }

  .header-circle p {
    font-size: 14px;
    padding-bottom: 10%;
  }
}

@media (max-width: 385px) {
  .header-circle p {
    padding-bottom: 7%;
  }
}

.header-title {
  color: #25a9c6;
  padding: 40px 0 40px 0;
  margin-top: 0;
  margin-bottom: 0;
}

.transparent-background {
  background-color: #f6f6f6;
}

.static-page-link {
  color: #333333;
  text-decoration: underline;
}

.static-page-link:hover {
  color: darken(#333333, 20);
}

.static-page-link:active {
  color: darken(#333333, 30);
}

@import "about";
@import "south-africa-chapter";
